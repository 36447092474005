import { billsForPPTAccountLoaded } from '@/app/modules/ppt/state/ppt.actions';
import { Injectable } from '@angular/core';
import { ofType } from '@ces/sourced-action';
import { Actions, createEffect } from '@ngrx/effects';
import { watchListLoaded } from '@watch-lists/state/feature/entities/watch-list/watch-list.actions';
import { EMPTY, of } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { addPPTAccountData } from './ppt-account.actions';

@Injectable()
export class PPTAccountEntityEffects
{
    constructor
    (
        // Dependencies

        private readonly actions$: Actions,
    )
    {}


    // Bills for account loaded

    private readonly billsForPPTAccountLoaded$ = createEffect(() => this.actions$.pipe(
        ofType(billsForPPTAccountLoaded),
        mergeMap(({ response }) => response
            ? of(addPPTAccountData(`Effect of ${billsForPPTAccountLoaded.type}`, { accountsData: response.accounts }))
            : EMPTY
        ),
    ));


    // Watch List details loaded

    private readonly watchListDetailsLoaded$ = createEffect(() => this.actions$.pipe(
        ofType(watchListLoaded),
        filter(a => !!a.response.ppt?.accounts.length),
        map(({ response }) => addPPTAccountData(
            `Effect of ${watchListLoaded.type}`,
            { accountsData: response.ppt!.accounts }
        )),
    ));
}
