import { CartPluginsService } from '@/app/modules/cart/services/cart-plugins.service';
import { Injectable } from '@angular/core';
import { cartContentsLoaded, invalidateTotal } from '@cart/state/common/cart-common.actions';
import { ofType } from '@ces/sourced-action';
import { CartNetworkService, createEgovAPIClientError } from 'egov-api';
import { Actions, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from '@state/model';
import { concat, EMPTY, from, merge, of } from 'rxjs';
import { catchError, delay, first, mapTo, mergeMap, switchMap, takeWhile } from 'rxjs/operators';
import { invalidateFees } from '../../../feature/cart-feature.actions';
import { setItemsInCart, updateCartItemPartialAmount, updateCartItemPartialAmountIntent, updateCartItemPartialComplete, updateCartItemPartialFailed } from './cart-item.actions';
import { CartItemData } from './cart-item.model';


@Injectable()
export class CartItemEffects
{
    constructor
    (
        // Dependencies

        private readonly plugins: CartPluginsService,
        private readonly actions$: Actions,
        private readonly cartNetworkService: CartNetworkService,
        private readonly store: Store<State>,
    )
    {}

    private readonly cartContentsLoaded$ = createEffect(() => this.actions$.pipe(
        ofType(cartContentsLoaded),
        mergeMap(({ cartResponse }) =>
        {
            if (cartResponse)
            {
                let items: CartItemData[] = [];

                const parsers = this.plugins.plugins.map(plugin => plugin.parse);

                for (const parser of parsers)
                {
                    const parsedItems = parser(cartResponse);
                    if (parsedItems?.length) items = items.concat(parsedItems);
                }

                return of(setItemsInCart(`Effect of ${cartContentsLoaded.type}`, { items }));
            }
            else
                return EMPTY;
        })
    ));

    private readonly updateCartItemPartialAmountIntent$ = createEffect(() => this.actions$.pipe(
        ofType(updateCartItemPartialAmountIntent),
        mergeMap(action =>
            merge(
                this.actions$.pipe(
                    ofType(updateCartItemPartialAmountIntent),
                    first(a => a.item.type === action.item.type && a.item.id === action.item.id),
                    mapTo(updateCartItemPartialFailed(`Effect of ${updateCartItemPartialAmount.type}`, {
                        error: createEgovAPIClientError({ message: 'Partial update cancelled' }),
                        intent: action
                    })),
                ),
                of(updateCartItemPartialAmount(
                    `Effect of ${updateCartItemPartialAmountIntent.type}`,
                    { intent: action }
                )).pipe(
                    delay(700),
                ),
            ).pipe(
                takeWhile(a => !a.type.includes(updateCartItemPartialFailed.type), true),
            ),
        ),
    ));

    private readonly updateCartItemPartialAmount$ = createEffect(() => this.actions$.pipe(
        ofType(updateCartItemPartialAmount),
        mergeMap(action => concat(
            from([
                invalidateTotal(`Effect of ${updateCartItemPartialAmountIntent.type}`),
                invalidateFees(`Effect of ${updateCartItemPartialAmountIntent.type}`),
            ]),
            merge(
                this.actions$.pipe(
                    ofType(updateCartItemPartialAmountIntent),
                    first(a => a.item.type === action.intent.item.type && a.item.id === action.intent.item.id),
                    mapTo(updateCartItemPartialFailed(`Effect of ${updateCartItemPartialAmount.type}`, {
                        error: createEgovAPIClientError({ message: 'Partial update cancelled' }),
                        intent: action.intent
                    })),
                ),
                this.cartNetworkService.updatePartialAmount$(
                    action.intent.item,
                    action.intent.partialAmount
                ).pipe(
                    switchMap(cartResponse => of(
                        cartContentsLoaded(`Effect of ${updateCartItemPartialAmount.type}`, { cartResponse }),
                        updateCartItemPartialComplete(`Effect of ${updateCartItemPartialAmount.type}`, { intent: action.intent }),
                    )),
                    catchError(e => of(updateCartItemPartialFailed(`Effect of ${updateCartItemPartialAmountIntent.type}`, {
                        error: e,
                        intent: action.intent
                    }))),
                ),
            ).pipe(
                takeWhile(a => !a.type.includes(updateCartItemPartialFailed.type), true),
            ),
        ))
    ));
}
