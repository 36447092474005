import { combineReducers } from '@ngrx/store';
import { WatchListEntityEffects } from './watch-list/watch-list.effects';
import { WatchListEntities } from './watch-list/watch-list.model';
import { watchListsReducer } from './watch-list/watch-list.reducer';


// Model

export interface WatchListsFeatureEntities
{
    watchLists: WatchListEntities
}


// Reducer

export const watchListsFeatureEntitiesReducer = combineReducers<WatchListsFeatureEntities>({
    watchLists: watchListsReducer,
});


// Effects

export const watchListsFeatureEntityEffects = [
    WatchListEntityEffects
];
