import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { ScrollableModal } from '@egovsolutions/angular-scrollable-modal';
import { DialogNavigations } from '@egovsolutions/angular-dialog-navigations';
import { LazyDialogContentComponent } from '@shared/components/dialogs/lazy-dialog-content/lazy-dialog-content.component';
import { filter } from 'rxjs/operators';
import { ComponentImporter, ModuleImporter } from '@egovsolutions/angular-lazy';

@Injectable({ providedIn: 'any' })
export class LazyModal
{
    constructor
    (
        // Dependencies

        private readonly scrollableModal: ScrollableModal,
        private readonly dialogNavigations: DialogNavigations,
    )
    {
        this.init();
    }


    // Initialization

    private init()
    {
        this.initializeDialogHandling();
    }


    // Dialog

    private initializeDialogHandling()
    {
        this.dialogNavigations.restore$.pipe(filter(dialogDefinition => dialogDefinition.data === 'lazy-modal'))
            .subscribe(definition =>
            {
                this.open(definition.data.moduleName, definition.data.componentName, definition.data.data);
            });
    }

    public open(
        componentImporter: ComponentImporter,
        moduleImporter?: ModuleImporter,
        data?: any,
        config?: MatDialogConfig
    )
    {
        return this.scrollableModal.open(
            LazyDialogContentComponent,
            {...config, data: { componentImporter, moduleImporter, data }}
        );
    }
}
