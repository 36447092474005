import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-powered-by',
    template: `
        <div class="powered-by" [style.text-align]="align">
            <span [style.color]="color === 'grey' ? '#999999' : 'white'">Powered by</span>
            <img
                width="62"
                height="21"
                src="assets/img/powered-by-logo{{ color === 'grey' ? '-grey' : '' }}-1x.png"
                srcset="
                    assets/img/powered-by-logo{{color === 'grey' ? '-grey' : ''}}-1x.png 1x,
                    assets/img/powered-by-logo{{color === 'grey' ? '-grey' : ''}}-2x.png 2x,
                    assets/img/powered-by-logo{{color === 'grey' ? '-grey' : ''}}-3x.png 3x
                "
                alt="eGov"
            />
        </div>
    `,
    styleUrls: ['./powered-by.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PoweredByComponent
{
    // Customizations

    @Input() protected align = 'left';
    @Input() protected color: 'grey' | 'white' = 'white';
}
