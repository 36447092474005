import { selectPPTAccountHasPayableBills, selectPPTAccountIsInCart, selectPPTAccountHasPayableBillsDelegated } from '@/app/modules/ppt/state/entities/account/ppt-account.selectors';
import { State } from '@/app/state/model';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { WatchListItemTypeDefinition } from '../watch-list-item-definition.model';
import { PPT_ACCOUNT_WATCH_LIST_ITEM_TYPE } from './ppt-account-watch-list-item.key';

@Injectable()
export class PPTWatchListItemDefinition implements WatchListItemTypeDefinition
{
    public readonly type = PPT_ACCOUNT_WATCH_LIST_ITEM_TYPE;

    constructor
    (
        // Dependencies

        private readonly store: Store<State>,
    )
    {}

    public readonly cartType = 'ppt-account';
    public readonly icon = 'home';
    public readonly name = 'Property Tax';

    public readonly itemIsPayable = (id: string) => combineLatest([
        this.store.select(selectPPTAccountHasPayableBills(id)),
        this.store.select(selectPPTAccountHasPayableBillsDelegated(id)),
    ]).pipe(
        map(([hasPayableBills, delegated]) => hasPayableBills && !delegated),
    );

    public readonly itemIsInCart = (id: string) => this.store.select(selectPPTAccountIsInCart(id)).pipe(
        map(inCart => inCart),
    );

    public readonly importComponent =
        () => import('@watch-lists/items/ppt-account-watch-list-item/ppt-account-watch-list-item.component')
            .then(({ PPTAccountWatchListItemComponent }) => PPTAccountWatchListItemComponent);
}
