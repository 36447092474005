import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { ofType } from '@ces/sourced-action';
import { Actions, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from '@state/model';
import { FrontEndNetworkService } from 'egov-api';
import { concat, of, throwError } from 'rxjs';
import { catchError, delay, map, mapTo, mergeMap, retryWhen, take, tap } from 'rxjs/operators';
import { loadState, stateLoaded, stateLoadFailed } from './app-state.actions';


@Injectable()
export class AppStateEffects
{
    constructor
    (
        // Dependencies

        private readonly actions$: Actions,
        private readonly networkService: FrontEndNetworkService,
        private readonly store: Store<State>,
        private readonly snackBar: MatSnackBar,
    )
    {}

    private readonly effectsInit$ = createEffect(() => this.actions$.pipe(
        ofType('@ngrx/effects/init'),
        mapTo(loadState('Effect of @ngrx/effects/init')),
    ));

    private readonly loadAppState$ = createEffect(() => this.actions$.pipe(
        ofType(loadState),
        mergeMap(() => this.networkService.loadState$().pipe(
            retryWhen(errors => concat(
                errors.pipe(delay(5000), take(12)),
                throwError('Could not load state')
            )),
            map(newState => stateLoaded(`Effect of ${loadState.type}`, { newState })),
            catchError(() => of(stateLoadFailed(`Effect of ${loadState.type}`)))
        ))
    ));

    private readonly stateLoadFailed$ = createEffect(() => this.actions$.pipe(
        ofType(stateLoadFailed),
        tap(() =>
        {
            const snackBarRef: MatSnackBarRef<SimpleSnackBar> = this.snackBar.open(
                'Could not load application state.',
                'Retry',
                { duration: 100 * 365 * 24 * 60 * 60 * 1000 /* A hundred years (Virtually forever) */ }
            );

            snackBarRef.onAction().subscribe(() => this.store.dispatch(loadState('Snackbar retry')));
        })
    ), { dispatch: false });

    private readonly setFavicon$ = createEffect(() => this.actions$.pipe(
        ofType(stateLoaded),
        tap((action: ReturnType<typeof stateLoaded>) =>
        {
            // TODO: Review this approach
            if (action.newState)
                (document.getElementById('site-favicon') as HTMLLinkElement).href =
                    action.newState.clientsFaviconImage;
        })
    ), { dispatch: false });
}
