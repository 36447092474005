import { createReducer } from '@ces/sourced-action';
import { on } from '@ngrx/store';
import { expirationUpdated, sessionInfoUpdated, signedOut, signOutFailed } from './session.actions';
import { Session } from './session.model';


// Initial state

const initialState: Session = {
    signingOut: false,
};


// Reducer

export const sessionReducer = createReducer<Session>(
    initialState,

    on(sessionInfoUpdated, (sessionState, { sessionInfo }) => ({ ...sessionState, sessionInfo })),
    on(expirationUpdated, (sessionState, { expiration }) => ({ ...sessionState,
        sessionInfo: {
            ...sessionState.sessionInfo!,
            expiration
        }
    })),

    // on(signOut, session => ({ ...session, signingOut: true })),
    on(signedOut, signOutFailed, session => ({ ...session, signingOut: false })),
);
