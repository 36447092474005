import { AppDialogsService } from '@/app/services/app-dialogs.service';
import { selectUserAccountsEnabled } from '@/app/state/app-state/app-state.selectors';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { selectGrandTotalWithoutFees } from '@cart/state/common/cart-common.selectors';
import { sync } from '@ces/sync';
import { ArtificialNavigations } from '@egovsolutions/angular-artificial-navigations';
import { FramesDelay } from '@egovsolutions/angular-frames-delay';
import { Store } from '@ngrx/store';
import { selectSignedIn, selectSigningOut, selectUserName } from '@session/state/session.selectors';
import { State } from '@state/model';
import { selectWatchListsEnabled } from '@watch-lists/state/common/state/watch-lists-state.selectors';
import { FrontEndSession } from 'egov-api';
import { environment } from 'environments/environment';
import { BehaviorSubject, connectable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-mobile-menu',
    templateUrl: './mobile-menu.component.html',
    styleUrls: ['./mobile-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileMenuComponent
{
    constructor
    (
        // Dependencies

        private readonly appDialogsService: AppDialogsService,
        private readonly artificialNavigations: ArtificialNavigations,
        private readonly store: Store<State>,
        private readonly router: Router,
        private readonly framesDelay: FramesDelay,
        private readonly session: FrontEndSession,
    )
    {}


    // State

    protected readonly opened$ = new BehaviorSubject(environment.startWithMobileMenuOpened);
    protected readonly accountsEnabled$ = this.store.select(selectUserAccountsEnabled);


    // Session state

    protected readonly signedIn$ = this.store.select(selectSignedIn);
    protected readonly userName$ = this.store.select(selectUserName());
    protected readonly signingOut$ = this.store.select(selectSigningOut);
    protected readonly watchListsEnabled$ = this.store.select(selectWatchListsEnabled);


    // Cart state

    protected readonly cartTotal$ = this.store.select(selectGrandTotalWithoutFees);
    protected readonly showTotal$ = this.cartTotal$.pipe(map(v => !!v));


    // User navigation

    protected readonly userNavOpened$ = new BehaviorSubject(false);

    protected userWidgetClicked()
    {
        if (!sync(this.store.select(selectSignedIn)))
        {
            this.appDialogsService.signIn();
            this.close(true);
        }
        else
            this.userNavOpened$.next(!this.userNavOpened$.value);
    }


    // Opened state

    private sideMenuNavigationId?: string;

    public open()
    {
        this.sideMenuNavigationId =
            this.artificialNavigations.set({ definition: { type: 'mobile-menu' }, onDropped: () => this.close() });
        this.opened$.next(true);
    }

    public close(dropNavigation: boolean = false)
    {
        if (dropNavigation && this.sideMenuNavigationId)
        {
            this.artificialNavigations.reportDropped(this.sideMenuNavigationId);
            delete this.sideMenuNavigationId;
        }

        this.opened$.next(false);
    }


    // Actions

    protected openSettings()
    {
        this.close(true);
        this.appDialogsService.openSettings();
    }

    protected signOut()
    {
        this.close(true);
        connectable(this.session.signOut$()).connect();
    }


    // Link

    protected navigateByUrl(path: string)
    {
        this.close(false);
        this.framesDelay.delay$(2).subscribe(() => { this.router.navigateByUrl(path); });
    }
}
