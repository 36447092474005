import { ViewportScroller } from '@angular/common';
import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { filter, map, share } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class Scroll
{
    constructor
    (
        // Dependencies

        private readonly dialog: MatDialog,
        private readonly ngZone: NgZone,
        private readonly viewportScroller: ViewportScroller,
    )
    {
        this.init();
    }


    // Initialization

    private init()
    {
        this.setUpGlobalScrollIgnores();
    }


    // Scroll events
    //
    // `browserScroll$` simply gets the browser events. Since it's a cold observable,
    // it doesn't listen for events until it is subscribed to.
    //
    // `scrollAll$` subscribes to `browserScroll$` outside Angular using the observer
    // passed. It unsubscribes when unsubscribed to. It is `share`d to avoid it running multiple
    // times if it can run only once. Emits scroll positions as number tuples.
    //
    // `scroll$` is like `scrollAll$` but it only emits when there are no
    // `reasonsToIgnoreScrollEvents`.
    //
    // You should use `scroll$` when possible and only use `scrollAll$` when `scroll$`
    // doesn't satisfy your needs.
    //
    // `scrollPositionAll$` and `scrollPosition$` are the same as `scrollAll$` and `scroll$`
    // only they also emit the initial value.
    //
    // The fact that these observables emit outside angular means that change detection
    // won't run every time something is emitted. So you are supposed to explicitly
    // run code inside Angular if you have determined that one of these events has
    // side effects that require something to change in a template.

    private readonly browserScroll$: Observable<Event> = fromEvent(window, 'scroll');

    public readonly scrollAll$: Observable<[number, number]> = new Observable<Event>(observer =>
    {
        let subscription: Subscription;
        this.ngZone.runOutsideAngular(() => { subscription = this.browserScroll$.subscribe(observer); });
        return { unsubscribe: () => this.ngZone.runOutsideAngular(() => subscription!.unsubscribe()) };
    })
    .pipe(
        map(this.get),
        share()
    );

    public readonly scroll$ = this.scrollAll$.pipe(filter(v => !this.reasonsToIgnoreScrollEvents.size));

    // public readonly scrollPositionAll$ = concat(of(this.get()), this.scrollAll$).pipe(shareReplay(1));
    // public readonly scrollPosition$ = concat(of(this.get()), this.scroll$).pipe(shareReplay(1));


    // Reasons to ignore scroll events

    public reasonsToIgnoreScrollEvents: Set<string> = new Set<string>();

    private setUpGlobalScrollIgnores()
    {
        // Dialogs in general

        this.dialog.afterOpened.subscribe(() => this.reasonsToIgnoreScrollEvents.add('dialogs'));
        this.dialog.afterAllClosed.subscribe(() => this.reasonsToIgnoreScrollEvents.delete('dialogs'));
    }


    // Scroll restoration

    public setHistoryScrollRestoration(value: 'auto' | 'manual')
    {
        this.viewportScroller.setHistoryScrollRestoration(value);
    }


    // Get scroll position

    public get(): [number, number]
    {
        return [
            window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0,
            window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0,
        ];
    }


    // Set scroll position

    public set(position: [number, number]) { this.viewportScroller.scrollToPosition(position); }
}
