import { createSelector } from '@ngrx/store';
import { State } from '@state/model';


const selectBTaxBillEntity = (s: State) => s.common.btax.entities.bills;

export const selectBTaxBillsById = createSelector(selectBTaxBillEntity, s => s.entities);
export const selectBTaxBills = createSelector(selectBTaxBillEntity, s => s.ids.map(id => s.entities[id]!));
export const selectBTaxBill = (id: string) => createSelector(selectBTaxBillEntity, s => s.entities[id]);

export const selectBTaxBillsByAccountNumber = (accountNumber: string) => createSelector(
    selectBTaxBills,
    bills => bills.filter(bill => bill.accountNumber === accountNumber),
);

export const selectBTaxBillByAccountNumberAndTaxYear = (accountNumber: string, taxYear: number) => createSelector(
    selectBTaxBills,
    bills => bills.find(bill => bill.accountNumber === accountNumber && bill.taxYear === taxYear),
);
