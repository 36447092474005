import { createEntityUpdater } from '@/helpers/entity-updater';
import { createReducer } from '@ces/sourced-action';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { on } from '@ngrx/store';
import { WatchList, WatchListData } from 'egov-api';
import { addItemsToList, addWatchListToCart, deleteWatchList, flushWatchLists, itemsAddedToList, itemsRemovedFromList, loadWatchLists, removeItemsFromList, removeWatchListFromCart, upsertWatchList, watchListAdded, watchListAddedToCart, watchListAddToCartFailed, watchListDeleted, watchListLoaded, watchListRemovedFromCart, watchListRemoveFromCartFailed, watchListsLoaded, watchListsLoadFailed } from './watch-list.actions';
import { WatchListEntities } from './watch-list.model';


// Runtime state

const addRuntimeState = (watchListData: WatchListData): WatchList => ({
    runtimeState:
    {
        updatingAddedToCart: 0,
        deleting: false,
    },
    ...watchListData
});


// Adapter

const adapter: EntityAdapter<WatchList> = createEntityAdapter<WatchList>({ selectId: a => a.slug });
const updater = createEntityUpdater(adapter, addRuntimeState, 'slug');


// Initial state

const initialState = adapter.getInitialState({ loading: false, loaded: false, loadFailed: false }) as WatchListEntities;


// Reducer

export const watchListsReducer = createReducer<WatchListEntities>(

    initialState,

    on(loadWatchLists, s => ({ ...s, loading: true })),

    on(watchListsLoaded, (s, { lists }) => (
    {
        ...adapter.setAll(lists?.map(addRuntimeState) || [], s),
        loading: false,
        loadFailed: false,
        loaded: true,
    })),

    on(watchListsLoadFailed, s => ({ ...s, loading: false, loadFailed: true })),

    on(watchListLoaded, (s, { list }) => updater.update(s, [list])),

    on(watchListAdded, (s, { list }) => updater.update(s, [list])),
    on(upsertWatchList, (s, { list }) =>  updater.update(s, [list])),

    on(deleteWatchList, (s, { slug }) => s.entities[slug]
        ? adapter.updateOne({ id: slug, changes: {
            runtimeState: {
                ...s.entities[slug]!.runtimeState,
                deleting: true // this
            }
        }}, s)
        : s
    ),

    on(watchListDeleted, (s, { slug }) => adapter.removeOne(slug, s)),

    on(addItemsToList, (s, { slug, items, optimistic }) => optimistic
        ? adapter.updateOne(
            {
                id: slug,
                changes:
                {
                    items: Array.from(new Set((s.entities[slug]?.items || []).concat(items))) // this
                }
            },
            s
        )
        : s
    ),

    on(itemsAddedToList, (s, { slug, items, optimistic }) =>
        optimistic
            ? s
            : adapter.updateOne({
                id: slug,
                changes:
                {
                    items: Array.from(new Set((s.entities[slug]?.items || []).concat(items))) // this
                }
            },
        s)
    ),

    on(removeItemsFromList, (s, { slug, items, optimistic }) => optimistic
        ? adapter.updateOne({
            id: slug,
            changes:
            {
                // this
                items: s.entities[slug]?.items?.
                    filter(itemInList => !items.find(i => i.type === itemInList.type && i.id === itemInList.id))
            }
        }, s)
        : s
    ),

    on(itemsRemovedFromList, (s, { slug, items, optimistic }) => optimistic
        ? s
        : adapter.updateOne({
            id: slug,
            changes:
            {
                items: s.entities[slug]?.items?.
                    filter(itemInList => !items.find(i => i.type === itemInList.type && i.id === itemInList.id)) // this
            }
        }, s)
    ),

    on(flushWatchLists, s => (
    {
        ...adapter.removeAll(s),
        loaded: false,
    })),

    on(addWatchListToCart, removeWatchListFromCart, (s, { slug }) => s.entities[slug]
        ? adapter.updateOne({ id: slug, changes: {
            runtimeState: {
                ...s.entities[slug]!.runtimeState,
                updatingAddedToCart: s.entities[slug]!.runtimeState.updatingAddedToCart + 1 // this
            }
        }}, s)
        : s
    ),

    on(
        watchListAddedToCart, watchListAddToCartFailed, watchListRemovedFromCart, watchListRemoveFromCartFailed,
        (s, { slug }) => s.entities[slug]
            ? adapter.updateOne({ id: slug, changes: {
                runtimeState: {
                    ...s.entities[slug]!.runtimeState,
                    updatingAddedToCart: s.entities[slug]!.runtimeState.updatingAddedToCart - 1 // this
                }
            }}, s)
            : s
    ),
);
