import { combineReducers } from '@ngrx/store';
import { PPTAccountEntities } from './account/ppt-account.model';
import { pptAccountsReducer } from './account/ppt-account.reducer';
import { PPTAccountEntityEffects } from './account/ppt-account.effects';
import { PPTBillEntities } from './bill/ppt-bill.model';
import { pptBillsReducer } from './bill/ppt-bill.reducer';
import { PPTBillEntityEffects } from './bill/ppt-bill.effects';
import { PPTParcelEntities } from './parcel/ppt-parcel.model';
import { pptParcelsReducer } from './parcel/ppt-parcel.reducer';
import { PPTParcelEntityEffects } from './parcel/ppt-parcel.effects';

export interface PPTEntities
{
    accounts: PPTAccountEntities
    parcels: PPTParcelEntities
    bills: PPTBillEntities
}

export const pptEntitiesReducer = combineReducers<PPTEntities>({
    accounts: pptAccountsReducer,
    parcels: pptParcelsReducer,
    bills: pptBillsReducer,
});

export const pptEntityEffects = [
    PPTAccountEntityEffects,
    PPTParcelEntityEffects,
    PPTBillEntityEffects,
];
