import { Injectable } from '@angular/core';
import { ofType } from '@ces/sourced-action';
import { Actions, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from '@state/model';
import { EMPTY, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { billsForBTaxAccountLoaded } from '../../btax.actions';
import { addBTaxBillData } from './btax-bill.actions';

@Injectable({ providedIn: 'root' })
export class BTaxBillEntityEffects
{
    constructor
    (
        // Dependencies

        private readonly actions$: Actions,
        private readonly store: Store<State>,
    )
    {}


    // Bills loaded

    private readonly billsForAccountLoaded$ = createEffect(() => this.actions$.pipe(
        ofType(billsForBTaxAccountLoaded),
        mergeMap(({ response }) => response?.bills
            ? of(addBTaxBillData(`Effect of ${billsForBTaxAccountLoaded.type}`, { billsData: response.bills }))
            : EMPTY
        ),
    ));
}
