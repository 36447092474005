import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundPage } from '@shared/pages/not-found/not-found.component';
import { Transitions } from '@shared/services/transitions.service';

const routes: Routes = [


    // Lazy

    {
        path: 'lookup',
        loadChildren: () => import('./modules/lookup/lookup.module').then(({ LookupModule }) => LookupModule),
        resolve: { forceRepaint: 'forceRepaintResolver' },
    },
    {
        path: 'property-tax',
        loadChildren: () => import('./modules/ppt/ppt.module').then(({ PPTModule }) => PPTModule),
        resolve: { forceRepaint: 'forceRepaintResolver' }
    },
    {
        path: 'business-tax',
        loadChildren: () => import('./modules/btax/btax.module').then(({ BTaxModule }) => BTaxModule),
        resolve: { forceRepaint: 'forceRepaintResolver' }
    },
    {
        path: 'cart',
        loadChildren: () => import('./modules/cart/cart.module').then(({ CartModule }) => CartModule),
        resolve: { forceRepaint: 'forceRepaintResolver' },
    },
    {
        path: 'watch-lists',
        loadChildren: () => import('./modules/watch-lists/watch-lists.module').then(({ WatchListsModule }) => WatchListsModule),
        resolve: { forceRepaint: 'forceRepaintResolver' },
    },


    // Lookup redirects after changing address

    {
        path: 'property-tax/lookup/:query/:sort/:direction',
        redirectTo: '/lookup/property-tax/:query/:sort/:direction',
        pathMatch: 'full'
    },
    {
        path: 'property-tax/lookup/:query',
        redirectTo: '/lookup/property-tax/:query',
        pathMatch: 'full'
    },
    {
        path: 'property-tax/lookup',
        redirectTo: '/lookup/property-tax',
    },


    // Homepage

    {
        path: '',
        redirectTo: '/lookup/property-tax',
        pathMatch: 'full',
    },


    // Not found

    {
        path: '**',
        component: NotFoundPage,
        data: { title: 'Not Found' },
        resolve: { forceRepaint: 'forceRepaintResolver' },
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    providers:
    [
        {
            provide: 'forceRepaintResolver',
            useValue: Transitions.FORCE_RELAYOUT_RESOLVER,
        }
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
