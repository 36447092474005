import { AutoDraftService } from '@auto-draft/auto-draft.service';
import { AppDialogsService } from '@/app/services/app-dialogs.service';
import { State } from '@state/model';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { selectPPTAutoDraftEnabled } from '@/app/modules/ppt/state/ppt-state/ppt-state.selectors';
import { LazyModal } from '@shared/services/lazy-modal.service';

@Component({
    selector: 'app-settings-hub',
    template: `
        <egov-dialog-close-button></egov-dialog-close-button>

        <div class="settings-hub-dialog">
            <h2 mat-dialog-title>Settings</h2>
            <ul>
                <li class="listing-item listing-menu-item">
                    <a mat-button (click)="accountDetails()">
                        <span class="icon">
                            <mat-icon>person</mat-icon>
                        </span>
                        <div class="texts">
                            <h3>Account details</h3>
                            <p>Change your account details or update your password.</p>
                        </div>
                    </a>
                </li>
                <!-- <li class="listing-item listing-menu-item">
                    <a mat-button (click)="paymentMethods()">
                        <span class="icon">
                            <mat-icon>credit_card</mat-icon>
                        </span>
                        <div class="texts">
                            <h3>Payment methods</h3>
                            <p>Add or remove payment methods.</p>
                        </div>
                    </a>
                </li> -->
                <li class="listing-item listing-menu-item" *ngIf="autoDraftEnabled$ | async">
                    <a mat-button (click)="autoDrafts()">
                        <span class="icon">
                            <mat-icon>event_available</mat-icon>
                        </span>
                        <div class="texts">
                            <h3>Auto-Draft</h3>
                            <p>Edit or remove existing auto-draft enrollments.</p>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    `,
    styleUrls: ['./settings-hub.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsHubComponent
{
    constructor
    (
        // Dependencies

        private readonly appDialogs: AppDialogsService,
        private readonly lazyModal: LazyModal,
        private readonly autoDraft: AutoDraftService,
        private readonly store: Store<State>,


        // Dialog reference

        private readonly dialogRef: MatDialogRef<SettingsHubComponent>,
    )
    {}


    // Launch dialogs

    protected accountDetails()
    {
        this.appDialogs.openAccountDetails(true);
        this.dialogRef.close();
    }

    protected paymentMethods()
    {
        // this.dialogRef.close();

        this.lazyModal.open(
            () => import('@payment/components/dialogs/manage-payment-methods/manage-payment-methods.component')
                .then(({ ManagePaymentMethodsComponent }) => ManagePaymentMethodsComponent),
            () => import('@payment/payment.module')
                .then(({ PaymentModule }) => PaymentModule),
            undefined,
            { autoFocus: false }
        );
    }


    // Auto draft

    protected readonly autoDraftEnabled$ = this.store.select(selectPPTAutoDraftEnabled);

    protected autoDrafts()
    {
        // this.dialogRef.close();

        this.autoDraft.showManageAutoDraftsDialog();
    }
}
