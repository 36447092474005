import { createReducer } from '@ces/sourced-action';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { on } from '@ngrx/store';
import { PPTParcelEntities } from './ppt-parcel.model';
import { addPPTParcelData } from './ppt-parcel.actions';
import { createEntityUpdater } from '@/helpers/entity-updater';
import { PPTParcel, PPTParcelData } from 'egov-api';


// Runtime state

const addDefaults = (parcelData: PPTParcelData): PPTParcel => (
{
    ...parcelData,


    // Testing

    // appraiserURL: 'lalala'
});


// Adapter

const adapter: EntityAdapter<PPTParcel> = createEntityAdapter<PPTParcel>();
const updater = createEntityUpdater(adapter, addDefaults);


// Initial state

const initialState = adapter.getInitialState() as PPTParcelEntities;


// Reducer

export const pptParcelsReducer = createReducer<PPTParcelEntities>(
    initialState,

    on(addPPTParcelData, (s, { parcelsData }) => updater.update(s, parcelsData)),
);
