import { InjectionToken } from '@angular/core';
import { Message } from '@egovsolutions/angular-messages';
import { PaymentType } from '@egovsolutions/angular-payment-types';
import { Observable } from 'rxjs';
import { CartPresentationalItemDefinition } from '../models/cart-presentational-item-definition.model';
import { CartItemData } from '../state/common/entities/cart-item/cart-item.model';


// Cart

export interface CartPlugin<T extends CartItemData = CartItemData>
{
    readonly type: string
    readonly parse: (response: any) => T[] | undefined
    readonly parseEntities?: (response: any) => void
    readonly partialPaymentAllowed$?: (item: T, itemsInCart: string[]) => Observable<boolean | undefined>

    // Intercept
    //
    // Returns:
    //   - `false` to let all items pass.
    //   - A `string[]` with item IDs of the items that are allowed to pass.
    //   - A `string` containing a message if the entire operation is stopped.
    //   - `true` to stop the addition without showing any messages.

    readonly intercept?: (items: string[]) => Observable<false | string[] | string | true>

    readonly dependencyLogic?:
    {
        readonly getDependencies: (itemId: string) => string[] | null | undefined

        readonly getMissingDependencies: (
            itemId: string,
            simultaneousAdditions: string[],
            itemsAlreadyInCart: string[]
        ) => string[] | null | undefined

        readonly confirmDependenciesInclussion$: (
            itemId: string,
            simultaneousAdditions: string[],
            itemsAlreadyInCart: string[]
        ) => Observable<boolean>

        readonly getDependents: (itemId: string) => string[] | null | undefined

        readonly getAddedDependents: (
            itemId: string,
            simultaneousRemovals: string[],
            itemsInCart: string[]
        ) => string[] | null | undefined

        readonly confirmDependentsRemoval$: (
            itemId: string,
            simultaneousRemovals: string[],
            itemsInCart: string[]
        ) => Observable<boolean>
    }
}

export const CART_PLUGIN = new InjectionToken<CartPlugin>('Cart plugin');


// Cart page

export interface PaymentTypeDisallowedDescriptor
{
    id: string
    itemMessage: string // HTML
    paymentFormMessage: string // HTML
}

export type CartItemPaymentTypeAllowedLogic<T extends CartItemData = CartItemData> =
    (item: T, paymentType: PaymentType) => Observable<true | string[] | undefined>;

export interface CartPagePlugin<T extends CartItemData = CartItemData>
{
    readonly type: string
    readonly createPresentationalItems: (cartItems: CartItemData[]) => CartPresentationalItemDefinition[]
    readonly paymentMessages?: Message[]
    readonly paymentTypeDisallowed?:
    {
        descriptors: PaymentTypeDisallowedDescriptor[]
        isPaymentTypeAllowed$?: CartItemPaymentTypeAllowedLogic<T>
    }
}

export const CART_PAGE_PLUGIN = new InjectionToken<CartPagePlugin>('Cart page plugin');
