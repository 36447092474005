import { createSourcedAction } from '@ces/sourced-action';
import { FrontEndUser, SessionInfo } from 'egov-api';
import { createAction, props } from '@ngrx/store';


export const sessionInfoUpdated = createAction('[EgovAPI] Session info updated', props<{ sessionInfo: SessionInfo<FrontEndUser> | null }>());
export const expirationUpdated = createAction('[EgovAPI] Session expiration updated', props<{ expiration: number }>());

export const newSession = createSourcedAction('New session');
export const signedIn = createSourcedAction('Signed in');
export const signedOut = createSourcedAction('Signed out');
export const signOutFailed = createSourcedAction('Sign out failed');

export const expireSession = createSourcedAction('Expire session');
