import { AppDialogsService } from '@/app/services/app-dialogs.service';
import { Injectable } from '@angular/core';
import { DialogNavigations } from '@egovsolutions/angular-dialog-navigations';
import { filter } from 'rxjs/operators';
import { LazyModal } from '../shared/services/lazy-modal.service';

@Injectable({ providedIn: 'any' })
export class AutoDraftService
{
    constructor
    (
        // Dependencies

        private readonly appDialogsService: AppDialogsService,
        private readonly dialogNavigations: DialogNavigations,
        private readonly lazyModal: LazyModal,
    )
    {
        this.init();
    }


    // Initialization

    public init()
    {
        this.setUpDialogNavigationRequests();
    }


    // Auto-Draft

    public showAutoDraftDialog(accountNumber: string)
    {
        this.appDialogsService.signIn().pipe(filter(signedIn => !!signedIn)).subscribe(() =>
        {
            const dialogRef = this.lazyModal.open(
                () => import('@auto-draft/dialogs/auto-draft/auto-draft-dialog.component')
                    .then(({ AutoDraftDialogComponent }) => AutoDraftDialogComponent),
                () => import('@auto-draft/dialogs/auto-draft/auto-draft-dialog.component')
                    .then(({ AutoDraftDialogModule }) => AutoDraftDialogModule),
                accountNumber,
                { autoFocus: false }
            );

            this.dialogNavigations.set(dialogRef, { type: 'show-auto-draft', data: { accountNumber } });
        });
    }


    // Manage auto-drafts

    public showManageAutoDraftsDialog()
    {
        this.appDialogsService.signIn().pipe(filter(signedIn => !!signedIn)).subscribe(signedIn => this.lazyModal.open(
            () => import('@auto-draft/dialogs/manage-auto-drafts/manage-auto-drafts.component')
                .then(({ ManageAutoDraftsComponent }) => ManageAutoDraftsComponent),
            () => import('@auto-draft/dialogs/manage-auto-drafts/manage-auto-drafts.component')
                .then(({ ManageAutoDraftsModule }) => ManageAutoDraftsModule),
            undefined,
            { autoFocus: false }
        ));
    }


    // Dialogs navigation

    public setUpDialogNavigationRequests()
    {
        this.dialogNavigations.restore$.pipe(filter(d => d.type === 'show-auto-draft'))
            .subscribe(d => this.showAutoDraftDialog(d.data.accountNumber));
    }
}
