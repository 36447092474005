import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { ofType } from '@ces/sourced-action';
import { Actions, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from '@state/model';
import { BTaxNetworkService } from 'egov-api';
import { concat, EMPTY, of, throwError } from 'rxjs';
import { catchError, delay, map, mergeMap, retryWhen, switchMap, take, tap } from 'rxjs/operators';
import { selectBTaxSupportedByState } from '../btax.selectors';
import { btaxStateLoaded, btaxStateLoadFailed, loadBTaxState } from './btax-state.actions';

@Injectable()
export class BTaxStateEffects
{
    constructor
    (
        // Dependencies

        private readonly store: Store<State>,
        private readonly actions$: Actions,
        private readonly networkService: BTaxNetworkService,
        private readonly snackBar: MatSnackBar,
    )
    {}

    private readonly loadBTaxState$ = createEffect(() => this.actions$.pipe(
        ofType(loadBTaxState),
        mergeMap(() => this.store.select(selectBTaxSupportedByState).pipe(
            switchMap(supported => supported
                ? this.networkService.loadState$().pipe(
                    retryWhen(errors => concat(
                        errors.pipe(delay(5000), take(12)),
                        throwError('Could not load Business Tax state')
                    )),
                    map(newState => btaxStateLoaded(`Effect of ${loadBTaxState.type}`, { newState })),
                    catchError(() => of(btaxStateLoadFailed(`Effect of ${loadBTaxState.type}`)))
                )
                : EMPTY
            )
        ))
    ));

    private readonly stateLoadFailed$ = createEffect(() => this.actions$.pipe(
        ofType(btaxStateLoadFailed),
        tap(() =>
        {
            const snackBarRef: MatSnackBarRef<SimpleSnackBar> = this.snackBar.open(
                'Could not load Business Tax service.',
                'Retry',
                { duration: 100 * 365 * 24 * 60 * 60 * 1000 /* A hundred years (Virtually forever) */ }
            );

            snackBarRef.onAction().subscribe(() => loadBTaxState('Snackbar retry'));
        })
    ), { dispatch: false });
}
