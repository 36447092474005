<div id="mobile-menu-dimmer" [class.show]="opened$ | async" (click)="close(true)" (touchstart)="close(true)"></div>

<div id="mobile-menu" class="formatted" [class.show]="opened$ | async">
    <ng-container *ngIf="accountsEnabled$ | async">
        <div class="user-widget">
            <button mat-button class="user" (click)="userWidgetClicked()">
                <span class="icon">
                    <mat-icon>account_circle</mat-icon>
                </span>
                <span *ngIf="(signedIn$ | async) !== true; else loggedIn" class="logged-out">Sign In</span>
                <ng-template #loggedIn>
                    <div class="logged-in">
                        <span class="name">{{ userName$ | async }}</span>
                        <mat-icon>
                            {{ (userNavOpened$ | async) === false ? "expand_more" : "expand_less" }}
                        </mat-icon>
                    </div>
                </ng-template>
            </button>
        </div>

        <nav *ngIf="signedIn$ | async" class="user-nav" egovSetHeight [class.closed]="(userNavOpened$ | async) === false">
            <div class="content">
                <a *ngIf="watchListsEnabled$ | async" mat-button (click)="navigateByUrl('/watch-lists')">
                    <span class="icon"></span>
                    <span>Watch Lists</span>
                </a>

                <a mat-button (click)="openSettings()">
                    <span class="icon"></span>
                    <span>Settings</span>
                </a>

                <a mat-button (click)="signOut()">
                    <span class="icon"></span>
                    <span>
                        {{ (signingOut$ | async) === false ? "Sign Out" : "Signing out..." }}
                    </span>
                </a>
            </div>
        </nav>
    </ng-container>

    <nav class="main-nav">
        <a mat-button class="cart" (click)="navigateByUrl('/cart')">
            <span class="icon">
                <mat-icon>shopping_cart</mat-icon>
            </span>
            <div class="label-and-value">
                <span class="label">Cart</span>
                <span *ngIf="showTotal$ | async" class="value cart-total">
                    {{ cartTotal$ | async | currency }}
                </span>
            </div>
        </a>

        <div class="more-buttons">
            <a mat-button class="search" (click)="navigateByUrl('/lookup/property-tax')">
                <span class="icon">
                    <mat-icon>search</mat-icon>
                </span>
                <span>Lookup</span>
            </a>
        </div>
    </nav>

    <app-powered-by color="grey"></app-powered-by>
</div>
