import { ChangeDetectionStrategy, Component, Inject, Input, Optional, Provider } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ComponentImporter, ModuleImporter } from '@egovsolutions/angular-lazy';

@Component({
    selector: 'app-lazy-component',
    template: `
        <egov-lazy-component [componentImporter]="componentImporter" [moduleImporter]="moduleImporter" [providers]="providers">
            <div class="loading" whileLoading>
                <mat-progress-spinner diameter="50" strokeWidth="3" mode="indeterminate"></mat-progress-spinner>
            </div>
        </egov-lazy-component>
    `,
    styleUrls: ['./lazy-dialog-content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LazyDialogContentComponent
{
    constructor
    (
        // Passed values

        private readonly dialogRef: MatDialogRef<LazyDialogContentComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) data: { moduleName: string, componentName: string, data?: any }
    )
    {
        Object.assign(this, data);
    }


    // Configuration

    @Input() protected componentImporter!: ComponentImporter;
    @Input() protected moduleImporter?: ModuleImporter;
    @Input() protected data?: any;


    // Providers

    protected get providers(): Provider[]
    {
        return [
            { provide: MAT_DIALOG_DATA, useValue: this.data },
            { provide: MatDialogRef, useValue: this.dialogRef },
        ];
    }
}
