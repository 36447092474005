import { ofType } from '@ces/sourced-action';
import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { billsForPPTAccountLoaded, billsForPPTAccountLoadFailed, loadBillsForPPTAccount } from './ppt.actions';
import { pptEntityEffects } from './entities';
import { PPTStateEffects } from './ppt-state/ppt-state.effects';
import { PPTNetworkService } from 'egov-api';


@Injectable()
class PPTEffects
{
    constructor
    (
        // Dependencies

        private readonly actions$: Actions,
        private readonly networkService: PPTNetworkService,
    )
    {}

    private readonly loadBillsForAccount = createEffect(() => this.actions$.pipe(
        ofType(loadBillsForPPTAccount),
        mergeMap(({ operationId, accountNumber, taxYear }) => this.networkService.bills$(accountNumber, taxYear).pipe(
            map(response => billsForPPTAccountLoaded(
                `Effect of ${loadBillsForPPTAccount.type}`,
                { operationId, response, latestYear: response.latestYear }
            )),
            catchError(error => of(billsForPPTAccountLoadFailed(
                `Effect of ${loadBillsForPPTAccount.type}`,
                { operationId, error: JSON.parse(JSON.stringify(error)) }
            )))
        )),
    ));
}

export const pptEffects = [PPTEffects, PPTStateEffects, ...pptEntityEffects];
