import { selectEntireState } from '@/app/state/selectors';
import { createSelector } from '@ngrx/store';
import { State } from '@state/model';
import { selectPPTAccountsByNumber } from './entities/account/ppt-account.selectors';
import { selectPPTBillIsByItselfPayable, selectPPTBillsById } from './entities/bill/ppt-bill.selectors';
import { selectPPTState } from './ppt-state/ppt-state.selectors';


export const selectPPT = (state: State) => state.common.ppt;

export const selectPPTAccountBillsForCartOperations =
    (accountNumber: string, excludeDelegated: boolean) => createSelector(
        selectPPTState, selectPPTAccountsByNumber, selectPPTBillsById, selectEntireState,
        (pptState, accounts, bills, s) =>
        {
            if (excludeDelegated && !pptState) return undefined;

            const account = accounts[accountNumber];

            let result: string[];

            if (account?.payableBillsWithABalance)
                result = account.payableBillsWithABalance;
            else if (account?.bills)
            {
                const billIds = account.bills;

                const payableBillIds: string[] = [];

                for (const billId of billIds)
                {
                    const itByItselfPayable = selectPPTBillIsByItselfPayable(billId, false)(s);
                    if (itByItselfPayable === undefined) return undefined;

                    if (itByItselfPayable) payableBillIds.push(billId);
                }

                result = payableBillIds;
            }
            else
                return undefined;


            // Account for turned over state

            const needsBeTurnedOver = excludeDelegated && !pptState!.handlesNotTurnedOver;
            const needsNotBeTurnedOver = excludeDelegated && !pptState!.handlesTurnedOver;

            if (needsBeTurnedOver || needsNotBeTurnedOver)
                for (const id of result)
                    if (!bills[id])
                        return undefined;

            if (needsBeTurnedOver)
                result = result.filter(billId => bills[billId]!.turnedOver);

            if (needsNotBeTurnedOver)
                result = result.filter(billId => !bills[billId]!.turnedOver);


            // Sort by tax year

            const billIdsByTaxYear = new Map<number | 'unknown', string[]>();

            for (const id of result)
            {
                const bill = bills[id];

                if (bill)
                    billIdsByTaxYear.set(bill.taxYear, (billIdsByTaxYear.get(bill.taxYear) || []).concat(id));
                else
                    billIdsByTaxYear.set('unknown', (billIdsByTaxYear.get('unknown') || []).concat(id));
            }

            result = [];

            const years = Array.from(billIdsByTaxYear.keys()).sort((a, b) => +a - +b);
            for (const year of years)
                result = result.concat(billIdsByTaxYear.get(year)!);

            return result;
        }
    );
