import { createSourcedAction } from '@ces/sourced-action';
import { CartOperationItem, EgovAPIError } from 'egov-api';
import { props } from '@ngrx/store';
import { CartItemData } from './cart-item.model';



export const setItemsInCart = createSourcedAction('Set items in cart', props<{
    items: CartItemData[]
}>());

export const updateCartItemPartialAmountIntent = createSourcedAction('Update cart item partial amount intent', props<{
    item: CartOperationItem,
    partialAmount: number,
}>());

export const updateCartItemPartialAmount = createSourcedAction('Update cart item partial amount', props<{
    intent: ReturnType<typeof updateCartItemPartialAmountIntent>
}>());

export const updateCartItemPartialFailed = createSourcedAction('Update cart item partial amount failed', props<{
    error: EgovAPIError
    intent: ReturnType<typeof updateCartItemPartialAmountIntent>
}>());

export const updateCartItemPartialComplete = createSourcedAction('Update cart item partial amount complete', props<{
    intent: ReturnType<typeof updateCartItemPartialAmountIntent>
}>());

// export const removeItemFromCart =
//     createSourcedAction('Remove item from cart', props<{ id: string }>());
