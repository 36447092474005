import { selectPPTAccountIsInCart } from '@/app/modules/ppt/state/entities/account/ppt-account.selectors';
import { selectPPTBillInCart } from '@/app/modules/ppt/state/entities/bill/ppt-bill.selectors';
import { Injectable } from '@angular/core';
import { sync } from '@ces/sync';
import { Store } from '@ngrx/store';
import { State } from '@state/model';
import { Subject } from 'rxjs';
import { CartActionsFacade } from '../../cart/services/cart-actions.facade';

@Injectable({ providedIn: 'any' })
export class PPTCart
{

    constructor
    (
        // Dependencies

        private readonly store: Store<State>,
        private readonly cartActions: CartActionsFacade,
    )
    {}


    // API settings

    public static readonly BILL_CART_TYPE = 'ppt';
    public static readonly ACCOUNT_CART_TYPE = 'ppt-account';

    public toggleBillInCart(
        billId: string,
        notifyErrors: boolean = false,
        raiseAttention: boolean = true,
        retry: () => any = () => this.toggleBillInCart(billId, notifyErrors, raiseAttention)
    ): Subject<void>
    {
        if (!sync(this.store.select(selectPPTBillInCart(billId))))
            return this.cartActions.addItemsToCart(
                [{ type: PPTCart.BILL_CART_TYPE, id: billId }],
                raiseAttention,
                notifyErrors,
                retry,
            );
        else
            return this.cartActions.removeItemsFromCart(
                [{ type: PPTCart.BILL_CART_TYPE, id: billId }],
                raiseAttention,
                notifyErrors,
                retry,
            );
    }


    // Adding / Removing entire accounts

    public toggleAccountInCart(
        accountNumber: string,
        notifyErrors: boolean = false,
        retry: () => any = () => this.toggleAccountInCart(accountNumber, notifyErrors)
    ): Subject<void>
    {
        const isAccountInCart = sync(this.store.select(selectPPTAccountIsInCart(accountNumber)));

        if (!isAccountInCart)
            return this.cartActions.addItemsToCart(
                [{ type: PPTCart.ACCOUNT_CART_TYPE, id: accountNumber }],
                true,
                notifyErrors,
                retry,
            );
        else
            return this.cartActions.removeItemsFromCart(
                [{ type: PPTCart.ACCOUNT_CART_TYPE, id: accountNumber }],
                true,
                notifyErrors,
                retry,
            );
    }
}
