import { createReducer } from '@ces/sourced-action';
import { on } from '@ngrx/store';
import { FrontEndState } from 'egov-api';
import { stateLoaded } from './app-state.actions';

const appStateInitialState = undefined;

export const stateReducer = createReducer<FrontEndState | undefined>(
    appStateInitialState,
    on(stateLoaded, (state, { newState }) =>
    ({
        // Defaults

        phoneRequiredForSignUp: false,


        ...newState as any
    })),
);
