import { DEFAULT_KEY, KEYS } from '@/api-keys';
import { ROLLBAR, RollbarErrorHandler, rollbarFactory } from '@/app/services/rollbar-error-handler.service';
import { environment } from '@/environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { MatDialogConfig, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ArtificialNavigationsModule, DEBUG_ARTIFICIAL_NAVIGATIONS } from '@egovsolutions/angular-artificial-navigations';
import { DEBUG_DIALOG_NAVIGATIONS, DialogNavigationsModule } from '@egovsolutions/angular-dialog-navigations';
import { API_PUBLIC_KEY, FORCE_SHORT_SESSION_TIMES, HOST_BASE, SIMULATE_RESPONSE_EXCEPTIONS } from 'egov-api';
import { QuickModalModule } from '@egovsolutions/angular-quick-modal';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app/app.component';
import { HeaderComponent } from './components/header/header.component';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';
import { FeedbackComponent } from './dialogs/feedback/feedback.component';
import { GetPasswordResetCodeComponent } from './dialogs/get-password-reset-code/get-password-reset-code.component';
import { PasswordResetComponent } from './dialogs/password-reset/password-reset.component';
import { SettingsHubComponent } from './dialogs/settings-hub/settings-hub.component';
import { SignInComponent } from './dialogs/sign-in/sign-in.component';
import { ValidateEmailComponent } from './dialogs/validate-email/validate-email.component';
import { BTaxCartPlugin } from './modules/cart/plugins/btax/btax.cart-plugin';
import { CART_PLUGIN } from './modules/cart/plugins/cart-plugin.model';
import { PPTCartPlugin } from './modules/cart/plugins/ppt/ppt.cart-plugin';
import { MaterialModule } from './modules/material/material.module';
import { CustomRouteReuseStrategy } from './modules/shared/services/custom-route-reuse-strategy.service';
import { SharedModule } from './modules/shared/shared.module';
import { WatchListsStateModule } from './modules/watch-lists/state/feature/watch-lists-state.module';
import { effects } from './state/effects';
import { reducers } from './state/reducers';
import { storeConfiguration } from './state/store-configuration';

const hostBase = (window as any).getAPIHostBase();

@NgModule({
    declarations:
    [
        AppComponent,
        HeaderComponent,
        SignInComponent,
        ValidateEmailComponent,
        MobileMenuComponent,
        FeedbackComponent,
        PasswordResetComponent,
        GetPasswordResetCodeComponent,
        SettingsHubComponent,
    ],
    imports:
    [
        BrowserModule,
        SharedModule,
        MaterialModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        StoreModule.forRoot(reducers, storeConfiguration),
        EffectsModule.forRoot(effects),
        StoreDevtoolsModule.instrument({ maxAge: 100, logOnly: environment.production }),
        WatchListsStateModule,

        DialogNavigationsModule,
        ArtificialNavigationsModule,
        QuickModalModule,
    ],
    providers:
    [

        // API

        { provide: HOST_BASE, useValue: hostBase },
        { provide: API_PUBLIC_KEY, useValue: hostBase && KEYS[hostBase] || DEFAULT_KEY },


        // Route reuse strategy

        {
            provide: RouteReuseStrategy,
            useClass: CustomRouteReuseStrategy
        },


        // Angular Material settings

        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: Object.assign(new MatDialogConfig(), { closeOnNavigation: false } as MatDialogConfig)
        },
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3500 } },


        // Rollbar

        { provide: ErrorHandler, useClass: RollbarErrorHandler },
        { provide: ROLLBAR, useFactory: rollbarFactory },


        // Debugging flags

        { provide: DEBUG_ARTIFICIAL_NAVIGATIONS, useValue: environment.debugArtificialNavigations },
        { provide: DEBUG_DIALOG_NAVIGATIONS, useValue: environment.debugDialogNavigations },
        { provide: FORCE_SHORT_SESSION_TIMES, useValue: environment.shortSessionTimes },
        { provide: SIMULATE_RESPONSE_EXCEPTIONS, useValue: environment.simulateResponseExceptions },


        // Cart plugins

        { provide: CART_PLUGIN, useClass: PPTCartPlugin, multi: true },
        { provide: CART_PLUGIN, useClass: BTaxCartPlugin, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
