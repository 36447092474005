import { selectCartItems } from '@cart/state/common/entities/cart-item/cart-item.selectors';
import { State } from '@/app/state/model';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@egovsolutions/angular-location';
import { Viewport } from '@egovsolutions/angular-viewport';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { selectGrandTotalWithoutFees } from '@cart/state/common/cart-common.selectors';

@Component({
    selector: 'app-cart-state-bar',
    template: `
        <button matRipple class="bar" [class.empty]="!(itemCount$ | async)" [class.in-cart]="(location.url$ | async) === '/cart'" (click)="goToCart()">
            <div class="count">{{ lastPositiveItemCount$ | async }} {{ (itemCount$ | async)! > 1 ? 'items' : 'item' }} in the cart</div>
            <div class="total">{{ lastPositiveTotal$ | async | currency }}</div>
        </button>
    `,
    styleUrls: ['./cart-state-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartStateBarComponent
{
    constructor
    (
        // Dependencies

        private readonly store: Store<State>,
        private readonly router: Router,


        // Template dependencies

        protected readonly viewport: Viewport,
        protected readonly location: Location,
    )
    {}

    protected readonly itemCount$ = this.store.select(selectCartItems).pipe(
        map(items => items?.length),
    );

    protected readonly lastPositiveItemCount$ = this.itemCount$.pipe(
        filter(v => !!v),
    );

    protected readonly lastPositiveTotal$ = this.store.select(selectGrandTotalWithoutFees).pipe(
        filter(v => !!v),
    );

    protected goToCart()
    {
        this.router.navigate(['cart']);
    }
}
