import { BreakpointObserver, BreakpointState, MediaMatcher } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Viewport as VP } from '@egovsolutions/angular-viewport';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class Viewport extends VP
{
    // Feature specific

    public mobileNavigationMediaQuery = '(max-width: 1060px)';
    public mobileNavigation$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor
    (
        // Dependencies

        private readonly bpObserver: BreakpointObserver,


        // Exposes

        public mediaMatcher: MediaMatcher,
    )
    {
        super(bpObserver, mediaMatcher);
        this.initBreakPoints();
    }

    private initBreakPoints()
    {
        this.bpObserver.observe(this.mobileNavigationMediaQuery)
            .subscribe((state: BreakpointState) => this.mobileNavigation$.next(state.matches));
    }
}
