import { State } from '@state/model';
import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { selectAppStateLoaded, selectBGs } from '@/app/state/app-state/app-state.selectors';
import { sync } from '@ces/sync';
import { Store } from '@ngrx/store';
import { NavigationBacktrack } from '@shared/services/navigation-backtrack.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BgImageService
{
    constructor
    (
        // Dependencies

        private readonly router: Router,
        private readonly store: Store<State>,
        private readonly navigationBacktrack: NavigationBacktrack,
    )
    {
        this.init();
    }


    // Initialization

    public init()
    {
        this.initializeImagePicking();
    }


    // Image picking

    private initializeImagePicking()
    {
        const currentNavigation = this.router.getCurrentNavigation();
        this.pickImage(currentNavigation ? currentNavigation.id : undefined);

        (this.router.events.pipe(filter(e => e instanceof NavigationStart)) as Observable<NavigationStart>)
            .subscribe(e => this.pickImage(this.navigationBacktrack.getOriginalNavigationId(e.id)));
    }

    private readonly appBGs$ = this.store.select(selectBGs);
    private readonly appStateLoaded$ = this.store.select(selectAppStateLoaded);

    private pickImage(navigationId?: number)
    {
        if (sync(this.appStateLoaded$))
        {
            if (navigationId !== undefined && this.previousBackgroundURLs[navigationId])
            {
                // If navigating back to the page, use previous background

                this.nextBackgroundURL$.next(this.previousBackgroundURLs[navigationId]);
            }
            else
            {
                const lastDisplayedBG = localStorage.getItem('appLastDisplayedBG');
                let newURL: string | undefined;

                const backgrounds = sync(this.appBGs$);
                const bgCount = backgrounds?.length;

                if (bgCount)
                {
                    if (bgCount > 1)
                    {
                        do
                        {
                            const randomIndex = Math.floor(Math.random() * bgCount);
                            newURL = backgrounds[randomIndex];
                        }
                        while (newURL === lastDisplayedBG);
                    }
                    else
                    {
                        newURL = backgrounds[0];
                    }
                }

                this.nextBackgroundURL$.next(newURL);
            }

            if (navigationId) this.previousBackgroundURLs[navigationId] = this.nextBackgroundURL$.value!;
            try { localStorage.setItem('appLastDisplayedBG', this.nextBackgroundURL$.value!); }
            catch {}
        }
        else
            this.appStateLoaded$.pipe(filter(loaded => loaded), first())
                .subscribe(() => this.pickImage(navigationId));
    }


    // URLs

    public nextBackgroundURL$: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
    private readonly previousBackgroundURLs: { [navigationId: number]: string } = {};
}
