import { createEntityUpdater } from '@/helpers/entity-updater';
import { createReducer } from '@ces/sourced-action';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { on } from '@ngrx/store';
import { BTaxBill } from 'egov-api';
import { addBTaxBillData } from './btax-bill.actions';
import { BTaxBillEntities } from './btax-bill.model';


// Runtime state

const addRuntimeState = (billData: BTaxBill): BTaxBill => ({
    ...billData
});


// Adapter

const adapter: EntityAdapter<BTaxBill> = createEntityAdapter<BTaxBill>();
const updater = createEntityUpdater(adapter, addRuntimeState);


// Initial state

const initialState = adapter.getInitialState() as BTaxBillEntities;


// Reducer

export const btaxBillsReducer = createReducer<BTaxBillEntities>(

    initialState,

    on(addBTaxBillData, (s, { billsData }) => updater.update(s, billsData)),
);
