import { signedIn } from '@/app/modules/session/state/session.actions';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { ofType } from '@ces/sourced-action';
import { Actions, createEffect } from '@ngrx/effects';
import { WatchListsNetworkService } from 'egov-api';
import { concat, of, throwError } from 'rxjs';
import { catchError, delay, map, mergeMap, retryWhen, take, tap } from 'rxjs/operators';
import { loadWatchListsState, watchListsStateLoaded, watchListsStateLoadFailed } from './watch-lists-state.actions';

@Injectable()
export class WatchListsStateEffects
{
    constructor
    (
        // Dependencies

        private readonly actions$: Actions,
        private readonly networkService: WatchListsNetworkService,
        private readonly snackBar: MatSnackBar,
    )
    {}

    private readonly loadAppStateOnSignIn$ = createEffect(() => this.actions$.pipe(
        ofType(signedIn),
        map(loadWatchListsState),
    ));

    private readonly loadAppState$ = createEffect(() => this.actions$.pipe(
        ofType(loadWatchListsState),
        mergeMap(() => this.networkService.loadState$().pipe(
            retryWhen(errors => concat(
                errors.pipe(delay(5000), take(12)),
                throwError('Could not load state')
            )),
            map(newState => watchListsStateLoaded(`Effect of ${loadWatchListsState.type}`, { newState })),
            catchError(() => of(watchListsStateLoadFailed(`Effect of ${loadWatchListsState.type}`)))
        ))
    ));

    private readonly stateLoadFailed$ = createEffect(() => this.actions$.pipe(
        ofType(watchListsStateLoadFailed),
        tap(() =>
        {
            const snackBarRef: MatSnackBarRef<SimpleSnackBar> = this.snackBar.open(
                'Could not load Watch Lists service.',
                'Retry',
                { duration: 100 * 365 * 24 * 60 * 60 * 1000 /* A hundred years (Virtually forever) */ }
            );

            snackBarRef.onAction().subscribe(() => loadWatchListsState('Snackbar retry'));
        })
    ), { dispatch: false });
}
