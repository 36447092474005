import { MobileMenuComponent } from '@/app/components/mobile-menu/mobile-menu.component';
import { ROLLBAR } from '@/app/services/rollbar-error-handler.service';
import { TitleService } from '@/app/services/title.service';
import { AfterContentChecked, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SessionLifetimeUI } from 'egov-api';
import { Platform } from '@egovsolutions/angular-platform';
import { ScrollableModalCommonService } from '@egovsolutions/angular-scrollable-modal';
import { Transitions } from '@shared/services/transitions.service';
import { Viewport } from '@shared/services/viewport.service';
import { environment } from 'environments/environment';
import Rollbar from 'rollbar';
import { Upgrades } from '@/app/services/upgrades.service';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
    selector: 'app-root',
    animations:
    [
        Transitions.RETAIN_LEAVING_PAGE
    ],
    template: `
        <div id="app" [ngClass]="platform.platformTokens$ | async">
            <app-progress-bar
                id="page-progress"
                *ngIf="
                    transitions.loadingNextPage$
                        | async
                        | waitABit
                            : {
                                initial: false,
                                delay: 2500,
                                immediate: (transitions.loadingNextPage$ | async) !== true
                            }
                "
            ></app-progress-bar>
            <app-header #header (sideMenuRequested)="showMobileMenu()"></app-header>

            <div
                #outletContainer
                class="router-container"
                [@retainPageAnimation]="pageChangeId$ | async"
                [style.margin-right]="(scrollableModalCommon.amountToAdjustForScrollbar$ | async) + 'px'"
            >
                <router-outlet #o="outlet"></router-outlet>
            </div>
        </div>
        <app-cart-state-bar *ngIf="viewport.phone$ | async"></app-cart-state-bar>
        <app-mobile-menu *ngIf="viewport.mobileNavigation$ | async"></app-mobile-menu>
    `,
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterContentChecked
{
    constructor
    (
        // Initialize

        upgrades: Upgrades,
        titleService: TitleService,
        @Inject(ROLLBAR) rollbar: Rollbar,
        lifetimeUI: SessionLifetimeUI,


        // Template dependencies

        protected readonly platform: Platform,
        protected readonly transitions: Transitions,
        protected readonly viewport: Viewport,
        protected readonly scrollableModalCommon: ScrollableModalCommonService,
    )
    {}


    // Log change detection

    public ngAfterContentChecked() { environment.logChangeDetectionRuns && console.log('checked'); } // eslint-disable-line


    // Values required by the Transitions service.

    @ViewChild('o', { static: true })
    private set outlet(newOutlet: RouterOutlet) { this.transitions.outlet = newOutlet; }

    @ViewChild('outletContainer', { static: true })
    private set outletContainer(newOutletContainer: ElementRef) { this.transitions.outletContainer = newOutletContainer; }

    @ViewChild('header', { read: ElementRef, static: true })
    private set header(newHeader: ElementRef) { this.transitions.header = newHeader; }

    @ViewChild(MobileMenuComponent) private readonly sideMenu!: MobileMenuComponent;


    // Provide animation ID to force leaving element to stay for a bit.

    protected readonly pageChangeId$ = this.transitions.routerAnimChanger$;


    // Bridge header menu button to actual side menu

    protected showMobileMenu() { if (this.sideMenu) this.sideMenu.open(); }
}
