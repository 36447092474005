import { AppComponent } from '@/app/components/app/app.component';
import { Injectable, Type } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { Viewport } from './viewport.service';


/* eslint-disable max-classes-per-file */

export class DefaultRouteReuseStrategy implements RouteReuseStrategy
{
    public shouldDetach(route: ActivatedRouteSnapshot): boolean { return false; }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle) {}

    public shouldAttach(route: ActivatedRouteSnapshot): boolean { return false; }
    public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null { return null; }
    public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean
    {
        return future.routeConfig === curr.routeConfig;
    }
}

@Injectable()
export class CustomRouteReuseStrategy extends DefaultRouteReuseStrategy
{
    constructor
    (
        // Dependencies for reuse logic in routes

        public readonly viewport: Viewport,
    )
    {
        super();
    }

    public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean
    {
        const currentComponent = this.getComponentForRouteSnapshot(curr);
        const futureComponent = this.getComponentForRouteSnapshot(future);

        let reuse;

        if (future?.data?.reuseLogic)
            reuse = future.data.reuseLogic(this);
        else
            reuse = future.data && Object.prototype.hasOwnProperty.call(future.data, 'reuse') ? future.data.reuse : true;

        return futureComponent === currentComponent && reuse;
    }

    private getComponentForRouteSnapshot(snapshot: ActivatedRouteSnapshot)
    {
        let component: Type<any> | string | null = null;

        let current: ActivatedRouteSnapshot | null = snapshot;

        do
        {
            if (current.component && current.component !== AppComponent)
                component = current.component;

            current = current.firstChild;
        }
        while (current);

        return component;
    }
}
