import { InjectionToken } from '@angular/core';
import { ComponentImporter } from '@egovsolutions/angular-lazy';
import { Observable } from 'rxjs';

export interface WatchListItemTypeDefinition
{
    type: string
    cartType?: string // Type to be passed to cart endpoints
    icon: string
    name: string
    itemIsPayable?: (id: string) => Observable<boolean | undefined>
    itemIsInCart?: (id: string) => Observable<boolean | undefined>
    importComponent: ComponentImporter
}

export const WATCH_LIST_ITEM_COMPONENT_DEFINITION = new InjectionToken<WatchListItemTypeDefinition>('Watch list item component definition');
