import { FeedbackComponent } from '@/app/dialogs/feedback/feedback.component';
import { GetPasswordResetCodeComponent } from '@/app/dialogs/get-password-reset-code/get-password-reset-code.component';
import { PasswordResetComponent } from '@/app/dialogs/password-reset/password-reset.component';
import { SettingsHubComponent } from '@/app/dialogs/settings-hub/settings-hub.component';
import { SignInComponent } from '@/app/dialogs/sign-in/sign-in.component';
import { ValidateEmailComponent } from '@/app/dialogs/validate-email/validate-email.component';
import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { os } from '@ces/observable-state';
import { ArtificialNavigationDefinition } from '@egovsolutions/angular-artificial-navigations';
import { DialogNavigations } from '@egovsolutions/angular-dialog-navigations';
import { ScrollableModal } from '@egovsolutions/angular-scrollable-modal';
import { Store } from '@ngrx/store';
import { selectSignedIn } from '@session/state/session.selectors';
import { LazyModal } from '@shared/services/lazy-modal.service';
import { State } from '@state/model';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppDialogsService
{
    constructor
    (

        // Dependencies

        private readonly store: Store<State>,
        private readonly snackBar: MatSnackBar,
        private readonly scrollableModal: ScrollableModal,
        private readonly dialogNavigations: DialogNavigations,
        private readonly lazyModal: LazyModal,
    )
    {
        // Bindings

        this.respondToDialogNavigations = this.respondToDialogNavigations.bind(this);


        // Init

        this.initializeDialogNavigations();
    }


    // Signed in state

    private readonly signedIn$ = os(this.store.select(selectSignedIn));


    // Dialog navigation

    private initializeDialogNavigations()
    {
        this.dialogNavigations.restore$.subscribe(this.respondToDialogNavigations);
    }

    private respondToDialogNavigations(navigationDefinition: ArtificialNavigationDefinition)
    {
        switch (navigationDefinition?.type)
        {
            case 'sign-in': this.signIn(); break;
            case 'sign-up': this.signUp(); break;
            case 'validate-email': this.validateEmail(navigationDefinition.data.email, navigationDefinition.data.firstVerification); break;
            case 'password-reset-get-code': this.passwordResetGetCode(); break;
            case 'password-reset-new-password': this.passwordResetNewPassword(navigationDefinition.data); break;
            case 'settings': this.openSettings(); break;
            case 'account-details': this.openAccountDetails(); break;
            case 'feedback': this.openFeedback(); break;
        }
    }


    // Sign in dialog
    //
    // Emits a boolean when the user has been signed in. Either through
    // the sign in dialog itself, or through another dialog created by it.
    //
    // If the user is already signed in, the dialog doesn't show and the
    // observable emits synchronically.
    //
    // If the user is not signed in, the dialog opens whether the
    // observable is subscribed to or not.

    public signIn(): Observable<boolean>
    {
        const subject: Subject<boolean> = new Subject<boolean>();
        const observable: Observable<boolean> = new Observable<boolean>(observer =>
        {
            if (this.signedIn$.value)
            {
                observer.next(true);
                observer.complete();
            }
            else
                subject.subscribe(observer);
        });

        if (!this.signedIn$.value)
        {
            const dialogRef: MatDialogRef<SignInComponent, string> =
                this.scrollableModal.open(SignInComponent, { autoFocus: true });

            this.dialogNavigations.set(dialogRef, 'sign-in');

            dialogRef.afterClosed().subscribe((result?: string) =>
            {
                switch (result)
                {
                    case 'signedIn':

                        subject.next(true);
                        subject.complete();

                        break;

                    case 'signUpRequested':

                        this.signUp().subscribe(signedIn =>
                        {
                            subject.next(signedIn);
                            subject.complete();
                        });

                        break;

                    case 'passwordResetRequested':

                        this.passwordResetGetCode().subscribe(signedIn =>
                        {
                            subject.next(signedIn);
                            subject.complete();
                        });

                        break;

                    default:

                        subject.next(false);
                        subject.complete();

                        break;
                }
            });
        }

        return observable;
    }


    // Sign up dialog
    //
    // Private because it's only reachable via the sign in dialog.
    //
    // Emits a boolean when the user has been signed *IN*. Either through
    // the sign up process itself, or through another dialog created by
    // the sign up dialog.
    //
    // If the user is already signed in, the dialog doesn't show and the
    // observable emits synchronically.
    //
    // If the user is not signed in, the dialog opens whether the
    // observable is subscribed to or not.

    private signUp(): Observable<boolean>
    {
        const subject: Subject<boolean> = new Subject<boolean>();
        const observable: Observable<boolean> = new Observable<boolean>(observer =>
        {
            if (this.signedIn$.value)
            {
                observer.next(true);
                observer.complete();
            }
            else
                subject.subscribe(observer);
        });

        if (!this.signedIn$.value)
        {
            interface SignUpResult { status: string; email?: string; }

            const dialogRef: MatDialogRef<any, SignUpResult> = this.lazyModal.open(
                () => import('@/app/dialogs/lazy/sign-up/sign-up.component')
                    .then(({ SignUpComponent }) => SignUpComponent)
            );

            this.dialogNavigations.set(dialogRef, 'sign-up');

            dialogRef.afterClosed().subscribe((result?: SignUpResult) =>
            {
                switch (result?.status)
                {
                    case 'done':

                        this.validateEmail(result.email!).subscribe(success =>
                        {
                            subject.next(success);
                            subject.complete();
                        });

                        break;


                    case 'signInRequested':

                        this.signIn().subscribe(signedIn =>
                        {
                            subject.next(signedIn);
                            subject.complete();
                        });

                        break;


                    default:

                        subject.next(false);
                        subject.complete();

                        break;
                }
            });
        }

        return observable;
    }


    // Validate email dialog

    public validateEmail(email: string, firstVerification = true): Subject<boolean>
    {
        const subject: Subject<boolean> = new Subject<boolean>();

        const dialogRef: MatDialogRef<ValidateEmailComponent, string> =
            this.scrollableModal.open(ValidateEmailComponent, { data: email });

        this.dialogNavigations.set(dialogRef, { type: 'validate-email', data: { email, firstVerification } });

        dialogRef.afterClosed().subscribe((result?: string) =>
        {
            switch (result)
            {
                case 'valid':

                    subject.next(true);
                    subject.complete();

                    this.dialogNavigations.fulfill(dialogRef.id, true);

                    this.snackBar.open(firstVerification
                        ? 'Email successfully verified. You are now signed in.'
                        : 'Email successfully verified.'
                    );

                    break;

                default:

                    subject.next(false);
                    subject.complete();

                    break;
            }
        });

        return subject;
    }


    // Get password reset code dialog
    //
    // Private because it's only reachable via the sign in dialog.

    private passwordResetGetCode(): Subject<boolean>
    {
        const subject: Subject<boolean> = new Subject<boolean>();

        const dialogRef: MatDialogRef<GetPasswordResetCodeComponent, string | undefined> =
            this.scrollableModal.open(GetPasswordResetCodeComponent);
        this.dialogNavigations.set(dialogRef, 'password-reset-get-code');

        dialogRef.afterClosed().subscribe((email?: string) =>
        {
            if (email)
            {
                this.dialogNavigations.fulfill(dialogRef.id, true);

                this.passwordResetNewPassword(email).subscribe(success =>
                {
                    subject.next(success);
                    subject.complete();
                });
            }
            else
            {
                subject.next(false);
                subject.complete();
            }
        });

        return subject;
    }


    // Password reset dialog
    //
    // Private because it's only reachable via the sign in dialog.

    private passwordResetNewPassword(email: string): Subject<boolean>
    {
        const subject: Subject<boolean> = new Subject<boolean>();

        const dialogRef: MatDialogRef<PasswordResetComponent, boolean> =
            this.scrollableModal.open(PasswordResetComponent, { data: email });

        this.dialogNavigations.set(dialogRef, { type: 'password-reset-new-password', data: email });

        dialogRef.afterClosed().subscribe((success?: boolean) =>
        {
            if (success)
            {
                this.dialogNavigations.fulfill(dialogRef.id, true);

                subject.next(true);
                subject.complete();

                this.snackBar.open('Password reset successfully. You are now signed in.');
            }
            else
            {
                subject.next(false);
                subject.complete();
            }
        });

        return subject;
    }


    // Settings dialogs

    public openSettings()
    {
        const dialogRef: MatDialogRef<SettingsHubComponent, string> =
            this.scrollableModal.open(SettingsHubComponent, { autoFocus: false });

        this.dialogNavigations.set(dialogRef, 'settings');

        return dialogRef;
    }

    public openAccountDetails(replaceState: boolean = false)
    {
        const dialogRef: MatDialogRef<any, string> = this.lazyModal.open(
            () => import('@/app/dialogs/lazy/account-details/account-details.component')
                .then(({ AccountDetailsComponent }) => AccountDetailsComponent),
            undefined,
            undefined,
            { autoFocus: true }
        );

        this.dialogNavigations.set(dialogRef, { replace: replaceState, definition: { type: 'account-details' } });

        return dialogRef;
    }


    // Feedback dialog

    public openFeedback()
    {
        const dialogRef: MatDialogRef<FeedbackComponent, string> =
            this.scrollableModal.open(FeedbackComponent, { autoFocus: true });

        this.dialogNavigations.set(dialogRef, 'feedback');

        return dialogRef;
    }
}
