import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { CdkTableModule } from '@angular/cdk/table';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule, MAT_MENU_SCROLL_STRATEGY } from '@angular/material/menu';
import { Overlay, ScrollStrategy } from '@angular/cdk/overlay';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatRippleModule } from '@angular/material/core';


export const MAT_MENU_SCROLL_STRATEGY_FACTORY = (overlay: Overlay): () => ScrollStrategy =>
    () => overlay.scrollStrategies.close();

@NgModule({
    imports:
    [
        MatButtonModule,
        MatCardModule,
        CdkTableModule,
        MatTableModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatToolbarModule,
        MatTabsModule,
        MatCheckboxModule,
        MatButtonToggleModule,
        MatSortModule,
        MatPaginatorModule,
        MatIconModule,
        MatMenuModule,
        MatChipsModule,
        MatProgressBarModule,
        MatDialogModule,
        MatDividerModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatRippleModule,
    ],
    exports:
    [
        MatButtonModule,
        MatCardModule,
        CdkTableModule,
        MatTableModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatToolbarModule,
        MatTabsModule,
        MatCheckboxModule,
        MatButtonToggleModule,
        MatSortModule,
        MatPaginatorModule,
        MatIconModule,
        MatMenuModule,
        MatChipsModule,
        MatProgressBarModule,
        MatDialogModule,
        MatDividerModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatRippleModule,
    ],
    providers:
    [
        { provide: MAT_MENU_SCROLL_STRATEGY, deps: [Overlay], useFactory: MAT_MENU_SCROLL_STRATEGY_FACTORY },
    ]
})
export class MaterialModule { }
