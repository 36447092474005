import { addPPTAccountData } from '@/app/modules/ppt/state/entities/account/ppt-account.actions';
import { selectPPTAccountByBillId } from '@/app/modules/ppt/state/entities/account/ppt-account.selectors';
import { addPPTBillData } from '@/app/modules/ppt/state/entities/bill/ppt-bill.actions';
import { selectPPTBill, selectPPTBillDependencies, selectPPTBillOrDependenciesDelegated, selectPPTBillsByAccountNumber, selectPPTBillsById } from '@/app/modules/ppt/state/entities/bill/ppt-bill.selectors';
import { addPPTParcelData } from '@/app/modules/ppt/state/entities/parcel/ppt-parcel.actions';
import { selectAccountDelegatedDialogContent, selectAccountPartiallyDelegatedDialogContent, selectPPTAllowPartialPayments, selectPPTDisallowPaymentTypes, selectPPTPaymentDependencies } from '@/app/modules/ppt/state/ppt-state/ppt-state.selectors';
import { selectPPTAccountBillsForCartOperations } from '@/app/modules/ppt/state/ppt.selectors';
import { State } from '@/app/state/model';
import { Injectable } from '@angular/core';
import { sync } from '@ces/sync';
import { DialogsService, GenericDialogComponentData } from '@egovsolutions/angular-dialogs-service';
import { PaymentType } from '@egovsolutions/angular-payment-types';
import { Store } from '@ngrx/store';
import { PPTBillCartItem, PPTBillCartItemData, PPT_BILL_CART_ITEM_TYPE } from 'egov-api';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CartPresentationalItemDefinition } from '../../models/cart-presentational-item-definition.model';
import { ConfirmDependenciesInclussion } from '../../services/confirm-dependencies-inclussion.service';
import { ConfirmDependentsRemoval } from '../../services/confirm-dependents-removal.service';
import { CartItemData } from '../../state/common/entities/cart-item/cart-item.model';
import { CartPagePlugin, CartPlugin } from '../cart-plugin.model';

@Injectable()
export class PPTCartPlugin implements CartPlugin<PPTBillCartItemData>
{
    public readonly type = PPT_BILL_CART_ITEM_TYPE;

    constructor
    (
        private readonly store: Store<State>,
        private readonly dialogs: DialogsService,
        private readonly confirmDependenciesInclussion: ConfirmDependenciesInclussion,
        private readonly confirmDependentsRemoval: ConfirmDependentsRemoval,
    )
    {}

    public parse = (cartResponse: any) =>
    {
        if (cartResponse?.cart_contents?.PPT)
        {
            const billIds = cartResponse.cart_contents.PPT.billIds?.map((id: number) => id.toString()) || [];
            const bills = cartResponse.cart_contents.PPT.bills || [];

            return billIds
                .map((billId: string): PPTBillCartItemData =>
                {
                    const bill = bills.find((b: any) => b.id === billId.toString());
                    const itemId = `${PPT_BILL_CART_ITEM_TYPE}-${billId}`;

                    return {
                        type: PPT_BILL_CART_ITEM_TYPE,
                        id: itemId,
                        amount: bill.due,
                        partialAmount: !isNaN(bill.partialAmount) ? bill.partialAmount : undefined,
                        billId,
                        accountNumber: bill.accountNumber,
                        taxYear: bill.taxYear,
                    };
                });
        }
    };

    public parseEntities(response: any)
    {
        if (response.cart_contents.PPT)
        {
            const bills = response.cart_contents.PPT.bills;
            const parcels = response.cart_contents.PPT.parcels;
            const accounts = response.cart_contents.PPT.accounts || [];

            if (accounts)
                this.store.dispatch(addPPTAccountData('PPTCart entity parsing', {
                    accountsData: accounts
                }));

            if (parcels)
                this.store.dispatch(addPPTParcelData('PPTCart entity parsing', {
                    parcelsData: parcels
                }));

            if (bills)
                this.store.dispatch(addPPTBillData('PPTCart entity parsing', {
                    billsData: bills,
                }));
        }
    }

    public partialPaymentAllowed$ = (item: PPTBillCartItemData, itemsInCart: string[]): Observable<boolean | undefined> =>
    {
        return this.store.select(selectPPTAllowPartialPayments).pipe(
            switchMap(allowPartials => allowPartials
                ? this.store.select(selectPPTPaymentDependencies).pipe(
                    switchMap(dependencies => !dependencies
                        ? of(true)
                        : this.store.select(selectPPTBill(item.billId)).pipe(
                            switchMap(bill => bill
                                ? this.store.select(selectPPTBillsByAccountNumber(bill.accountNumber)).pipe(
                                    map(bills =>
                                    {
                                        const billsToTest = dependencies === 'priors'
                                            ? bills.filter(bill => itemsInCart.includes(bill.id))
                                            : bills;

                                        const yearAllowed = billsToTest.reduce(
                                            (acc, bill) => acc > bill.taxYear ? acc : bill.taxYear,
                                            0
                                        );

                                        return bill.taxYear === yearAllowed;
                                    }),
                                )
                                : of(undefined)
                            ),
                        )
                    )
                )
                : of(allowPartials)
            )
        );
    };


    // Intercept

    private readonly defaultInterceptionMessage = 'Error trying to add items to the cart.';

    public intercept = (items: string[]): Observable<false | string[] | string | true> =>
    {
        const pptBillsById = sync(this.store.select(selectPPTBillsById));
        if (!pptBillsById) return of(this.defaultInterceptionMessage);


        // Get delegated state for all items acknowledging delegated state of dependencies

        const delegatedStates = items.map(item => sync(this.store.select(selectPPTBillOrDependenciesDelegated(item))));
        if (delegatedStates.includes(undefined)) return of(this.defaultInterceptionMessage);
        if (!delegatedStates.includes(true)) return of(false);


        // If only some are delegated, offer partial pass

        if (delegatedStates.includes(false))
        {
            const itemsThatCanPass = delegatedStates.map((delegated, i) => !delegated && items[i]).filter(id => id);

            const partiallyDelegatedContent = sync(this.store.select(selectAccountPartiallyDelegatedDialogContent));
            const dialogSettings: GenericDialogComponentData =
            {
                content: partiallyDelegatedContent,
                buttons:
                [
                    { label: 'Cancel', closeState: true },
                    { label: 'Proceed anyway', closeState: itemsThatCanPass, default: true },
                ]
            };

            return this.dialogs.dialog({ navigate: true, autoFocus: true, ...dialogSettings }).afterClosed()
                .pipe(map(answer => answer || true)) as any;
        }


        // If all delegated, forbid moving forward

        const accountDelegatedMessage = sync(this.store.select(selectAccountDelegatedDialogContent))!;
        const dialogSettings: GenericDialogComponentData = { content: accountDelegatedMessage };
        this.dialogs.dialog({ navigate: true, autoFocus: true, ...dialogSettings });
        return of(true);
    };


    // Dependency logic

    public readonly dependencyLogic =
    {
        getDependencies: (itemId: string): string[] | null | undefined =>
        {
            return sync(this.store.select(selectPPTBillDependencies(itemId, true)));
        },

        getMissingDependencies: (
            itemId: string,
            simultaneousAdditions: string[],
            itemsAlreadyInCart: string[],
        ): string[] | null | undefined =>
        {
            const dependencies = this.dependencyLogic.getDependencies(itemId)!;
            if (!dependencies) return dependencies;

            const missingDependencies = dependencies.filter(id =>
                !itemsAlreadyInCart.includes(id) && !simultaneousAdditions.includes(id)
            );

            return missingDependencies.length ? missingDependencies : null;
        },

        confirmDependenciesInclussion$: (
            itemId: string,
            simultaneousAdditions: string[],
            itemsAlreadyInCart: string[]
        ): Observable<boolean> =>
        {
            const missingDependencies = this.dependencyLogic.getMissingDependencies(itemId, simultaneousAdditions, itemsAlreadyInCart)!;
            const missingDependenciesNames = missingDependencies?.map(id => this.dependencyLogic.getBillName(id));

            return missingDependenciesNames?.length
                ? this.confirmDependenciesInclussion.launchConfirmationDialog(
                    this.dependencyLogic.getBillName(itemId),
                    missingDependenciesNames
                )
                : of(true);
        },


        getDependents: (itemId: string): string[] | null | undefined =>
        {
            const paymentDependenciesRequirement = sync(this.store.select(selectPPTPaymentDependencies));
            if (paymentDependenciesRequirement === undefined) return undefined;
            if (paymentDependenciesRequirement === false) return null;

            const bill = sync(this.store.select(selectPPTBill(itemId)));
            const accountNumber = bill?.accountNumber ||
                sync(this.store.select(selectPPTAccountByBillId(itemId)))?.accountNumber;

            if (bill && accountNumber)
            {
                const billsForCartOperations = sync(this.store.select(
                    selectPPTAccountBillsForCartOperations(accountNumber, false),
                ));

                if (billsForCartOperations)
                {
                    let requiredBills: string[] | undefined;

                    const otherBills = billsForCartOperations
                        .filter(id => id !== itemId);


                    if (paymentDependenciesRequirement === 'priors')
                    {
                        requiredBills = [];

                        const bills = otherBills.map(id => sync(this.store.select(selectPPTBill(id))));

                        for (const b of bills)
                        {
                            if (!b) return undefined;

                            if (b.taxYear > bill.taxYear)
                                requiredBills.push(b.id);
                        }
                    }
                    else if (paymentDependenciesRequirement === 'all')
                        requiredBills = otherBills;

                    if (requiredBills && requiredBills.length > 0)
                        return requiredBills;
                    else
                        return null;
                }
            }
        },

        getAddedDependents: (
            itemId: string,
            simultaneousRemovals: string[],
            itemsInCart: string[],
        ): string[] | null | undefined =>
        {
            const dependents = this.dependencyLogic.getDependents(itemId)!;
            if (!dependents) return dependents;

            const addedDependents =
                dependents.filter(id => itemsInCart.includes(id) && !simultaneousRemovals.includes(id));

            return addedDependents.length ? addedDependents : null;
        },

        confirmDependentsRemoval$: (
            itemId: string,
            simultaneousAdditions: string[],
            itemsInCart: string[],
        ): Observable<boolean> =>
        {
            const addedDependents = this.dependencyLogic.getAddedDependents(itemId, simultaneousAdditions, itemsInCart)!;
            const addedDependentsNames = addedDependents?.map(id => this.dependencyLogic.getBillName(id));

            return addedDependentsNames?.length
                ? this.confirmDependentsRemoval.launchConfirmationDialog(
                    this.dependencyLogic.getBillName(itemId) + ' for this account',
                    addedDependentsNames
                )
                : of(true);
        },

        getBillName: (billId: string) =>
        {
            const bill = sync(this.store.select(selectPPTBill(billId)));

            if (bill)
                return `Tax Year ${bill.taxYear}`;

            return `Bill #${billId}`;
        },
    };
}


@Injectable()
export class PPTCartPagePlugin implements CartPagePlugin<PPTBillCartItemData>
{
    constructor
    (
        private readonly store: Store<State>,
    )
    {}

    public readonly type = PPT_BILL_CART_ITEM_TYPE;

    public createPresentationalItems(cartItems: CartItemData[]): CartPresentationalItemDefinition[]
    {
        const accountNumbers = new Set<string>();

        for (const item of cartItems)
        {
            if (item.type === PPT_BILL_CART_ITEM_TYPE)
                accountNumbers.add((item as PPTBillCartItem).accountNumber);
        }

        return Array.from(accountNumbers).map(aN => (
        {
            type: 'ppt-account',
            id: aN,
            componentImporter:
                () => import('@cart/plugins/ppt/ppt-account-cart-item.component')
                    .then(({ PPTAccountCartItemComponent }) => PPTAccountCartItemComponent),
        }));
    }

    public readonly paymentMessages =
    [
        {
            type: 'warning' as const,
            content: 'If you have a mortgage company that pays your property taxes, do not remit a payment.',
            dismissable: true,
        }
    ];

    public readonly paymentTypeDisallowed =
    {
        descriptors:
        [
            {
                id: 'ppt-delinquent-bill',
                itemMessage: 'Delinquent bills cannot be paid with the payment method selected.',
                paymentFormMessage: 'This payment method cannot be used to pay delinquent bills. Pick another payment method or remove all delinquent bills from the cart to be able to proceed with the payment.',
            }
        ],
        isPaymentTypeAllowed$: (item: PPTBillCartItemData, paymentType: PaymentType): Observable<true | string[] | undefined> =>
        {
            const bill$ = this.store.select(selectPPTBill(item.billId));

            return combineLatest([this.store.select(selectPPTDisallowPaymentTypes), bill$]).pipe(
                map(([disallowPaymentTypes, bill]) =>
                {
                    if (disallowPaymentTypes)
                    {
                        if (disallowPaymentTypes.delinquent?.includes(paymentType) && bill?.delinquent)
                            return ['ppt-delinquent-bill'];
                        else
                            return true;
                    }
                }),
            );
        }
    };
}
