import { Injectable } from '@angular/core';
import { ofType } from '@ces/sourced-action';
import { Actions, createEffect } from '@ngrx/effects';
import { FrontEndSession } from 'egov-api';
import deepEqual from 'fast-deep-equal';
import { EMPTY, of } from 'rxjs';
import { distinctUntilChanged, map, mergeMap, pairwise, switchMap } from 'rxjs/operators';
import { expirationUpdated, newSession, sessionInfoUpdated, signedIn, signedOut } from './session.actions';


@Injectable()
export class SessionEffects
{
    constructor
    (
        // Dependencies

        private readonly session: FrontEndSession,
        private readonly actions$: Actions,
    )
    {}


    // New session info

    private readonly sessionInfoUpdated$ = createEffect(() => this.actions$.pipe(
        ofType('@ngrx/effects/init'),
        switchMap(() => this.session.sessionInfo$),
        distinctUntilChanged((old, nu) =>
            old?.jwt === nu?.jwt
            &&
            old?.type === nu?.type
            &&
            deepEqual(old?.user, nu?.user)
        ),
        map(sessionInfo => sessionInfoUpdated({ sessionInfo })),
    ));

    private readonly expirationUpdated$ = createEffect(() => this.actions$.pipe(
        ofType('@ngrx/effects/init'),
        switchMap(() => this.session.expiration$),
        distinctUntilChanged(),
        switchMap(expiration => expiration ? of(expirationUpdated({ expiration })) : EMPTY),
    ));

    private readonly signedIn$ = createEffect(() => this.actions$.pipe(
        ofType(sessionInfoUpdated),
        map(action => !!(action.sessionInfo && action.sessionInfo.type === 'user')),
        distinctUntilChanged(),
        mergeMap(_signedIn => _signedIn ? of(signedIn(`Effect of ${sessionInfoUpdated.type}`)) : EMPTY),
    ));

    private readonly newSession$ = createEffect(() => this.actions$.pipe(
        ofType(sessionInfoUpdated),
        map(action => !!action.sessionInfo),
        distinctUntilChanged(),
        mergeMap(hasSession => hasSession ? of(newSession(`Effect of ${sessionInfoUpdated.type}`)) : EMPTY),
    ));


    // Sign out

    private readonly signedOut$ = createEffect(() => this.actions$.pipe(
        ofType('@ngrx/effects/init'),
        switchMap(() => this.session.sessionInfo$),
        pairwise(),
        mergeMap(([sessionBefore, sessionNow]) => sessionBefore && !sessionNow
            ? of(signedOut('Effect of session sign out'))
            : EMPTY
        ),
    ));
}
