import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'any' })
export class Animation
{
    // Easing

    public static readonly DEFAULT_EASING: string = 'cubic-bezier(.6, 0, .1, 1)';


    // Detected animation events for the browser

    public readonly transitionEndEvent: string = ((): string =>
    {
        const el: HTMLElement = document.createElement('testelement');

        /* eslint-disable @typescript-eslint/naming-convention */
        const transitions: any = {
            transition: 'transitionend',
            OTransition: 'oTransitionEnd',
            MozTransition: 'transitionend',
            WebkitTransition: 'webkitTransitionEnd',
        };
        /* eslint-enable @typescript-eslint/naming-convention */

        for (const t in transitions)
            if ((el.style as any)[t] !== undefined)
                return transitions[t];

        return 'transitionend';
    })();

    public readonly animationEndEvent: string = ((): string =>
    {
        const el: HTMLElement = document.createElement('testelement');

        /* eslint-disable @typescript-eslint/naming-convention */
        const animations: any = {
            animation: 'animationend',
            OAnimation: 'oAnimationEnd',
            MozAnimation: 'animationend',
            WebkitAnimation: 'webkitAnimationEnd',
        };
        /* eslint-enable @typescript-eslint/naming-convention */

        for (const t in animations)
            if ((el.style as any)[t] !== undefined)
                return animations[t];

        return 'animationend';
    })();
}
