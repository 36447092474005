import { selectCartItems } from '@/app/modules/cart/state/common/entities/cart-item/cart-item.selectors';
import { AppDialogsService } from '@/app/services/app-dialogs.service';
import { selectAppState, selectAppStateLoaded, selectClientsLogoImage, selectClientsLogoImageAspectRatio, selectClientsName, selectClientsSlogan, selectClientsTitle, selectUserAccountsEnabled } from '@/app/state/app-state/app-state.selectors';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { selectGrandTotalWithoutFees } from '@cart/state/common/cart-common.selectors';
import { AppTickScheduler } from '@egovsolutions/angular-app-tick-scheduler';
import { Location } from '@egovsolutions/angular-location';
import { Platform } from '@egovsolutions/angular-platform';
import { ScrollableModalCommonService } from '@egovsolutions/angular-scrollable-modal';
import { Store } from '@ngrx/store';
import { selectSignedIn, selectUserName } from '@session/state/session.selectors';
import { ImageLoader } from '@shared/services/image-loader.service';
import { Viewport } from '@shared/services/viewport.service';
import { State } from '@state/model';
import { selectWatchListsEnabled } from '@watch-lists/state/common/state/watch-lists-state.selectors';
import { FrontEndSession } from 'egov-api';
import { BehaviorSubject, combineLatest, connectable, fromEvent, merge, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { HeaderService } from './header.service';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit
{
    constructor
    (
        // Dependencies

        private readonly store: Store<State>,
        private readonly service: HeaderService,
        private readonly appDialogsService: AppDialogsService,
        private readonly location: Location,
        private readonly imageLoader: ImageLoader,
        private readonly platform: Platform,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly ngZone: NgZone,
        private readonly tickScheduler: AppTickScheduler,
        private readonly session: FrontEndSession,


        // Template dependencies

        protected scrollableModalCommon: ScrollableModalCommonService,
        protected viewport: Viewport,
    )
    {}


    // Side menu requested

    @Output() public readonly sideMenuRequested: EventEmitter<void> = new EventEmitter<void>();


    // Initialization

    public ngOnInit()
    {
        this.initializeLogoScaling();
    }


    // Header state

    protected readonly collapsed$ =
        this.service.reasonsToShow$.pipe(tap(() => this.tickScheduler.scheduleTick()), map(r => !r.size));

    protected readonly showBG$ =
        this.service.closeToTop$.pipe(tap(() => this.tickScheduler.scheduleTick()), map(v => !v));


    // Logo values

    protected readonly logoImageURL$ = this.store.select(selectClientsLogoImage);
    protected readonly logoImageAspectRatio$ = this.store.select(selectClientsLogoImageAspectRatio).pipe(map(r => r || 1));

    protected readonly clientsName$ = this.store.select(selectClientsName);
    protected readonly clientsTitle$ = this.store.select(selectClientsTitle);
    protected readonly clientsSlogan$ = this.store.select(selectClientsSlogan);


    // Cart button

    protected readonly drawAttentionToCart$ = this.service.drawAttentionToCart$;
    protected readonly cartTotal$ = combineLatest([
        this.service.frozenCartTotal$,
        this.store.select(selectGrandTotalWithoutFees)
    ])
    .pipe(
        map(([frozenCartTotal, grandTotalWithoutFees]) =>
            frozenCartTotal === undefined ? grandTotalWithoutFees : frozenCartTotal),
    );

    protected readonly itemCount$ = this.store.select(selectCartItems).pipe(
        map(items => items?.length)
    );


    // State values

    protected readonly signedIn$ = this.store.select(selectSignedIn);
    protected readonly appStateLoaded$ = this.store.select(selectAppStateLoaded);
    protected readonly accountsEnabled$ = this.store.select(selectUserAccountsEnabled);
    protected readonly inParcelLanding$ = this.location.url$.pipe(map(url => url === '/lookup/property-tax' || url === '/lookup/business-tax'));
    protected readonly inCart$ = this.location.url$.pipe(map(url => url === '/cart'));
    protected readonly userName$ = this.store.select(selectUserName());


    // Logo image loaded

    protected readonly doneLoadingLogoImage$ = this.store.select(selectAppState).pipe(
        switchMap(s => s ? this.imageLoader.load(s.clientsLogoImage) : of(s)),
        map(v => !!v)
    );


    // Logo scaling

    protected readonly textScale$ = new BehaviorSubject(1);

    @ViewChild('textsContainer', { static: true }) private readonly textsContainer?: ElementRef;
    private textsReference?: HTMLDivElement;

    private initializeLogoScaling()
    {
        this.scheduleFirstTitleUpdate();

        this.ngZone.runOutsideAngular(() =>
        {
            merge(
                fromEvent(window, 'resize'),
                fromEvent(window, 'orientationchange'),
                fromEvent(window, 'load')
            )
            .subscribe(() =>
            {
                this.updateTextScaleAndDetectChanges();
                setTimeout(() => this.updateTextScaleAndDetectChanges(), 0);
            });
        });
    }

    private scheduleFirstTitleUpdate()
    {
        requestAnimationFrame(() =>
        {
            this.updateTextScaleAndDetectChanges();

            if (!this.textsReference)
                this.scheduleFirstTitleUpdate();
        });
    }

    private updateTextScale()
    {
        if (!this.textsReference)
        {
            if (this.textsContainer?.nativeElement)
            {
                const elementFound: HTMLDivElement | undefined = (this.textsContainer.nativeElement as HTMLDivElement)
                    .querySelector(
                        '.texts.reference .all-texts:not(.placeholders)'
                    ) as HTMLDivElement | undefined;

                if (elementFound)
                    this.textsReference = elementFound;
            }
        }

        if (this.textsReference)
        {
            let ratio: number = this.textsReference.offsetWidth / this.textsReference.scrollWidth;


            // Safari appears to have some problem with rounding computed font sizes when scaled through em units.
            // Forcing the ratio to be a bit lower prevents part of the text from being clipped out.
            //
            // If you find that it is still cropping the last part of the text, take this factor a little bit lower.

            if (this.platform.isSafari) ratio *= .97;


            this.textScale$.next(ratio < 1 ? ratio : 1);
        }
        else
            this.textScale$.next(1);
    }

    private updateTextScaleAndDetectChanges()
    {
        const previousTextScale = this.textScale$.value;

        this.updateTextScale();

        if (this.textScale$.value !== previousTextScale)
            this.changeDetector.detectChanges();
    }


    // Watch lists state

    protected readonly watchListsEnabled$ = this.store.select(selectWatchListsEnabled);


    // Actions

    protected signIn() { this.appDialogsService.signIn(); }
    protected showMenu() { this.sideMenuRequested.emit(); }
    protected openSettings() { this.appDialogsService.openSettings(); }
    protected signOut() { connectable(this.session.signOut$()).connect(); }

}
