import { createReducer } from '@ces/sourced-action';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { on } from '@ngrx/store';
import { PPTBillEntities } from './ppt-bill.model';
import { addPPTBillData } from './ppt-bill.actions';
import { createEntityUpdater } from '@/helpers/entity-updater';
import { PPTBill, PPTBillData } from 'egov-api';


// Runtime state

const addDefaults = (billData: PPTBillData): PPTBill => ({


    // Defaults

    parcelInformation: null,
    appraisalInformation: null,
    taxInformation: null,
    cityTaxSite: null,
    countyTaxSite: null,
    turnedOver: false,
    delegateLink: null,


    // Response

    ...billData as any,


    // Testing

    // appraisalInformation: {
    //     landValue: 12345,
    //     personalPropertyValue: 23456,
    //     improvementValue: 3456,
    //     totalPropertyValue: 55678,
    //     yearAppraised: 2020
    // },

    // taxInformation: {
    //     totalPropertyValue: 55678,
    //     assessmentPercentage: 25,
    //     assessedValue: 5150.26,
    //     taxRate: 5,
    //     taxLevy: 250.55,
    //     fees: 12.5,
    //     interest: 1.32,
    //     stateRelief: 0,
    //     countyRelief: 0,
    //     payments: 0,
    //     billBalance: 203.13,
    // }

    // cityTaxSite: 'lalala',
    // countyTaxSite: 'lalala',

    // paymentBlocked: true,


    // Hardcoded until feature signed off on:

    // ['turned' + 'Over']: false,
    // ['delegate' + 'Link']: null,
});


// Adapter

const adapter: EntityAdapter<PPTBill> = createEntityAdapter<PPTBill>();
const updater = createEntityUpdater(adapter, addDefaults);


// Initial state

const initialState = adapter.getInitialState() as PPTBillEntities;


// Reducer

export const pptBillsReducer = createReducer<PPTBillEntities>(

    initialState,

    on(addPPTBillData, (s, { billsData }) => updater.update(s, billsData)),
);
