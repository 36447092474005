import Rollbar from 'rollbar';
import { ErrorHandler, Inject, Injectable, InjectionToken } from '@angular/core';
import { environment } from 'environments/environment';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';


// Configuration

const rollbarConfig = {
    accessToken: '4543d2f921634916886ac67e9bd8c839',
    captureUncaught: true,
    captureUnhandledRejections: true,
};


// Injection token

export const ROLLBAR = new InjectionToken<Rollbar>('rollbar');


// Factory

export const rollbarFactory = () => new Rollbar(rollbarConfig);


// Implementation of Angular's ErrorHandler interface

@Injectable()
export class RollbarErrorHandler implements ErrorHandler
{
    constructor
    (
        // Dependencies

        @Inject(ROLLBAR) private readonly rollbar: Rollbar,
        private readonly snackBar: MatSnackBar,
    )
    {}


    private rethrownError?: any;

    public handleError(err: any)
    {

        // Depending on the context in which the error happens, rethrowing it here
        // may cause this handler to be called again. That's why we need to store
        // the last rethrown error and make sure we don't react to it more than
        // once.

        if (err !== this.rethrownError)
        {
            if (environment.production)
                this.rollbar.error(err.originalError || err);

            const snackBarRef: MatSnackBarRef<SimpleSnackBar> = this.snackBar.open('This application has encountered an issue.', 'Reload it', { duration: 100 * 365 * 24 * 60 * 60 * 1000 /* Virtually forever */ });
            snackBarRef.onAction().subscribe(() => location.reload());
        }


        // Rethrow it so code execution will stop

        this.rethrownError = err;
        setTimeout(() => { throw err; }, 0);
    }
}
