import { createSourcedAction } from '@ces/sourced-action';
import { props } from '@ngrx/store';
import { CardProvider, PaymentType } from '@egovsolutions/angular-payment-types';
import { EgovAPIError, PaymentData } from 'egov-api';
import { PaymentOption } from '@payment/components/new-payment-method-form/new-payment-method-form.component';


export const invalidateFees = createSourcedAction('Invalidate cart fees');

export const setPaymentOption = createSourcedAction('Set payment option', props<{ paymentOption?: PaymentOption }>());
export const setExistingPaymentMethod = createSourcedAction('Set selected existing payment method', props<{ paymentMethod?: string | null }>());

export const setPaymentOptionToExistingIfNotSet = createSourcedAction('Set payment option to EXISTING if not set');
export const clearExistingPaymentOption = createSourcedAction('Clear \'EXISTING\' payment option');

export const setExistingPaymentMethodIfNotSet = createSourcedAction(
    'Set existing payment method if not set',
    props<{ paymentMethods: string[] }>()
);

export const submitCartPayment = createSourcedAction('Submit cart payment', props<{
    cardProvider: CardProvider,
    paymentData: PaymentData | string,
    grandTotalWithoutFees: number,
    grandTotalWithFees: number,
    paymentType: PaymentType,
}>());

export const cartPaymentSuccess = createSourcedAction('Cart payment success', props<{
    receiptId: string
    cardType: CardProvider,
    paymentType: PaymentType,
    printUrl?: string
}>());

export const cartPaymentError = createSourcedAction('Cart payment error', props<{
    error: EgovAPIError
}>());
