import { State } from '@state/model';
import { createSelector } from '@ngrx/store';


export const selectSessionState = (state: State) => state.session;

export const selectSessionInfo = createSelector(selectSessionState, s => s.sessionInfo);
export const selectSessionActive = createSelector(selectSessionInfo, s => !!s?.jwt);
export const selectSignedIn = createSelector(selectSessionInfo, s => !!s && s.type === 'user');

export const selectUser = createSelector(selectSessionInfo, i => i?.user);
export const selectPersonalInfo = createSelector(selectUser, u => u?.personalInfo);
export const selectUserName = (fallback: string = 'User') => createSelector(
    selectPersonalInfo,
    info =>
    {
        if (info?.name)
        {
            let name: string = info.name.first;
            if (info.name.last) name += ' ' + info.name.last;

            return name;
        }
        else
            return fallback;
    }
);

export const selectSigningOut = createSelector(selectSessionState, s => s.signingOut);
