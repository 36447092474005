import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { DialogsService } from '@egovsolutions/angular-dialogs-service';
import { EgovAPIError } from 'egov-api';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'any' })
export class SimpleErrorHandling
{
    constructor
    (
        private readonly snackBar: MatSnackBar,
        private readonly dialogs: DialogsService,
    )
    {}


    public getValidationErrors(error: EgovAPIError | undefined): any | undefined
    {
        return error?.response?.invalid;
    }


    public getValidationErrorsOrDisplayBestMessage(
        error: EgovAPIError | undefined,
        options?: SimpleErrorHandlingOptions
    ): any | undefined
    {
        const invalidMessages = this.getValidationErrors(error);


        // Check if invalidMessages has non-ignorable properties

        let hasMessages = false;

        if (invalidMessages)
        {
            const clonedInvalidMessages = JSON.parse(JSON.stringify(invalidMessages));

            if (options?.ignoreValidationProperties)
                for (const propertyName of options.ignoreValidationProperties)
                    clonedInvalidMessages[propertyName] = undefined;

            for (const name in clonedInvalidMessages)
            {
                if (clonedInvalidMessages[name] && clonedInvalidMessages[name] !== true)
                {
                    hasMessages = true;
                    break;
                }
            }
        }


        // If no messages, show message

        if (!hasMessages)
            this.displayBestErrorMessage(error, options);


        return invalidMessages;
    }


    public getBestErrorMessage(error: EgovAPIError | undefined, options?: SimpleErrorHandlingOptions)
    {

        // Defaults

        options = {
            skipValidationMessages: false,
            preferCustomOverEventMessage: false,
            preferCustomOverHttpMessage: true,
            useDialogInsteadOfSnackbar: false,
            ...options
        };


        if (!options.skipValidationMessages)
        {
            const invalidMessages = this.getValidationErrors(error);

            if (invalidMessages)
            {
                for (const name in invalidMessages)
                {
                    if (Object.prototype.hasOwnProperty.call(invalidMessages, name))
                    {
                        const message = invalidMessages[name][0];

                        if (message)
                            return message;
                    }
                }
            }
        }

        if (error?.message && !(options.customMessage && options.preferCustomOverEventMessage))
            return error.message;

        if (options.customMessage)
            return options.customMessage;
    }


    public displayBestErrorMessage(error: EgovAPIError | undefined, options?: SimpleErrorHandlingOptions)
    {
        this.displayMessage(
            this.getBestErrorMessage(error, options),
            !error?.permanent ? options?.retryHandler : undefined,
            options?.useDialogInsteadOfSnackbar
        );
    }


    public displayMessage(message: string, retryHandler?: () => any, dialog: boolean = false)
    {
        if (!dialog)
            this.displaySnackbar(message, retryHandler);
        else
            this.displayDialog(message, retryHandler);
    }


    public displaySnackbar(message: string, retryHandler?: () => any)
    {
        const snackBarRef: MatSnackBarRef<SimpleSnackBar> =
            this.snackBar.open(message, retryHandler ? 'Retry' : undefined);

        if (retryHandler) snackBarRef.onAction().subscribe(retryHandler);

        return snackBarRef;
    }


    public displayDialog(message: string, retryHandler?: () => any)
    {
        const options: any = {
            content: message,
            html: false,
            autoFocus: true,
        };

        if (retryHandler)
        {
            options.buttons =
            [
                {
                    label: 'Cancel',
                    closeState: 'cancel',
                    default: false,
                },
                {
                    label: 'Retry',
                    closeState: 'retry',
                    default: true,
                }
            ];
        }

        const dialogRef = this.dialogs.dialog(options);
        if (retryHandler) dialogRef.afterClosed().pipe(filter(v => v === 'retry')).subscribe(retryHandler);
    }
}

export interface SimpleErrorHandlingOptions
{
    customMessage?: string; // What to show if a better error message isn't available

    skipValidationMessages?: boolean; // Don't check for server validation messages in general
    ignoreValidationProperties?: string[]; // Don't check for specific validation message properties

    preferCustomOverEventMessage?: boolean; // Prefer the custom message over the event message. Defaults to false.
    preferCustomOverHttpMessage?: boolean; // Prefer the custom message over the http error message. Defaults to true.

    useDialogInsteadOfSnackbar?: boolean;

    // If passed and the error is not permanent, a Retry button will be shown and this action will be run when pressed
    retryHandler?: () => any;
}
