import { ChangeDetectionStrategy, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FrontEndSession } from 'egov-api';
import { SimpleErrorHandling } from '@shared/services/simple-error-handling.service';

@Component({
    selector: 'app-validate-email',
    template: `
        <egov-dialog-close-button></egov-dialog-close-button>

        <form [formGroup]="form" (ngSubmit)="validate()">
            <p class="prevent-overlap-with-close-button">Enter the verification code we sent you via email.</p>

            <mat-form-field appearance="standard" hideRequiredMarker>
                <input
                    #codeInput
                    matInput
                    type="tel"
                    formControlName="code"
                    required
                    minlength="6"
                    maxlength="6"
                    [style.letter-spacing]="'1em'"
                    [style.padding-left]="'.5em'"
                    [egovCleave]="{ numericOnly: true, blocks: [6] }"
                />
            </mat-form-field>

            <button mat-flat-button color="primary" class="bigger" type="submit" [disabled]="!form.valid">
                {{ form.disabled ? "Verifying..." : "Verify email" }}
            </button>
        </form>
    `,
    styleUrls: ['./validate-email.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidateEmailComponent
{
    constructor
    (
        // Dependencies

        private readonly session: FrontEndSession,
        private readonly simpleErrorHandling: SimpleErrorHandling,


        // Dialog reference and passed values

        private readonly dialogRef: MatDialogRef<ValidateEmailComponent>,
        @Inject(MAT_DIALOG_DATA) private readonly email: string,
    )
    {}


    // Form

    protected readonly form: UntypedFormGroup = new UntypedFormGroup({
        code: new UntypedFormControl(''),
    });

    @ViewChild('codeInput') private readonly focusField!: ElementRef<HTMLInputElement>;

    protected validate()
    {
        this.form.disable();

        this.session.validateEmail$(this.email, this.form.get('code')!.value).subscribe({

            next: () => this.dialogRef.close('valid'),
            error: error =>
            {
                this.simpleErrorHandling.displayBestErrorMessage(error, {
                    customMessage: 'Error trying to verify the email.',
                    retryHandler: () => this.validate()
                });

                this.form.enable();
                this.focusField.nativeElement.focus();
            },
        });
    }
}
