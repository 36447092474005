import { createReducer } from '@ces/sourced-action';
import { Action, on } from '@ngrx/store';
import { cartPaymentSuccess } from '../feature/cart-feature.actions';
import { addCartUpdater, cartContentsLoaded, flushCartState, invalidateTotal, removeCartUpdater, setCartLoadFailed } from './cart-common.actions';
import { CartCommonState } from './cart-common.model';
import { cartItemsReducer } from './entities/cart-item/cart-item.reducer';


// Initial state

const initialState = {
    cartBeingUpdated: 0,
    cartLoadFailed: false,
} as unknown as CartCommonState;


// Reducer

const cartRootReducer = createReducer<CartCommonState>(
    initialState,

    on(cartContentsLoaded, (s, { cartResponse }) => ({
        ...s,
        latestCartResponse: cartResponse,
        grandTotalWithoutFees: cartResponse?.cart_contents?.payment_fees?.grand_total_without_fees ?? 0,
        supportsPartialAccountUpdates: cartResponse?.cart_contents.supportsPartialAccountUpdates ?? false,
        providesAllPayableItemsForEachAccount: cartResponse?.cart_contents.providesAllPayableItemsForEachAccount ?? false,
    })),

    on(addCartUpdater, s => ({ ...s, cartBeingUpdated: s.cartBeingUpdated + 1 })),
    on(removeCartUpdater, s => ({ ...s, cartBeingUpdated: s.cartBeingUpdated - 1 })),

    on(setCartLoadFailed, (s, { failed }) => ({ ...s, cartLoadFailed: failed })),

    on(invalidateTotal, s => ({ ...s, grandTotalWithoutFees: undefined })),

    on(cartPaymentSuccess, (s, { receiptId, printUrl }) => ({
        ...s,
        paidOrderReceiptId: receiptId,
        paidOrderItems: s.items,
        paidOrderGrandTotalWithoutFees: s.grandTotalWithoutFees,
        paidOrderReceiptUrl: printUrl,
    })),

    on(flushCartState, s => ({
        ...s,
        latestCartResponse: undefined,
        grandTotalWithoutFees: undefined,
        cartLoadFailed: false,
    })),
);

export const composedCartCommonReducer = (state: CartCommonState | undefined, action: Action) =>
{
    if (state === undefined) state = initialState;

    const reducedItemsState = cartItemsReducer(state?.items, action);
    const updatedState = reducedItemsState !== state?.items
        ? { ...state, items: reducedItemsState }
        : state;

    return cartRootReducer(updatedState, action);
};
