// DO NOT CHANGE THESE
//
// These properties exist for development-time testing purposes only and are only intended to be
// changed in `environment.dev.ts`, not here.

export const environment =
{
    production: true,

    shortSessionTimes: false,
    simulateResponseExceptions: false,

    debugArtificialNavigations: false,
    debugDialogNavigations: false,

    startWithMobileMenuOpened: false,

    logChangeDetectionRuns: false,
};
