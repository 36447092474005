import { selectItemInCart } from '@/app/modules/cart/state/common/entities/cart-item/cart-item.selectors';
import { BTaxAccount, BTAX_BILL_CART_ITEM_TYPE, formatAddress } from 'egov-api';
import { createSelector } from '@ngrx/store';
import { State } from '@state/model';
import { selectEntireState } from '@state/selectors';

const selectBTaxAccountEntity = (s: State) => s.common.btax.entities.accounts;

export const selectBTaxAccounts = createSelector(selectBTaxAccountEntity, s => s.ids.map(id => s.entities[id]!));

export const selectBTaxAccountsByNumber = createSelector(selectBTaxAccountEntity, s => s.entities);
export const selectBTaxAccount = (accountNumber: string | undefined) => createSelector(
    selectBTaxAccountsByNumber,
    accountsByNumber => accountNumber !== undefined ? accountsByNumber[accountNumber] : undefined
);

export const selectBTaxAccountPath = (accountNumber: string | undefined) => createSelector(
    selectBTaxAccountsByNumber,
    accountsByNumber => accountNumber !== undefined ? `/business-tax/account/${accountNumber}` : undefined
);

export const selectBTaxAccountByBillId = (id: string) => createSelector(
    selectBTaxAccounts,
    accounts =>
        accounts.find(account => account.bills?.includes(id) || account.billsWithABalance?.includes(id))
);

export const selectBTaxAccountStatus = (accountNumber: string) => createSelector(
    selectBTaxAccountsByNumber,
    accountsByNumber =>
        accountsByNumber[accountNumber]?.status
);

export const selectBTaxAccountBusinessName = (accountNumber: string) => createSelector(
    selectBTaxAccountsByNumber,
    accountsByNumber => accountsByNumber[accountNumber]?.businessName
);

export const selectBTaxAccountFictitiousName = (accountNumber: string) => createSelector(
    selectBTaxAccountsByNumber,
    accountsByNumber => accountsByNumber[accountNumber]?.fictitiousName
);

export const selectBTaxAccountOwnerName = (accountNumber: string) => createSelector(
    selectBTaxAccountsByNumber,
    accountsByNumber => accountsByNumber[accountNumber]?.owner
);

function accountHasTitle(account: BTaxAccount)
{
    return !!(account.businessName || account.fictitiousName || account.owner);
}

export const selectBTaxAccountHasTitle = (accountNumber: string) => createSelector(
    selectBTaxAccountsByNumber,
    accountsByNumber =>
    {
        const account = accountsByNumber[accountNumber];

        if (account)
            return accountHasTitle(account);

        return undefined;
    }
);

export const selectBTaxAccountFriendlyTitle = (accountNumber: string, includeOwner = true) => createSelector(
    selectBTaxAccountsByNumber,
    accountsByNumber =>
    {
        const account = accountsByNumber[accountNumber];

        if (account)
        {
            if (accountHasTitle(account))
            {
                const name = account.businessName || account.fictitiousName;

                if (name)
                {
                    if (includeOwner && account.owner)
                        if (name !== account.owner)
                            return `${name} — ${account.owner}`;
                        else
                            return name;
                    else
                        return name;
                }
                else
                    if (account.owner)
                        return account.owner;
            }

            return 'N / A';
        }

        return undefined;
    }
);

export const selectBTaxAccountStartDate = (accountNumber: string) => createSelector(
    selectBTaxAccountsByNumber, accountsByNumber => accountsByNumber[accountNumber]?.startDate
);

export const selectBTaxAccountHasBusinessAddress = (accountNumber: string) => createSelector(
    selectBTaxAccountsByNumber,
    accountsByNumber =>
    {
        const account = accountsByNumber[accountNumber];

        if (account?.businessAddress)
        {
            if (formatAddress(account.businessAddress, { skipName: account.businessName }))
                return true;

            return false;
        }

        return undefined;
    }
);

export const selectBTaxAccountBusinessAddress = (accountNumber: string) => createSelector(
    selectBTaxAccountsByNumber,
    accountsByNumber =>
    {
        const account = accountsByNumber[accountNumber];

        if (account?.businessAddress)
            return formatAddress(account.businessAddress, { skipName: account.businessName });

        return undefined;
    }
);

export const selectBTaxAccountHasMailingAddress = (accountNumber: string) => createSelector(
    selectBTaxAccountsByNumber,
    accountsByNumber =>
    {
        const account = accountsByNumber[accountNumber];

        if (account?.mailingAddress)
        {
            if (formatAddress(account.mailingAddress, { skipName: account.businessName }))
                return true;

            return false;
        }

        return undefined;
    }
);

export const selectBTaxAccountMailingAddress = (accountNumber: string) => createSelector(
    selectBTaxAccountsByNumber,
    accountsByNumber =>
    {
        const account = accountsByNumber[accountNumber];

        if (account?.mailingAddress)
            return formatAddress(account.mailingAddress, { skipName: account.businessName });

        return undefined;
    }
);

export const selectBTaxAccountExemptionStatus = (accountNumber: string) => createSelector(
    selectBTaxAccountsByNumber,
    accountsByNumber =>
    {
        return accountsByNumber[accountNumber]?.exemptionStatus;
    }
);

export const selectBTaxAccountHasTypesOfBusiness = (accountNumber: string) => createSelector(
    selectBTaxAccountsByNumber,
    accountsByNumber =>
    {
        const account = accountsByNumber[accountNumber];

        if (account)
        {
            if (account.typesOfBusiness)
                return !!account.typesOfBusiness.length;
        }

        return undefined;
    }
);

export const selectBTaxAccountTypesOfBusiness = (accountNumber: string) => createSelector(
    selectBTaxAccountsByNumber,
    accountsByNumber =>
    {
        const account = accountsByNumber[accountNumber];

        if (account)
        {
            if (account.typesOfBusiness)
                return account.typesOfBusiness;
        }

        return undefined;
    }
);

export const selectBTaxAccountHistory = (accountNumber: string) => createSelector(
    selectBTaxAccountsByNumber,
    accountsByNumber => accountsByNumber[accountNumber]?.bills
);


export const selectBTaxAccountAddToOrRemoveFromCartTitle = (accountNumber: string) => createSelector(
    selectEntireState,
    selectBTaxAccountsByNumber,
    (s, accountsByNumber) =>
    {
        const account = accountsByNumber[accountNumber];

        const total = account?.due?.total; // Whether there is something to pay
        if (total === undefined) return '';

        const blocked = account?.paymentBlocked;
        if (blocked === undefined) return '';

        if (total)
            if (!blocked)
            {
                const inTheCart = selectBTaxAccountInCart(accountNumber)(s);

                if (!inTheCart)
                    return 'Add to cart';
                else
                    return 'Remove from cart';
            }
            else
                return ''; // Show UI tooltip instead
        else
            return 'Nothing to pay';
    }
);

export const selectBTaxAccountLink = (accountNumber: string) => createSelector(
    selectBTaxAccountsByNumber,
    accountsByNumber =>
    {
        const account = accountsByNumber[accountNumber];
        if (account)
            return `/business-tax/account/${account.accountNumber}`;
    }
);


export const selectBTaxAccountBillsForCartOperations = (accountNumber: string) => createSelector(
    selectBTaxAccountsByNumber, accountsByNumber =>
    {
        const account = accountsByNumber[accountNumber];

        if (account)
        {
            if (account.paymentBlocked)
                return [] as string[];
            else if (account.billsWithABalance)
                return account.billsWithABalance;
        }
    }
);

export const selectBTaxAccountInCart = (accountNumber: string) => createSelector(
    selectEntireState,
    s =>
    {
        const bills = selectBTaxAccountBillsForCartOperations(accountNumber)(s);

        if (bills)
        {
            if (bills.length)
            {
                if (bills.find(id => !selectItemInCart(BTAX_BILL_CART_ITEM_TYPE, id)(s)))
                    return false;

                return true;
            }

            return false;
        }
    }
);


export const selectBTaxAccountIsPayable = (accountNumber: string) => createSelector(
    selectBTaxAccountsByNumber, accounts =>
    {
        const account = accounts[accountNumber];

        if (account?.paymentBlocked !== undefined)
            return !account.paymentBlocked && !!account.billsWithABalance.length;
    }
);



export const selectBTaxAccountIsInCart = (accountNumber: string) => createSelector(
    selectEntireState,
    s =>
    {
        const paymentEnabledBills = selectBTaxAccountBillsForCartOperations(accountNumber)(s);

        return paymentEnabledBills === undefined
            ? undefined
            : paymentEnabledBills?.length
                ? paymentEnabledBills.map(billId => selectItemInCart(BTAX_BILL_CART_ITEM_TYPE, billId)(s)).reduce(
                    (result, billInCart) => !!billInCart && result,
                    true
                )
                : false;
    }
);
