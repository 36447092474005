import { Injectable } from '@angular/core';
import { ofType } from '@ces/sourced-action';
import { Actions, createEffect } from '@ngrx/effects';
import { billsForPPTAccountLoaded } from '@ppt/state/ppt.actions';
import { watchListLoaded } from '@watch-lists/state/feature/entities/watch-list/watch-list.actions';
import { EMPTY, of } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { addPPTParcelData } from './ppt-parcel.actions';

@Injectable()
export class PPTParcelEntityEffects
{
    constructor
    (
        // Dependencies

        private readonly actions$: Actions,
    )
    {}

    private readonly billsForAccountLoaded$ = createEffect(() => this.actions$.pipe(
        ofType(billsForPPTAccountLoaded),
        mergeMap(({ response }) => response
            ? of(addPPTParcelData(`Effect of ${billsForPPTAccountLoaded.type}`, { parcelsData: response.parcels }))
            : EMPTY
        ),
    ));


    // Watch List details loaded

    private readonly watchListDetailsLoaded$ = createEffect(() => this.actions$.pipe(
        ofType(watchListLoaded),
        filter(a => !!a.response.ppt?.parcels?.length),
        map(({ response }) => addPPTParcelData(
            `Effect of ${watchListLoaded.type}`,
            { parcelsData: response.ppt!.parcels }
        )),
    ));
}
