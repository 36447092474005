import { createSelector } from '@ngrx/store';
import { State } from '@state/model';


const selectEntity = (state: State) => state.common.cart.items;

export const selectCartItemIds = createSelector(selectEntity, e => e.ids);
export const selectCartItems = createSelector(selectEntity, e => e.ids.map(id => e.entities[id]!));
export const selectCartItemsById = createSelector(selectEntity, e => e.entities);
export const selectCartItem = (id: string) => createSelector(
    selectCartItemsById,
    itemsById => itemsById[id]
);
export const selectCartItemPartialAmount = (id: string) => createSelector(
    selectCartItemsById,
    itemsById => itemsById[id]?.partialAmount
);

export const selectItemInCart = (moduleId: string, itemId: string) => createSelector(
    selectCartItemsById,
    itemsById => !!itemsById[`${moduleId}-${itemId}`],
);