import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { ofType } from '@ces/sourced-action';
import { Actions, createEffect } from '@ngrx/effects';
import { PPTNetworkService } from 'egov-api';
import { concat, of, throwError } from 'rxjs';
import { catchError, delay, map, mergeMap, retryWhen, take, tap } from 'rxjs/operators';
import { loadPPTState, pptStateLoaded, pptStateLoadFailed } from './ppt-state.actions';

@Injectable()
export class PPTStateEffects
{
    constructor
    (
        // Dependencies

        private readonly actions$: Actions,
        private readonly networkService: PPTNetworkService,
        private readonly snackBar: MatSnackBar,
    )
    {}

    // private readonly effectsInit$ = createEffect(() => this.actions$.pipe(
    //     ofType('@ngrx/effects/init'),
    //     mapTo(loadPPTState('Effect of @ngrx/effects/init')),
    // ));

    private readonly loadAppState$ = createEffect(() => this.actions$.pipe(
        ofType(loadPPTState),
        mergeMap(() => this.networkService.loadState$().pipe(
            retryWhen(errors => concat(
                errors.pipe(delay(5000), take(12)),
                throwError('Could not load state')
            )),
            map(newState => pptStateLoaded(`Effect of ${loadPPTState.type}`, { newState })),
            catchError(() => of(pptStateLoadFailed(`Effect of ${loadPPTState.type}`)))
        ))
    ));

    private readonly stateLoadFailed$ = createEffect(() => this.actions$.pipe(
        ofType(pptStateLoadFailed),
        tap(() =>
        {
            const snackBarRef: MatSnackBarRef<SimpleSnackBar> = this.snackBar.open(
                'Could not load Property Tax service.',
                'Retry',
                { duration: 100 * 365 * 24 * 60 * 60 * 1000 /* A hundred years (Virtually forever) */ }
            );

            snackBarRef.onAction().subscribe(() => loadPPTState('Snackbar retry'));
        })
    ), { dispatch: false });
}
