import { Injectable } from '@angular/core';
import { selectGrandTotalWithoutFees } from '@cart/state/common/cart-common.selectors';
import { os } from '@ces/observable-state';
import { sync } from '@ces/sync';
import { Store } from '@ngrx/store';
import { Scroll } from '@shared/services/scroll.service';
import { Viewport } from '@shared/services/viewport.service';
import { State } from '@state/model';
import { BehaviorSubject } from 'rxjs';
import { pairwise, startWith } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class HeaderService
{
    constructor
    (
        // Dependencies

        private readonly scroll: Scroll,
        private readonly store: Store<State>,
        private readonly viewport: Viewport,
    )
    {
        this.init();
    }


    // Initialization

    private init()
    {
        this.initializeScrollHandling();
    }


    // Handle scroll

    private initializeScrollHandling()
    {
        this.scroll.scroll$.subscribe(position => this.updateScroll());
    }


    // Thresholds

    public static readonly CLOSE_TO_TOP_THRESHOLD = 2;
    public static readonly SCROLL_DIRECTION_CHANGE_THRESHOLD = 5;
    public static readonly SCROLL_SHOW_THRESHOLD = 20;


    // Reasons to show

    public readonly reasonsToShow$ = new BehaviorSubject(new Set<string>(['scroll'])); // Emits outside Angular

    public addReasonToShow(reason: string)
    {
        this.reasonsToShow$.next(new Set([...Array.from(this.reasonsToShow$.value), reason]));
    }

    public removeReasonToShow(reason: string)
    {
        const reasons = new Set(this.reasonsToShow$.value);
        reasons.delete(reason);
        this.reasonsToShow$.next(reasons);
    }


    // Close to top

    public readonly closeToTop$ = new BehaviorSubject(true); // Emits outside Angular

    public updateCloseToTop(newScroll: number) // Public because Transitions service uses it
    {
        const newCloseToTop = newScroll < HeaderService.CLOSE_TO_TOP_THRESHOLD;

        if (this.closeToTop$.value !== newCloseToTop)
            this.closeToTop$.next(newCloseToTop);
    }


    // Update closeToTop and reasonsToShow based on scroll updates

    private lastScroll = 0;
    private scrollDirection?: number;
    private scrollDirectionChangeStart?: number;

    public readonly updateScroll = (newScroll: number = this.scroll.get()[1]) => // Public because Transitions service uses it
    {
        this.updateCloseToTop(newScroll);

        const difference = newScroll - this.lastScroll;
        const newScrollDirection = Math.round(difference / Math.abs(difference));

        if (newScrollDirection !== this.scrollDirection)
        {
            this.scrollDirection = newScrollDirection;
            this.scrollDirectionChangeStart = this.lastScroll;
        }

        if
        (
            Math.abs(this.scrollDirectionChangeStart! - newScroll) >= HeaderService.SCROLL_DIRECTION_CHANGE_THRESHOLD
            ||
            newScroll < HeaderService.SCROLL_SHOW_THRESHOLD
        )
        {
            if (this.scrollDirection === 1 && newScroll > HeaderService.SCROLL_SHOW_THRESHOLD)
            {
                if (this.reasonsToShow$.value.has('scroll'))
                {
                    this.removeReasonToShow('scroll');
                }
            }
            else if (this.scrollDirection === -1)
            {
                if (!this.reasonsToShow$.value.has('scroll'))
                {
                    this.addReasonToShow('scroll');
                }
            }
        }

        this.lastScroll = newScroll;
    };

    public forceShow(targetPageScrollTop: number)
    {
        this.updateScroll(targetPageScrollTop +
            HeaderService.SCROLL_DIRECTION_CHANGE_THRESHOLD + 1);
        this.updateScroll(targetPageScrollTop);
    }


    // Cart

    public readonly drawAttentionToCart$ = new BehaviorSubject(false);
    public readonly frozenCartTotal$ = new BehaviorSubject<number | undefined>(undefined);

    private callAttentionToCartButtonTimeout1?: number;
    private callAttentionToCartButtonTimeout2?: number;

    public callAttentionToCartButton(previousTotal?: number)
    {
        this.frozenCartTotal$.next(previousTotal);

        const reason = `cart-updated-${Math.random()}`;
        this.addReasonToShow(reason);

        this.callAttentionToCartButtonTimeout1 = window.setTimeout(() =>
        {
            this.frozenCartTotal$.next(undefined);
            this.drawAttentionToCart$.next(true);

            this.callAttentionToCartButtonTimeout2 = window.setTimeout(() =>
            {
                this.drawAttentionToCart$.next(false);
                this.removeReasonToShow(reason);
            }
            , 800);
        }
        , 300);
    }

    private readonly lastTwoTotals$ = os(this.store.select(selectGrandTotalWithoutFees).pipe(
        startWith(sync(this.store.select(selectGrandTotalWithoutFees))),
        pairwise(),
    ));

    public raiseAttentionToTotalIfChanged()
    {
        const [previousTotal, currentTotal] = sync(this.lastTwoTotals$)!;
        if (previousTotal !== currentTotal) this.raiseAttentionTotal(previousTotal);
    }

    private raiseAttentionTotal(previousTotal: number | undefined)
    {
        if (!this.viewport.phone$.value)
            this.callAttentionToCartButton(previousTotal);
    }
}
