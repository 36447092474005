import { RootStoreConfig } from '@ngrx/store';
import { State } from './model';

export const storeConfiguration: RootStoreConfig<State> = {
    runtimeChecks:
    {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
    }
};
