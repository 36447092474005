import { createSourcedAction } from '@ces/sourced-action';
import { props } from '@ngrx/store';
import { EgovAPIError, PPTBillsResponse } from 'egov-api';


export const loadBillsForPPTAccount = createSourcedAction(
    'Load bills for PPT account',
    props<{ operationId?: string, accountNumber: string, taxYear?: number }>()
);

export const billsForPPTAccountLoaded = createSourcedAction(
    'Bills for PPT account loaded',
    props<{ operationId?: string, response: PPTBillsResponse, latestYear: number }>()
);

export const billsForPPTAccountLoadFailed = createSourcedAction(
    'Bills for PPT account load failed',
    props<{ operationId?: string, error: EgovAPIError }>()
);
