import { createSourcedAction } from '@ces/sourced-action';
import { EgovAPIError, SingleWatchListResponse, WatchListData, WatchListItem } from 'egov-api';
import { Action, props } from '@ngrx/store';


// Actions

export const loadWatchLists = createSourcedAction('Load watch lists', props<{ notifyErrors?: boolean }>());
export const watchListsLoaded = createSourcedAction('Watch lists loaded', props<{ lists: WatchListData[] | null }>());
export const watchListsLoadFailed = createSourcedAction('Watch lists load failed', props<{ notify?: boolean }>());

export const loadWatchList = createSourcedAction('Load watch list', props<{ slug: string, notifyErrors?: boolean }>());
export const watchListLoaded = createSourcedAction('Watch list loaded', props<{ slug: string, list: WatchListData, response: SingleWatchListResponse }>());
export const watchListLoadFailed = createSourcedAction('Watch list load failed', props<{ slug: string, error: EgovAPIError, notify?: boolean }>());

export const addWatchList = createSourcedAction(
    'Add watch list',
    props<{ name: string, items?: WatchListItem[], notifyErrors?: boolean }>(),
);
export const watchListAdded = createSourcedAction('Watch list added', props<{ list: WatchListData }>());
export const watchListAddFailed = createSourcedAction(
    'Watch list add failed',
    props<{ name: string, action: Action, error: EgovAPIError, notify?: boolean }>()
);

export const upsertWatchList = createSourcedAction('Upsert watch list', props<{ list: WatchListData }>());


export const deleteWatchList = createSourcedAction(
    'Delete watch list',
    props<{ slug: string, notifyErrors?: boolean }>(),
);

export const watchListDeleted = createSourcedAction(
    'Watch list deleted',
    props<{ slug: string }>()
);

export const watchListDeleteFailed = createSourcedAction(
    'Watch list delete failed',
    props<{ slug: string, error: EgovAPIError, notify?: boolean }>()
);


export const flushWatchLists = createSourcedAction('Flush watch lists');


export const addItemsToList = createSourcedAction(
    'Add items to list',
    props<{ slug: string, items: WatchListItem[], optimistic?: boolean, notifyErrors?: boolean }>(),
);

export const itemsAddedToList = createSourcedAction(
    'Items added to list',
    props<{ slug: string, items: WatchListItem[], optimistic: boolean }>()
);
export const addingItemsToListFailed = createSourcedAction(
    'Adding items to list failed',
    props<{ action: Action, error: EgovAPIError, notify?: boolean }>(),
);


export const removeItemsFromList = createSourcedAction(
    'Remove items from list',
    props<{ slug: string, items: WatchListItem[], optimistic?: boolean, notifyErrors?: boolean }>(),
);

export const itemsRemovedFromList = createSourcedAction(
    'Items removed from list',
    props<{ slug: string, items: WatchListItem[], optimistic: boolean }>()
);

export const removingItemsFromListFailed = createSourcedAction(
    'Removing items from list failed',
    props<{ action: Action, slug: string, items: WatchListItem[], error: EgovAPIError, notify?: boolean }>(),
);


// Actions - Cart

export const addWatchListToCart = createSourcedAction(
    'Add watch list to cart',
    props<{ slug: string, notifyErrors?: boolean, includeTypes?: string[], excludeTypes?: string[] }>()
);

export const watchListAddedToCart = createSourcedAction(
    'Watch list added to cart',
    props<{ slug: string }>()
);

export const watchListAddToCartFailed = createSourcedAction(
    'Watch list add to cart failed',
    props<{ slug: string, error: EgovAPIError, notifyErrors?: boolean }>()
);


export const removeWatchListFromCart = createSourcedAction(
    'Remove watch list from cart',
    props<{ slug: string, notifyErrors?: boolean, includeTypes?: string[], excludeTypes?: string[] }>()
);

export const watchListRemovedFromCart = createSourcedAction(
    'Watch list removed from cart',
    props<{ slug: string }>()
);

export const watchListRemoveFromCartFailed = createSourcedAction(
    'Watch list remove from cart failed',
    props<{ slug: string, error: EgovAPIError, notifyErrors?: boolean }>()
);
