import { State } from '@/app/state/model';
import { Inject, Injectable } from '@angular/core';
import { sync } from '@ces/sync';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CartPlugin, CART_PLUGIN } from '../plugins/cart-plugin.model';
import { CartItem } from '../state/common/entities/cart-item/cart-item.model';
import { selectCartItem, selectCartItems } from '../state/common/entities/cart-item/cart-item.selectors';


@Injectable({ providedIn: 'root' })
export class CartPluginsService
{
    constructor
    (
        // Dependencies

        @Inject(CART_PLUGIN) public readonly plugins: CartPlugin[],
        private readonly store: Store<State>,
    )
    {}

    public readonly byType = this.plugins.reduce<{ [type: string]: CartPlugin }>(
        (reduced, item) => ({ ...reduced, [item.type]: item }),
        {}
    );


    // Partial payments

    private readonly partialPaymentAllowedLogicById = this.plugins
        .filter(plugin => plugin.partialPaymentAllowed$)
        .reduce<{ [id: string]: Exclude<CartPlugin['partialPaymentAllowed$'], undefined> }>(
            (result, plugin) => ({ ...result, [plugin.type]: plugin.partialPaymentAllowed$! }),
            {}
        );

    public getPartialPaymentAllowedForItemById$(
        itemId: string
    ): Observable<boolean | undefined>
    {
        return this.store.select(selectCartItem(itemId)).pipe(
            switchMap(item => this.getPartialPaymentAllowedForItem$(item)),
        );
    }

    public getPartialPaymentAllowedForItem$(item?: CartItem): Observable<boolean | undefined>
    {
        if (item && this.partialPaymentAllowedLogicById[item.type])
        {
            const cartItems = sync(this.store.select(selectCartItems))!;

            const cartItemIds = cartItems
                ?.filter(i => i.type === item.type)
                .map(i => i.id.substr(item.type.length + 1));

            return this.partialPaymentAllowedLogicById[item.type](item, cartItemIds);
        }
        else
            return of(undefined);
    }
}
