import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-progress-bar',
    template: `
        <div class="bar">
            <div class="fill"></div>
            <div class="fill"></div>
        </div>
    `,
    styleUrls: ['./progress-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent {}
