import { isString } from '@egovsolutions/type-checking';
import { createSelector } from '@ngrx/store';
import { selectWatchListsFeatureState } from '@watch-lists/state/feature/watch-lists-feature.selectors';
import { WatchList } from 'egov-api';


export const selectWatchListEntity = createSelector(
    selectWatchListsFeatureState,
    s => s?.entities.watchLists
);

export const selectWatchListsBySlug = createSelector(
    selectWatchListEntity,
    s => s?.entities,
);

export const selectWatchLists = createSelector(
    selectWatchListEntity,
    s => s?.ids.map(id => s.entities[id]) as WatchList[],
);

export type WatchListsSortCriteria = 'name' | 'lastAccessed' | 'created' | 'length';

export const selectSortedWatchLists = (criterion: WatchListsSortCriteria = 'name', direction: 'asc' | 'desc' = 'asc') => createSelector(
    selectWatchLists,
    watchLists =>
    {
        if (!watchLists.length) return [];

        const lists = watchLists.filter(list => !list.runtimeState.deleting);

        let sortedLists: WatchList[] | undefined;

        if (criterion !== 'length')
            sortedLists = lists.sort((_a: WatchList, _b: WatchList) =>
            {
                let a: string | number = (_a as any)[criterion];
                let b: string | number = (_b as any)[criterion];

                if (isString(a))
                {
                    a = a.toUpperCase();
                    b = (b as string).toUpperCase();

                    return a < b ? -1 : a > b ? 1 : 0;
                }
                else
                    return a - (b as number);

            });
        else
            sortedLists = lists.sort((a: WatchList, b: WatchList) => (a.items?.length || 0) - (b.items?.length || 0));

        if (criterion === 'lastAccessed' || criterion === 'created')
            sortedLists = sortedLists.reverse();

        if (direction === 'desc')
            sortedLists = sortedLists.reverse();

        return sortedLists;
    },
);

export const selectWatchList = (slug: string) => createSelector(selectWatchListEntity,
    s => s?.entities[slug],
);

export const selectWatchListItems = (slug: string | undefined) => createSelector(selectWatchListsBySlug,
    watchListsBySlug => slug !== undefined ? watchListsBySlug[slug]?.items : undefined
);

export const selectWatchListsLoading = createSelector(selectWatchListEntity, s => s?.loading);
export const selectWatchListsLoaded = createSelector(selectWatchListEntity, s => s?.loaded);
export const selectWatchListsLoadFailed = createSelector(selectWatchListEntity, s => s?.loadFailed);
