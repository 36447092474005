import { combineReducers } from '@ngrx/store';
import { BTaxAccountEntities } from './account/btax-account.model';
import { btaxAccountsReducer } from './account/btax-account.reducer';
import { BTaxAccountEntityEffects } from './account/btax-account.effects';
import { BTaxBillEntities } from './bill/btax-bill.model';
import { btaxBillsReducer } from './bill/btax-bill.reducer';
import { BTaxBillEntityEffects } from './bill/btax-bill.effects';

export interface BTaxCommonEntities
{
    accounts: BTaxAccountEntities
    bills: BTaxBillEntities
}

export const btaxEntitiesReducer = combineReducers<BTaxCommonEntities>({
    accounts: btaxAccountsReducer,
    bills: btaxBillsReducer,
});

export const btaxEntityEffects = [
    BTaxAccountEntityEffects,
    BTaxBillEntityEffects,
];
