import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, merge } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({ providedIn: 'any' })
export class ImageLoader
{
    public load(imageURL: string, orError: boolean = false): BehaviorSubject<boolean>
    {
        const behaviorSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

        const loaderImg: HTMLImageElement = new Image();

        merge(fromEvent(loaderImg, 'load'), fromEvent(loaderImg, 'error')).pipe(first()).subscribe(e =>
        {
            behaviorSubject.next(e.type !== 'error' || orError ? true : false);
        });

        loaderImg.src = imageURL;

        return behaviorSubject;
    }
}
