import { Inject, Injectable } from '@angular/core';
import { WatchListItemTypeDefinition, WATCH_LIST_ITEM_COMPONENT_DEFINITION } from '../items/watch-list-item-definition.model';


@Injectable()
export class WatchListItemTypeDefinitions
{
    constructor
    (
        // Dependencies

        @Inject(WATCH_LIST_ITEM_COMPONENT_DEFINITION)
        public readonly watchListItemTypeDefinitions: WatchListItemTypeDefinition[],
    )
    {}

    public readonly definitionsByType = this.watchListItemTypeDefinitions.reduce<{ [type: string]: WatchListItemTypeDefinition }>(
        (reduced, item) => ({ ...reduced, [item.type]: item }),
        {}
    );
}
