import { Injectable } from '@angular/core';
import { ofType } from '@ces/sourced-action';
import { CartNetworkService, CartOperationItem, createEgovAPIError } from 'egov-api';
import { isString } from '@egovsolutions/type-checking';
import { Actions, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { newSession, signedOut } from '@session/state/session.actions';
import { selectSessionActive } from '@session/state/session.selectors';
import { State } from '@state/model';
import { concat, from, merge, Observable, of } from 'rxjs';
import { catchError, concatMap, distinctUntilChanged, filter, first, last, map, mapTo, mergeMap, reduce, scan, switchMap, takeWhile, tap } from 'rxjs/operators';
import { CartPluginsService } from '../../services/cart-plugins.service';
import { actuallyLoadCart, addCartUpdater, addItemsToCart, addItemsToCartComplete, addItemsToCartFailed, addItemsToCartFullyIntercepted, addItemsToCartIntent, cartContentsLoaded, cartEmptied, cartLoadFailed, clearCart, emptyCart, emptyCartFailed, flushCartState, invalidateTotal, loadCart, removeCartUpdater, removeItemsFromCart, removeItemsFromCartComplete, removeItemsFromCartFailed, removeItemsFromCartIntent, setCartLoadFailed } from './cart-common.actions';
import { selectCartItems } from './entities/cart-item/cart-item.selectors';

@Injectable()
export class CartEffects
{
    constructor
    (
        // Dependencies

        private readonly actions$: Actions,
        private readonly store: Store<State>,
        private readonly networkService: CartNetworkService,
        private readonly cartPlugins: CartPluginsService,
    )
    {}


    // Load

    private readonly loadCartOnSession$ = createEffect(() => this.actions$.pipe(
        ofType(newSession),
        distinctUntilChanged(),
        map(() => loadCart(`Effect of ${newSession.type}`)),
    ));

    private readonly loadCart$ = createEffect(() => this.actions$.pipe( // Intent
        ofType(loadCart),
        mergeMap(() => this.store.select(selectSessionActive).pipe(
            first(),
            filter(hasSession => hasSession),
            mapTo(actuallyLoadCart(`Effect of ${loadCart.type}`))
        ))
    ));

    private readonly actuallyLoadCart$ = createEffect(() => this.actions$.pipe( // Actually load cart
        ofType(actuallyLoadCart),
        mergeMap(_ => concat(
            of(addCartUpdater(`Effect of ${loadCart.type}`)),
            this.networkService.cart$().pipe(
                switchMap(cartResponse => of(
                    removeCartUpdater(`Effect of ${actuallyLoadCart.type}`),
                    cartContentsLoaded(`Effect of ${actuallyLoadCart.type}`, { cartResponse }),
                )),
                catchError(error => of(
                    cartLoadFailed(`Effect of ${actuallyLoadCart.type}`),
                    removeCartUpdater(`Effect of ${actuallyLoadCart.type}`),
                )),
            )
        ))
    ));


    // Update cart load failed

    private readonly clearCartFailedOnActuallyLoad$ = createEffect(() => this.actions$.pipe(
        ofType(actuallyLoadCart),
        mapTo(setCartLoadFailed(`Effect of ${actuallyLoadCart.type}`, { failed: false }))
    ));

    private readonly setCartFailedOnLoadError$ = createEffect(() => this.actions$.pipe(
        ofType(cartLoadFailed),
        mapTo(setCartLoadFailed(`Effect of ${cartLoadFailed.type}`, { failed: true }))
    ));


    // Operation helpers

    private intercept$(items: CartOperationItem[]): Observable<false | CartOperationItem[] | true | string>
    {
        // Separate items by type, so we can run interceptors.

        const itemsByType = items.reduce<{ [type: string]: string[] }>(
            (out, item) => ({
                ...out,
                [item.type]: (out[item.type] || []).concat([item.id])
            }),
            {}
        );


        // Get the intercepts and make them return CartOperationItems rather than only ids.
        //
        // Part of this code is ran inside an anonymous function to work around typing difficulties.

        const intercepts = Object.keys(itemsByType).map(
            type => (() =>
            {
                const interceptor = this.cartPlugins.byType[type]?.intercept;
                if (!interceptor) return of(false);
                return interceptor(itemsByType[type]);
            })()
            .pipe(
                map(intercept => Array.isArray(intercept)
                    ? intercept.map(id => ({ type, id }))
                    : intercept
                )
            )
        );

        return concat(...intercepts).pipe(
            takeWhile(intercept => intercept !== true && !isString(intercept), true),
            reduce(
                (acc, intercept) =>intercept === true || isString(intercept)
                    ? intercept
                    : intercept === false
                        ? acc
                        : (Array.isArray(acc) ? acc : []).concat(intercept),
                false as boolean | string | CartOperationItem[]
            ),
        );
    }

    private cartItemIds$(moduleId: string)
    {
        return this.store.select(selectCartItems).pipe(
            first(),
            map(cartItems => cartItems?.filter(item => item.type === moduleId)
                .map(item => item.id.substr(moduleId.length + 1))
            )
        );
    }

    private addDependencies$(items: CartOperationItem[]): Observable<undefined | CartOperationItem[] | false>
    {
        // Separate items by type, so we can run interceptors.

        const itemsByType = items.reduce<{ [type: string]: string[] }>(
            (out, item) => ({
                ...out,
                [item.type]: (out[item.type] || []).concat([item.id])
            }),
            {}
        );


        // Get the deps functions and make them return CartOperationItems rather than only ids.

        const dependenciesPerType = Object.keys(itemsByType).map(type => this.addDependenciesPerModule$(type, itemsByType[type]).pipe(
            map(intercept => Array.isArray(intercept)
                ? intercept.map(id => ({ type, id }))
                : intercept
            )
        ));

        return concat(...dependenciesPerType).pipe(
            takeWhile(deps => deps !== undefined && deps !== false, true),
            reduce(
                (acc, deps) => deps === undefined || deps === false
                    ? deps
                    : (acc as CartOperationItem[]).concat(deps),
                [] as undefined | CartOperationItem[] | false
            ),
        );

    }

    private addDependenciesPerModule$(moduleId: string, itemIds: string[]): Observable<undefined | string[] | false>
    {
        return this.cartItemIds$(moduleId).pipe(
            switchMap(cartItemIds =>
            {
                // Get a hold of the dependency logic

                const logic = this.cartPlugins.byType[moduleId]?.dependencyLogic;


                // Get ids for all the items that would be added

                let allIds: string[];

                if (!logic) allIds = itemIds;
                else
                {
                    const dependencies = itemIds
                        .map(id => logic.getMissingDependencies(id, itemIds, cartItemIds))
                        .filter(v => v)
                        .reduce<string[]>((accumulated, ids) => accumulated.concat(ids!), []);

                    allIds = Array.from(new Set(itemIds.concat(dependencies)));
                }


                // If there is indeed a logic, apply it

                if (logic)
                {
                    // If dependencies cannot be determined for an item, fail the entire operation.

                    for (const itemId of itemIds)
                    {
                        const missingDependencies = logic.getMissingDependencies(itemId, itemIds, cartItemIds);

                        if (missingDependencies === undefined)
                            return of(undefined);
                    }


                    // Compute dependencies and get inclusion approval

                    const itemsWithMissingDependencies =
                        itemIds.filter(id => logic.getMissingDependencies(id, itemIds, cartItemIds)?.length);

                    const alreadyAllowedIds = new Set<string>();

                    if (itemsWithMissingDependencies?.length) return from(itemsWithMissingDependencies).pipe(
                        concatMap(id =>
                            logic.confirmDependenciesInclussion$(
                                id,
                                itemIds.concat(Array.from(alreadyAllowedIds)),
                                cartItemIds
                            ).pipe(
                                map(dialogResponse => ({ dialogResponse, id }))
                            )
                        ),
                        takeWhile(({ dialogResponse }) => dialogResponse, true),
                        tap(({ dialogResponse, id }) =>
                            dialogResponse && logic.getMissingDependencies(
                                id,
                                itemIds.concat(Array.from(alreadyAllowedIds)),
                                cartItemIds
                            )
                            ?.forEach(id => alreadyAllowedIds.add(id))
                        ),
                        map(({ dialogResponse }) => dialogResponse),
                        scan(
                            (result, dialogResponse) => dialogResponse ? result : false,
                            true
                        ),
                        last(),
                        concatMap(doIt => of(doIt
                            ? allIds
                            : false as const
                        )),
                    );
                }


                // Otherwise add all items

                return of(allIds);
            })
        );
    }

    private addDependents$(items: CartOperationItem[]): Observable<undefined | CartOperationItem[] | false>
    {
        // Separate items by type, so we can run interceptors.

        const itemsByType = items.reduce<{ [type: string]: string[] }>(
            (out, item) => ({
                ...out,
                [item.type]: (out[item.type] || []).concat([item.id])
            }),
            {}
        );


        // Get the deps functions and make them return CartOperationItems rather than only ids.

        const dependentsPerType = Object.keys(itemsByType).map(type => this.addDependentsPerModule$(type, itemsByType[type]).pipe(
            map(intercept => Array.isArray(intercept)
                ? intercept.map(id => ({ type, id }))
                : intercept
            )
        ));

        return concat(...dependentsPerType).pipe(
            takeWhile(deps => deps !== undefined && deps !== false, true),
            reduce(
                (acc, deps) => deps === undefined || deps === false
                    ? deps
                    : (acc as CartOperationItem[]).concat(deps),
                [] as undefined | CartOperationItem[] | false
            ),
        );

    }

    private addDependentsPerModule$(moduleId: string, itemIds: string[]): Observable<undefined | string[] | false>
    {
        return this.cartItemIds$(moduleId).pipe(
            switchMap(cartItemIds =>
            {
                // Get a hold of the dependency logic

                const logic = this.cartPlugins.byType[moduleId]?.dependencyLogic;



                // Get ids for all the items that would be removed

                let allIds: string[];

                if (!logic)
                    allIds = itemIds;
                else
                {
                    const dependencies = itemIds
                        .map(id => logic.getAddedDependents(id, itemIds, cartItemIds))
                        .filter(v => v)
                        .reduce<string[]>((accumulated, ids) => accumulated.concat(ids!), []);

                    allIds = Array.from(new Set(itemIds.concat(dependencies )));
                }

                // If there is indeed a logic, apply it

                if (logic)
                {
                    // If dependencies cannot be determined for an item, fail the entire operation.

                    for (const itemId of itemIds)
                    {
                        const dependents = logic.getAddedDependents(itemId, itemIds, cartItemIds);

                        if (dependents === undefined)
                            return of(undefined);
                    }

                    const itemsWithAddedDependents = itemIds
                        .filter(id => logic.getAddedDependents(id, itemIds, cartItemIds)?.length);


                    const alreadyAllowedIds = new Set<string>();

                    if (itemsWithAddedDependents?.length) return from(itemsWithAddedDependents).pipe(
                        concatMap(id =>
                            logic.confirmDependentsRemoval$(
                                id,
                                itemIds.concat(Array.from(alreadyAllowedIds)),
                                cartItemIds
                            ).pipe(
                                map(dialogResponse => ({ dialogResponse, id }))
                            )
                        ),
                        takeWhile(({ dialogResponse }) => dialogResponse, true),
                        tap(({ dialogResponse, id }) =>
                            dialogResponse && logic.getAddedDependents(
                                id,
                                itemIds.concat(Array.from(alreadyAllowedIds)),
                                cartItemIds
                            )
                            ?.forEach(id => alreadyAllowedIds.add(id))
                        ),
                        map(({ dialogResponse }) => dialogResponse),
                        scan(
                            (result, dialogResponse) => dialogResponse ? result : false,
                            true
                        ),
                        last(),
                        concatMap(doIt => of(doIt
                            ? allIds
                            : false as const
                        )),
                    );
                }


                // Otherwise remove all items

                return of(allIds);
            })
        );
    }


    // Operations

    private readonly addItemsToCartIntent$ = createEffect(() => this.actions$.pipe(
        ofType(addItemsToCartIntent),
        mergeMap(action =>
        {
            return this.intercept$(action.items).pipe(
                switchMap(intercepted => intercepted === true || isString(intercepted)
                    ? of(addItemsToCartFullyIntercepted(
                        `Effect of ${addItemsToCartIntent.type}`,
                        { id: action.id, intent: action }
                    ))
                    : this.addDependencies$(Array.isArray(intercepted) ? intercepted : action.items).pipe(
                        map(dependencies =>
                        {

                            // If dependencies cannot be determined, emit failure

                            if (dependencies === undefined) return addItemsToCartFailed(`Effect of ${addItemsToCartIntent.type}`, {
                                id: action.id,
                                error: JSON.parse(JSON.stringify(createEgovAPIError('Dependencies could not be determined for some of the items.'))),
                                worthARetry: true,
                                intent: action,
                            });


                            // If dependencies were not approved, emit failure without notification

                            if (dependencies === false) return addItemsToCartFailed(`Effect of ${addItemsToCartIntent.type}`, {
                                id: action.id,
                                error: JSON.parse(JSON.stringify(createEgovAPIError('Items cannot be added to the cart due to unmet dependencies.'))),
                                worthARetry: false,
                                intent: action,
                            });


                            // If dependencies approved, add to cart

                            return addItemsToCart(`Effect of ${ addItemsToCartIntent.type }`, {
                                id: action.id,
                                intent: action,
                                items: dependencies,
                            });
                        })
                    ),
                )
            );
        }),
    ));

    private readonly addItemsToCart$ = createEffect(() => this.actions$.pipe(
        ofType(addItemsToCart),
        mergeMap(action =>
            this.networkService.addToCart$(action.items).pipe(
                switchMap(cartResponse => of(
                    cartContentsLoaded(`Effect of ${addItemsToCart.type}`, { cartResponse }),
                    addItemsToCartComplete(`Effect of ${addItemsToCart.type}`, {
                        id: action.id,
                        addAction: action
                    })
                )),
                catchError(error => of(addItemsToCartFailed(`Effect of ${addItemsToCart.type}`, {
                    id: action.id,
                    error: JSON.parse(JSON.stringify(error)),
                    worthARetry: !error || !error.permanent,
                    intent: action.intent,
                    addAction: action
                })))
            )
        ),
    ));

    private readonly removeItemsFromCartIntent$ = createEffect(() => this.actions$.pipe(
        ofType(removeItemsFromCartIntent),
        mergeMap(action => this.addDependents$(action.items).pipe(
            map(dependents =>
            {
                // If dependents cannot be determined, emit failure

                if (dependents === undefined) return removeItemsFromCartFailed(`Effect of ${removeItemsFromCartIntent.type}`, {
                    id: action.id,
                    error: JSON.parse(JSON.stringify(createEgovAPIError('Dependencies could not be determined for some of the items.'))),
                    worthARetry: true,
                    intent: action,
                });


                // If dependents removal was not approved, emit failure without notification

                if (dependents === false) return removeItemsFromCartFailed(`Effect of ${removeItemsFromCartIntent.type}`, {
                    id: action.id,
                    error: JSON.parse(JSON.stringify(createEgovAPIError('Items could not be removed from the cart due to dependencies with other items.'))),
                    worthARetry: false,
                    intent: action
                });


                // If dependencies approved, remove from cart

                return removeItemsFromCart(`Effect of ${ removeItemsFromCartIntent.type }`, {
                    id: action.id,
                    items: dependents,
                    intent: action,
                });
            })
        )),
    ));

    private readonly removeItemsFromCart$ = createEffect(() => this.actions$.pipe(
        ofType(removeItemsFromCart),
        mergeMap(action =>
            this.networkService.removeFromCart$(action.items).pipe(
                switchMap(cartResponse => of(
                    cartContentsLoaded(`Effect of ${removeItemsFromCart.type}`, { cartResponse }),
                    removeItemsFromCartComplete(`Effect of ${removeItemsFromCart.type}`, {
                        id: action.id,
                        removeAction: action,
                    })
                )),
                catchError(error => of(removeItemsFromCartFailed(`Effect of ${removeItemsFromCart.type}`, {
                    id: action.id,
                    error: JSON.parse(JSON.stringify(error)),
                    intent: action.intent,
                    removeAction: action
                })))
            )
        )
    ));

    private readonly emptyCart$ = createEffect(() => this.actions$.pipe(
        ofType(emptyCart),
        mergeMap(action => this.networkService.emptyCart$().pipe(
            switchMap(cartResponse => of(
                cartContentsLoaded(`Effect of ${emptyCart.type}`, { cartResponse }),
                cartEmptied(`Effect of ${emptyCart.type}`, { id: action.operationId })
            )),
            catchError(error => of(emptyCartFailed(`Effect of ${emptyCart.type}`, { id: action.operationId })))
        ))
    ));


    // Cart operation updaters

    private readonly addToCartUpdaters$ = createEffect(() => this.actions$.pipe(
        ofType(addItemsToCartIntent),
        mergeMap(action => concat(
            of(addCartUpdater(`Effect of ${addItemsToCartIntent.type}`)),
            merge(
                this.actions$.pipe(ofType(addItemsToCartComplete), filter(a => a.id === action.id)),
                this.actions$.pipe(ofType(addItemsToCartFullyIntercepted), filter(a => a.id === action.id)),
                this.actions$.pipe(ofType(addItemsToCartFailed), filter(a => a.id === action.id)),
            ).pipe(
                first(),
                map(_ => removeCartUpdater(`Effect of ${addItemsToCartIntent.type}`))
            ),
        )),
    ));

    private readonly removeFromCartUpdaters$ = createEffect(() => this.actions$.pipe(
        ofType(removeItemsFromCartIntent),
        mergeMap(action => concat(
            of(addCartUpdater(`Effect of ${removeItemsFromCartIntent.type}`)),
            merge(
                this.actions$.pipe(ofType(removeItemsFromCartComplete), filter(a => a.id === action.id)),
                this.actions$.pipe(ofType(removeItemsFromCartFailed), filter(a => a.id === action.id)),
            ).pipe(
                first(),
                map(_ => removeCartUpdater(`Effect of ${removeItemsFromCartIntent.type}`))
            ),
        )),
    ));

    private readonly emptyCartUpdaters$ = createEffect(() => this.actions$.pipe(
        ofType(emptyCart),
        mergeMap(action => concat(
            of(addCartUpdater(`Effect of ${emptyCart.type}`)),
            merge(
                this.actions$.pipe(ofType(cartEmptied), filter(a => a.id === action.operationId)),
                this.actions$.pipe(ofType(emptyCartFailed), filter(a => a.id === action.operationId)),
            ).pipe(
                first(),
                map(_ => removeCartUpdater(`Effect of ${emptyCart.type}`))
            ),
        )),
    ));


    // Clear

    private readonly flushCartOnSignedOut$ = createEffect(() => this.actions$.pipe(
        ofType(signedOut),
        map(() => flushCartState(`Effect of ${signedOut.type}`)),
    ));

    private readonly invalidateTotal$ = createEffect(() => this.actions$.pipe(
        ofType(clearCart),
        map(action => invalidateTotal(`Effect of ${action.type}`)),
    ));


    // Entity parsing

    private readonly entityParsing$ = createEffect(() => this.actions$.pipe(
        ofType(cartContentsLoaded),
        tap(a =>
        {
            for (const plugin of this.cartPlugins.plugins)
                if (plugin.parseEntities) plugin.parseEntities(a.cartResponse);
        }),
    ), { dispatch: false });
}
