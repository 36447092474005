import { Injectable } from '@angular/core';
import { cartContentsLoaded } from '@cart/state/common/cart-common.actions';
import { ofType } from '@ces/sourced-action';
import { Actions, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { signedOut } from '@session/state/session.actions';
import { SimpleErrorHandling } from '@shared/services/simple-error-handling.service';
import { State } from '@state/model';
import { WatchListItemTypeDefinitions } from '@watch-lists/services/watch-list-item-definitions.service';
import { CartNetworkService, CartOperationItem, createEgovAPIClientError, createEgovAPINotFoundError, EgovAPIError, WatchListsNetworkService } from 'egov-api';
import { Observable, of } from 'rxjs';
import { catchError, filter, first, map, mapTo, mergeMap, switchMap, tap } from 'rxjs/operators';
import { addingItemsToListFailed, addItemsToList, addWatchList, addWatchListToCart, deleteWatchList, flushWatchLists, itemsAddedToList, itemsRemovedFromList, loadWatchList, loadWatchLists, removeItemsFromList, removeWatchListFromCart, removingItemsFromListFailed, watchListAdded, watchListAddedToCart, watchListAddFailed, watchListAddToCartFailed, watchListDeleted, watchListDeleteFailed, watchListLoaded, watchListLoadFailed, watchListRemovedFromCart, watchListRemoveFromCartFailed, watchListsLoaded, watchListsLoadFailed } from './watch-list.actions';
import { selectWatchListItems } from './watch-list.selectors';


@Injectable()
export class WatchListEntityEffects
{
    constructor
    (
        // Dependencies

        private readonly actions$: Actions,
        private readonly store: Store<State>,
        private readonly cartNetworkService: CartNetworkService,
        private readonly networkService: WatchListsNetworkService,
        private readonly simpleErrorHandling: SimpleErrorHandling,
        private readonly typeDefinitions: WatchListItemTypeDefinitions,
    )
    {}


    // Load lists

    private readonly loadWatchLists$ = createEffect(() => this.actions$.pipe(
        ofType(loadWatchLists),
        mergeMap(({ notifyErrors: notify }) => this.networkService.watchLists$().pipe(
            map(lists => watchListsLoaded(`Effect of ${loadWatchLists.type}`, { lists })),
            catchError(error => of(watchListsLoadFailed(`Effect of ${loadWatchLists.type}`, { notify })))
        ))
    ));

    private readonly notifyWatchListsLoadFailed$ = createEffect(() => this.actions$.pipe(
        ofType(watchListsLoadFailed),
        filter(a => !!a.notify),
        tap(_ => this.simpleErrorHandling.displaySnackbar('Could not load watch lists.', () =>
            this.store.dispatch(loadWatchLists('Snackbar retry', { notifyErrors: true })),
        )),
    ), { dispatch: false });


    // Load list details

    private readonly loadWatchList$ = createEffect(() => this.actions$.pipe(
        ofType(loadWatchList),
        mergeMap(({ slug, notifyErrors: notify }) => this.networkService.getWatchListDetails$(slug).pipe(
            tap(response => { if (!response.watchlist) throw createEgovAPINotFoundError({ message: 'List doesn\'t exist', permanent: true }); }),
            map(response => watchListLoaded(`Effect of ${loadWatchList.type}`, { slug, list: response.watchlist, response })),
            catchError(error => of(watchListLoadFailed(`Effect of ${loadWatchList.type}`, { slug, error, notify })))
        )),
    ));

    private readonly notifyWatchListLoadFailed$ = createEffect(() => this.actions$.pipe(
        ofType(watchListLoadFailed),
        filter(a => !!a.notify),
        tap(({ slug, error }) => this.simpleErrorHandling.displaySnackbar('Could not load watch list.', error.permanent
            ? undefined
            : () => this.store.dispatch(loadWatchList('Snackbar retry', { slug, notifyErrors: true })),
        )),
    ), { dispatch: false });


    // Add

    private readonly addWatchList$ = createEffect(() => this.actions$.pipe(
        ofType(addWatchList),
        mergeMap(action => this.networkService.addWatchList$(action.name, action.items).pipe(
            map(list => watchListAdded(`Effect of ${addWatchList.type}`, { list })),
            catchError(error => of(watchListAddFailed(
                `Effect of ${addWatchList.type}`,
                {
                    name: action.name,
                    action,
                    error,
                    notify: action.notifyErrors,
                }
            ))),
        ))
    ));

    private readonly notifyWatchListAddFailed$ = createEffect(() => this.actions$.pipe(
        ofType(watchListAddFailed),
        filter(a => !!a.notify),
        tap(({ error, action }) => this.simpleErrorHandling.displayBestErrorMessage(error, {
            customMessage: 'Error adding new list.',
            retryHandler: () => this.store.dispatch(action),
        })),
    ), { dispatch: false });


    // Delete

    private readonly deleteWatchList$ = createEffect(() => this.actions$.pipe(
        ofType(deleteWatchList),
        mergeMap(action => this.networkService.deleteWatchList$(action.slug).pipe(
            map(() => watchListDeleted(`Effect of ${deleteWatchList.type}`, { slug: action.slug })),
            catchError(error => of(
                watchListDeleteFailed(`Effect of ${deleteWatchList.type}`, {
                    slug: action.slug,
                    notify: action.notifyErrors,
                    error: JSON.parse(JSON.stringify(error)),
                })
            )),
        )),
    ));

    private readonly notifyWatchListDeleteFailed$ = createEffect(() => this.actions$.pipe(
        ofType(watchListDeleteFailed),
        filter(a => !!a.notify),
        tap(action => this.simpleErrorHandling.displayBestErrorMessage(action.error, {
            customMessage: 'Error deleting list.',
            retryHandler: () => this.store.dispatch(deleteWatchList('Snackbar retry', {
                slug: action.slug,
                notifyErrors: true
            }))
        }))
    ));


    // Add accounts to list

    private readonly addAccountsToList$ = createEffect(() => this.actions$.pipe(
        ofType(addItemsToList),
        mergeMap(action => this.networkService.addAccountsToList$(action.slug, action.items).pipe(
            map(() => itemsAddedToList(
                `Effect of ${addItemsToList.type}`,
                { slug: action.slug, items: action.items, optimistic: !!action.optimistic }
            )),
            catchError(error => of(addingItemsToListFailed(
                `Effect of ${addItemsToList.type}`,
                {
                    action,
                    error: JSON.parse(JSON.stringify(error)),
                    notify: action.notifyErrors,
                }
            ))),
        )),
    ));

    private readonly notifyAddingAccountsToListFailed$ = createEffect(() => this.actions$.pipe(
        ofType(addingItemsToListFailed),
        filter(a => !a.notify),
        tap(({ error, action }) => this.simpleErrorHandling.displayBestErrorMessage(error, {
            customMessage: 'Error adding account to list.',
            retryHandler: () => this.store.dispatch(action),
        })),
    ), { dispatch: false });


    // Remove accounts from list

    private readonly removeAccountsFromList$ = createEffect(() => this.actions$.pipe(
        ofType(removeItemsFromList),
        mergeMap(action => this.networkService.removeAccountsFromList$(action.slug, action.items).pipe(
            map(() => itemsRemovedFromList(
                `Effect of ${removeItemsFromList.type}`,
                { slug: action.slug, items: action.items, optimistic: !!action.optimistic }
            )),
            catchError(error => of(removingItemsFromListFailed(
                `Effect of ${removeItemsFromList.type}`,
                {
                    action,
                    slug: action.slug,
                    items: action.items,
                    error: JSON.parse(JSON.stringify(error)),
                    notify: action.notifyErrors,
                }
            ))),
        )),
    ));

    private readonly notifyRemovingAccountsFromListFailed$ = createEffect(() => this.actions$.pipe(
        ofType(removingItemsFromListFailed),
        filter(a => !a.notify),
        tap(({ error, action }) => this.simpleErrorHandling.displayBestErrorMessage(error, {
            customMessage: 'Error removing account from list.',
            retryHandler: () => this.store.dispatch(action),
        })),
    ), { dispatch: false });


    // Cart

    private getWatchListItemsForCartOperation$(
        action: ReturnType<typeof addWatchListToCart> | ReturnType<typeof removeWatchListFromCart>
    ): Observable<CartOperationItem[]>
    {
        return this.store.select(selectWatchListItems(action.slug)).pipe(
            first(),
            tap(items => { if (items === null) throw createEgovAPIClientError({ message: 'No items for this list.' }); }),
            tap(items => { if (items === undefined) throw createEgovAPIClientError({ message: 'Items for this list are not available.' }); }),
            map(items => items!.filter(i => !!this.typeDefinitions.definitionsByType[i.type].cartType)),
            tap(items => { if (!items.length) throw createEgovAPIClientError({ message: 'No payable items for this list.' }); }),
            map(items => action.includeTypes ? items.filter(i => action.includeTypes!.includes(i.type)) : items),
            map(items => action.excludeTypes ? items.filter(i => !action.excludeTypes!.includes(i.type)) : items),
            tap(items => { if (!items.length) throw createEgovAPIClientError({ message: 'No payable items left after filtering.' }); }),
            map(items => items.map(i => ({ id: i.id, type: this.typeDefinitions.definitionsByType[i.type].cartType! }))),
        );
    }

    private readonly addWatchListToCart$ = createEffect(() => this.actions$.pipe(
        ofType(addWatchListToCart),
        mergeMap(action => this.getWatchListItemsForCartOperation$(action).pipe(
            switchMap(items => this.cartNetworkService.addToCart$(items)),
            switchMap(cartResponse => of(
                cartContentsLoaded(`Effect of ${addWatchListToCart.type}`, { cartResponse }),
                watchListAddedToCart(`Effect of ${addWatchListToCart.type}`, { slug: action.slug }),
            )),
            catchError((error: EgovAPIError) => of(watchListAddToCartFailed(
                `Effect of ${addWatchListToCart.type}`,
                {
                    slug: action.slug,
                    error,
                    notifyErrors: action.notifyErrors
                }
            ))),
        )),
    ));

    private readonly notifyAddWatchListToCartFailed$ = createEffect(() => this.actions$.pipe(
        ofType(watchListAddToCartFailed),
        filter(a => !!a.notifyErrors),
        tap(action => this.simpleErrorHandling.displayBestErrorMessage(action.error, {
            customMessage: 'Error adding list to the cart',
            retryHandler: () => this.store.dispatch(addWatchListToCart(
                'Snackbar retry',
                { slug: action.slug, notifyErrors: true }
            )),
        })),
    ), { dispatch: false });

    private readonly removeWatchListFromCart$ = createEffect(() => this.actions$.pipe(
        ofType(removeWatchListFromCart),
        mergeMap(action => this.getWatchListItemsForCartOperation$(action).pipe(
            switchMap(items => this.cartNetworkService.removeFromCart$(items)),
            switchMap(cartResponse => of(
                cartContentsLoaded(`Effect of ${removeWatchListFromCart.type}`, { cartResponse }),
                watchListRemovedFromCart(`Effect of ${removeWatchListFromCart.type}`, { slug: action.slug }),
            )),
            catchError((error: EgovAPIError) => of(watchListRemoveFromCartFailed(
                `Effect of ${watchListRemoveFromCartFailed.type}`,
                {
                    slug: action.slug,
                    error,
                    notifyErrors: action.notifyErrors
                }
            ))),
        )),
    ));

    private readonly watchListRemovedFromCartFailed$ = createEffect(() => this.actions$.pipe(
        ofType(watchListRemoveFromCartFailed),
        filter(a => !!a.notifyErrors),
        tap(action => this.simpleErrorHandling.displayBestErrorMessage(action.error, {
            customMessage: 'Error removing list from the cart',
            retryHandler: () => this.store.dispatch(removeWatchListFromCart(
                'Snackbar retry',
                { slug: action.slug, notifyErrors: true },
            )),
        })),
    ), { dispatch: false });


    // Flush

    private readonly flushWatchListsStateOnSignedOut$ = createEffect(() => this.actions$.pipe(
        ofType(signedOut),
        mapTo(flushWatchLists(`Effect of ${signedOut.type}`)),
    ));
}
