import { watchListLoaded } from '@/app/modules/watch-lists/state/feature/entities/watch-list/watch-list.actions';
import { Injectable } from '@angular/core';
import { ofType } from '@ces/sourced-action';
import { Actions, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { billsForPPTAccountLoaded } from '@ppt/state/ppt.actions';
import { State } from '@state/model';
import { EMPTY, of } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { addPPTBillData } from './ppt-bill.actions';

@Injectable({ providedIn: 'root' })
export class PPTBillEntityEffects
{
    constructor
    (
        // Dependencies

        private readonly actions$: Actions,
        private readonly store: Store<State>,
    )
    {}


    // Bills loaded

    private readonly billsForAccountLoaded$ = createEffect(() => this.actions$.pipe(
        ofType(billsForPPTAccountLoaded),
        filter(a => !!a.response.bills?.length),
        mergeMap(({ response }) => response
            ? of(addPPTBillData(`Effect of ${billsForPPTAccountLoaded.type}`, { billsData: response.bills! }))
            : EMPTY
        ),
    ));


    // Watch List details loaded

    private readonly watchListDetailsLoaded$ = createEffect(() => this.actions$.pipe(
        ofType(watchListLoaded),
        filter(a => !!a.response.ppt?.bills?.length),
        map(({ response }) => addPPTBillData(
            `Effect of ${watchListLoaded.type}`,
            { billsData: response.ppt!.bills! }
        )),
    ));
}
