import { ChangeDetectionStrategy, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EgovAPIError, FrontEndSession, PASSWORD_VALIDATOR } from 'egov-api';
import { SimpleErrorHandling } from '@shared/services/simple-error-handling.service';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-password-reset',
    template: `
        <egov-dialog-close-button></egov-dialog-close-button>
        <form [formGroup]="form" (ngSubmit)="resetPassword()">
            <p class="prevent-overlap-with-close-button"> Enter the verification code we sent you via email and your new password. </p>

            <mat-form-field appearance="standard" hideRequiredMarker>
                <mat-label>Verification code</mat-label>
                <input
                    placeholder="&ndash;&ndash;&ndash;&ndash;&ndash;&ndash;"
                    matInput
                    type="tel"
                    formControlName="code"
                    required
                    minlength="6"
                    maxlength="6"
                    [style.letter-spacing]="'1em'"
                    [egovCleave]="{ numericOnly: true, blocks: [6] }"
                />
            </mat-form-field>
            <mat-error *ngIf="(submissionErrors$ | async)?.validation_code?.length">
                {{ ((submissionErrors$ | async)?.validation_code)[0] }}
            </mat-error>

            <mat-form-field appearance="standard" hideRequiredMarker>
                <mat-label>New password</mat-label>
                <input #newPasswordField matInput type="password" formControlName="new-password" required />
            </mat-form-field>
            <mat-hint *ngIf="!(submissionErrors$ | async)?.password?.length && (form.get('new-password')!.pristine || form.get('new-password')!.valid)">
                Minimum eight characters. At least one letter and one number.
            </mat-hint>
            <mat-error *ngIf="(submissionErrors$ | async)?.password?.length || !(form.get('new-password')!.pristine || form.get('new-password')!.valid)">
                {{
                    (submissionErrors$ | async)?.password?.length
                        ? ((submissionErrors$ | async)?.password)[0]
                        : "Minimum eight characters. At least one letter and one number."
                }}
            </mat-error>

            <button mat-flat-button color="primary" class="bigger" type="submit" [disabled]="!form.valid">
                {{ form.disabled ? "Resetting password..." : "Reset password" }}
            </button>
        </form>
    `,
    styleUrls: ['./password-reset.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordResetComponent
{
    constructor
    (
        // Dependencies

        private readonly session: FrontEndSession,
        private readonly simpleErrorHandling: SimpleErrorHandling,


        // Dialog reference and passed values

        private readonly dialogRef: MatDialogRef<PasswordResetComponent>,
        @Inject(MAT_DIALOG_DATA) private readonly email: string,
    )
    {}


    // Form

    protected readonly submissionErrors$ = new BehaviorSubject<any | undefined>(undefined);

    protected readonly form: UntypedFormGroup = new UntypedFormGroup({
        code: new UntypedFormControl(''),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'new-password': new UntypedFormControl('', [ Validators.required, PASSWORD_VALIDATOR ]),
    });

    @ViewChild('newPasswordField') private readonly newPasswordField!: ElementRef<HTMLInputElement>;

    protected resetPassword()
    {
        this.submissionErrors$.next(undefined);

        this.form.disable();

        this.session.resetPassword$(this.email, this.form.value.code, this.form.value['new-password']).subscribe({

            next: () => this.dialogRef.close('valid'),
            error: (error: EgovAPIError) =>
            {
                this.submissionErrors$.next(this.simpleErrorHandling.getValidationErrorsOrDisplayBestMessage(error, {
                    customMessage: 'Error trying to reset password.',
                    retryHandler: () => this.resetPassword(),
                }));

                this.form.enable();
                this.newPasswordField.nativeElement.focus();
            },
        });
    }
}
