import { selectItemInCart } from '@/app/modules/cart/state/common/entities/cart-item/cart-item.selectors';
import { selectEntireState } from '@/app/state/selectors';
import { createSelector } from '@ngrx/store';
import { State } from '@state/model';
import { PPTCart } from '../../../services/ppt-cart.service';
import { selectDelegatedBillMessage, selectDelegatedPriorsMessage, selectPPTPaymentDependencies, selectPPTState } from '../../ppt-state/ppt-state.selectors';
import { selectPPTAccountBillsForCartOperations } from '../../ppt.selectors';
import { selectPPTAccountByBillId } from '../account/ppt-account.selectors';


const selectPPTBillEntity = (s: State) => s.common.ppt.entities.bills;

export const selectPPTBillsById = createSelector(selectPPTBillEntity, s => s.entities);
export const selectPPTBills = createSelector(selectPPTBillEntity, s => s.ids.map(id => s.entities[id]!));
export const selectPPTBill = (id: string) => createSelector(selectPPTBillEntity, s => s.entities[id]);

export const selectPPTBillsByAccountNumber = (accountNumber: string) => createSelector(
    selectPPTBills,
    bills => bills.filter(bill => bill.accountNumber === accountNumber),
);

export const selectPPTBillByAccountNumberAndTaxYear = (accountNumber: string, taxYear: number) => createSelector(
    selectPPTBills,
    bills => bills.find(bill => bill.accountNumber === accountNumber && bill.taxYear === taxYear),
);

export const selectPPTBillLink = (id: string) => createSelector(
    selectPPTBillsById,
    bills =>
    {
        const bill = bills[id];
        if (bill)
            return `/property-tax/bill/${bill.accountNumber}/${bill.taxYear}`;
    }
);

export const selectPPTAppraisalInformation = (id: string) => createSelector(
    selectPPTBillsById,
    bills => bills[id]?.appraisalInformation,
);

export const selectPPTTaxInformation = (id: string) => createSelector(selectPPTBillEntity, s => s.entities[id]?.taxInformation);

export const selectPPTCityTaxSite = (id: string) => createSelector(
    selectPPTBillsById,
    bills => bills[id]?.cityTaxSite,
);

export const selectPPTCountyTaxSite = (id: string) => createSelector(
    selectPPTBillsById,
    bills => bills[id]?.countyTaxSite,
);


// This selector doesn't account for whether a bill's dependencies prevent
// this bill from being paid or not.

export const selectPPTBillDelegated = (id: string) => createSelector(
    selectPPTState, selectPPTBillsById,
    (pptState, bills) => pptState && bills[id] &&
        (
            !pptState.handlesTurnedOver && bills[id]!.turnedOver
            ||
            !pptState.handlesNotTurnedOver && !bills[id]!.turnedOver
        )
);

export const selectPPTBillIsByItselfPayable = (id: string, accountForDelegatedState = false) => createSelector(
    selectPPTBillsById, selectEntireState,
    (billsById, s) =>
    {
        const bill = billsById[id];
        if (bill === undefined) return undefined;
        if (bill.balanceViewBlocked === undefined) return undefined;
        if (bill.paymentBlocked === undefined) return undefined;

        const payable = !!bill.due && !bill.balanceViewBlocked && !bill.paymentBlocked;
        if (!payable) return payable;
        if (accountForDelegatedState) return !selectPPTBillDelegated(id)(s);
        return true;
    }
);

export const selectPPTBillDependencies = (billId: string, excludeDelegated: boolean) => createSelector(
    selectPPTPaymentDependencies, selectPPTBillsById, selectEntireState,
    (paymentDependenciesRequirement, billsById, s) =>
    {
        if (paymentDependenciesRequirement === undefined) return undefined;
        if (paymentDependenciesRequirement === false) return null;

        const bill = billsById[billId];
        const accountNumber = bill?.accountNumber ||
            selectPPTAccountByBillId(billId)(s)?.accountNumber;

        if (bill && accountNumber)
        {
            const billsForCartOperations = selectPPTAccountBillsForCartOperations(accountNumber, excludeDelegated)(s);

            if (billsForCartOperations)
            {
                let requiredBills: string[] | undefined;

                const otherBills = billsForCartOperations
                    .filter(id => id !== billId);

                if (paymentDependenciesRequirement === 'priors')
                {
                    requiredBills = [];

                    const bills = otherBills.map(id => billsById[id]);

                    for (const b of bills)
                    {
                        if (!b) return undefined;

                        if (b.taxYear < bill.taxYear)
                            requiredBills.push(b.id);
                    }
                }
                else if (paymentDependenciesRequirement === 'all')
                    requiredBills = otherBills;

                if (requiredBills && requiredBills.length > 0)
                    return requiredBills;
                else
                    return null;
            }
        }
    }
);

export const selectPPTBillInCart = (id: string) => createSelector(
    selectEntireState,
    s => selectItemInCart(PPTCart.BILL_CART_TYPE, id)(s),
);

export const selectPPTBillCartButtonTitle = (id: string) => createSelector(
    selectEntireState,
    s =>
    {
        const bill = selectPPTBillsById(s)[id];

        if (!bill || bill.paymentBlocked) return '';
        if (selectPPTBillDelegated(id)(s)) return selectDelegatedBillMessage(s) || '';
        if (selectPPTBillDependenciesDelegated(id)(s)) return selectDelegatedPriorsMessage(s) || '';
        if (selectPPTBillInCart(id)(s)) return 'Remove this bill from the cart';
        if (bill.balanceViewBlocked) return 'This bill cannot be added to the cart because its balance is unavailable';
        if (bill.due) return 'Add this bill to the cart';

        return 'This bill cannot be added to the cart because it doesn\'t have a balance due';
    }
);

export const selectPPTBillDependenciesDelegated = (billId: string) => createSelector(
    selectEntireState,
    s =>
    {
        const deps = selectPPTBillDependencies(billId, false)(s);
        const depsDelegated = deps?.map(id => selectPPTBillDelegated(id)(s));

        if (depsDelegated?.includes(undefined)) return undefined;
        if (depsDelegated?.includes(true)) return true;

        return false;
    }
);

export const selectPPTBillOrDependenciesDelegated = (billId: string) => createSelector(
    selectEntireState,
    s => selectPPTBillDelegated(billId)(s) || selectPPTBillDependenciesDelegated(billId)(s)
);

export const selectPPTBillDelegateLink = (id: string) => createSelector(
    selectPPTBillsById,
    bills => bills[id]?.delegateLink
);
