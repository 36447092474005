import { createSourcedAction } from '@ces/sourced-action';
import { CartOperationItem, CartResponse, EgovAPIError } from 'egov-api';
import { props } from '@ngrx/store';


export const loadCart = createSourcedAction('Load cart'); // Signal intent to load it
export const actuallyLoadCart = createSourcedAction('Actually load cart'); // Actually do it
export const cartContentsLoaded = createSourcedAction('Cart contents loaded', props<{ cartResponse: CartResponse }>());
export const cartLoadFailed = createSourcedAction('Cart load failed', props<{ error: EgovAPIError }>());

export const setCartLoadFailed = createSourcedAction('Update cartLoadFailed', props<{ failed: boolean }>());

export const addCartUpdater = createSourcedAction('Add cart updater');
export const removeCartUpdater = createSourcedAction('Remove cart updater');


// Main cart actions
//
// Only use these cart actions directly if you cannot achieve what you need to do, using
// the CartActionsFacade service.
//
// That way you get all the complexities of subscribing and unsubscribing to the actions
// that derive from these intents resolved for you.

export const addItemsToCartIntent = createSourcedAction('Intent to add items to cart', props<{
    id?: string,
    items: CartOperationItem[]
}>());

export const addItemsToCartFullyIntercepted = createSourcedAction('Add items to cart fully intercepted', props<{
    id?: string,
    intent: ReturnType<typeof addItemsToCartIntent>,
}>());

export const addItemsToCart = createSourcedAction('Add items to cart', props<{
    id?: string,
    items: CartOperationItem[],
    intent: ReturnType<typeof addItemsToCartIntent>,
}>());

export const addItemsToCartFailed = createSourcedAction('Add items to cart failed', props<{
    id?: string,
    error: EgovAPIError,
    worthARetry: boolean,
    intent: ReturnType<typeof addItemsToCartIntent>,
    addAction?: ReturnType<typeof addItemsToCart>,
}>());

export const addItemsToCartComplete = createSourcedAction('Add items to cart complete', props<{
    id?: string,
    addAction: ReturnType<typeof addItemsToCart>,
}>());


export const removeItemsFromCartIntent = createSourcedAction('Intent to remove items from cart', props<{
    id?: string,
    items: CartOperationItem[],
}>());

export const removeItemsFromCart = createSourcedAction('Remove items from cart', props<{
    id?: string,
    items: CartOperationItem[],
    intent: ReturnType<typeof removeItemsFromCartIntent>,
}>());

export const removeItemsFromCartFailed = createSourcedAction('Remove items from cart failed', props<{
    id?: string,
    error: EgovAPIError,
    worthARetry?: boolean,
    intent: ReturnType<typeof removeItemsFromCartIntent>,
    removeAction?: ReturnType<typeof removeItemsFromCart>,
}>());

export const removeItemsFromCartComplete = createSourcedAction('Remove items from cart complete', props<{
    id?: string,
    removeAction: ReturnType<typeof removeItemsFromCart>,
}>());


// Other actions

export const emptyCart = createSourcedAction('Empty cart', props<{
    operationId: string,
}>());

export const cartEmptied = createSourcedAction('Cart emptied', props<{
    id: string,
}>());

export const emptyCartFailed = createSourcedAction('Empty cart failed', props<{
    id: string,
}>());

export const invalidateTotal = createSourcedAction('Invalidate cart total');
export const clearCart = createSourcedAction('Clear cart');
export const flushCartState = createSourcedAction('Flush cart state');
