import { Type } from '@angular/core';
import { CartEffects } from '@cart/state/common/cart-common.effects';
import { CartItemEffects } from '@cart/state/common/entities/cart-item/cart-item.effects';
import { PaymentEffects } from '@payment/state/payment.effects';
import { pptEffects } from '@ppt/state/ppt.effects';
import { SessionEffects } from '@session/state/session.effects';
import { btaxEffects } from '@btax/state/btax.effects';
import { AppStateEffects } from './app-state/app-state.effects';
import { watchListsCommonEffects } from '../modules/watch-lists/state/common/watch-lists-common.effects';

export const effects: Type<any>[] = [
    AppStateEffects,
    SessionEffects,
    ...watchListsCommonEffects,
    ...pptEffects,
    ...btaxEffects,
    CartEffects,
    CartItemEffects,
    PaymentEffects,
];
