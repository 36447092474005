import { EntityAdapter, EntityState } from '@ngrx/entity';

// EntityUpdate
//
// This helper allows you to "Update or Insert" an item in a ngrx entity collection while also
// allowing you to transform the items before adding them to the collection if they don't
// already exist in the collection.
//
// Typically used to add `runtimeState` properties to new items without replacing them
// on items.

export interface EntityUpdater<T, U>
{
    update<V extends EntityState<T>>(state: V, newData: U[]): V
}

export const createEntityUpdater = <T, U>(adapter: EntityAdapter<T>, newEntitiesMapper?: (data: U) => T, idProperty: string = 'id'): EntityUpdater<T, U> =>
{
    return {
        update: <V extends EntityState<T>>(state: V, newData: U[]): V => newData.reduce(
            (state, newItemData) => !(state.ids as any[]).includes((newItemData as any)[idProperty])
                ?
                    adapter.addOne(
                        newEntitiesMapper
                            ? newEntitiesMapper(newItemData)
                            : newItemData as unknown as T,
                        state
                    )
                : adapter.updateOne({ id: (newItemData as any)[idProperty], changes: newItemData as any }, state),
            state
        )
    };
};
