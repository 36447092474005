import { ChangeDetectionStrategy, Component } from '@angular/core';

/* eslint-disable @angular-eslint/component-class-suffix */

@Component({
    selector: 'app-not-found',
    template: `
        <app-bg fullPage></app-bg>
        <article class="page not-found">
            <div class="top-content">
                <div class="text-container">
                    <div class="text formatted">
                        <h2>Page not found</h2>
                        <p>
                            Visit our
                            <a routerLink="/">homepage</a>
                        </p>
                    </div>
                </div>
            </div>
        </article>
    `,
    styleUrls: ['./not-found.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundPage {}
