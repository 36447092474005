import { selectBTaxAccountIsInCart, selectBTaxAccountIsPayable } from '@/app/modules/btax/state/entities/account/btax-account.selectors';
import { State } from '@/app/state/model';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { WatchListItemTypeDefinition } from '../watch-list-item-definition.model';
import { BTAX_ACCOUNT_WATCH_LIST_ITEM_TYPE } from './btax-account-watch-list-item.key';

@Injectable()
export class BTaxWatchListItemDefinition implements WatchListItemTypeDefinition
{
    public readonly type = BTAX_ACCOUNT_WATCH_LIST_ITEM_TYPE;

    constructor
    (
        // Dependencies

        private readonly store: Store<State>,
    )
    {}

    public readonly cartType = 'btax-account';
    public readonly icon = 'business';
    public readonly name = 'Business Tax';
    public readonly importComponent =
        () => import('@watch-lists/items/btax-account-watch-list-item/btax-account-watch-list-item.component')
            .then(({ BTaxAccountWatchListItemComponent }) => BTaxAccountWatchListItemComponent);

    public readonly itemIsPayable = (id: string) => this.store.select(selectBTaxAccountIsPayable(id)).pipe(
        map(payable => !!payable),
    );

    public readonly itemIsInCart = (id: string) => this.store.select(selectBTaxAccountIsInCart(id)).pipe(
        map(inCart => !!inCart),
    );
}