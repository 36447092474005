import { createSourcedAction } from '@ces/sourced-action';
import { PaymentMethod } from '@egovsolutions/angular-payment-methods';
import { props } from '@ngrx/store';
import { PaymentState } from 'egov-api';

export const loadPaymentState = createSourcedAction('Load payment service state');
export const paymentStateLoaded = createSourcedAction('Payment state loaded', props<{ state: PaymentState }>());
export const paymentStateLoadFailed = createSourcedAction('Payment state load failed');

export const setPaymentMethods = createSourcedAction('Set payment methods', props<{ paymentMethods: PaymentMethod[] }>());
export const clearPaymentMethods = createSourcedAction('Clear payment methods');
export const removePaymentMethod = createSourcedAction('Remove payment method', props<{ id: string }>());

// TODO: This action should probably be removed once the API implementation is ready which would probably return
// an updated list of all the payment methods when a new one is submitted.
export const addPaymentMethod = createSourcedAction('Add payment method', props<{ paymentMethod: PaymentMethod }>());

export const flushPaymentState = createSourcedAction('Flush payment state');
