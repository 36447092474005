import { createSelector } from '@ngrx/store';
import { State } from '@state/model';


export const selecWatchListsCommonState = (state: State) => state.common.watchLists;
export const selectWatchListsState = createSelector(selecWatchListsCommonState, s => s?.state);
export const selectWatchListsStateLoaded = createSelector(selecWatchListsCommonState, s => !!s.state);

export const selectWatchListsEnabled = createSelector(selectWatchListsState, s => s?.available);
export const selectWatchListsUnavailabilityMessage = createSelector(selectWatchListsState, s => s?.unavailabilityMessage);
