import { addBTaxAccountData } from '@/app/modules/btax/state/entities/account/btax-account.actions';
import { selectBTaxAccountBillsForCartOperations, selectBTaxAccountByBillId } from '@/app/modules/btax/state/entities/account/btax-account.selectors';
import { addBTaxBillData } from '@/app/modules/btax/state/entities/bill/btax-bill.actions';
import { selectBTaxBill } from '@/app/modules/btax/state/entities/bill/btax-bill.selectors';
import { State } from '@/app/state/model';
import { BTaxBillCartItem, BTaxBillCartItemData, BTAX_BILL_CART_ITEM_TYPE } from 'egov-api';
import { Injectable } from '@angular/core';
import { sync } from '@ces/sync';
import { DialogsService } from '@egovsolutions/angular-dialogs-service';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { CartPresentationalItemDefinition } from '../../models/cart-presentational-item-definition.model';
import { ConfirmDependenciesInclussion } from '../../services/confirm-dependencies-inclussion.service';
import { ConfirmDependentsRemoval } from '../../services/confirm-dependents-removal.service';
import { CartItemData } from '../../state/common/entities/cart-item/cart-item.model';
import { CartPagePlugin, CartPlugin } from '../cart-plugin.model';

@Injectable()
export class BTaxCartPlugin implements CartPlugin<BTaxBillCartItemData>
{
    public readonly type = BTAX_BILL_CART_ITEM_TYPE;

    constructor
    (
        private readonly store: Store<State>,
        private readonly dialogs: DialogsService,
        private readonly confirmDependenciesInclussion: ConfirmDependenciesInclussion,
        private readonly confirmDependentsRemoval: ConfirmDependentsRemoval,
    )
    {}

    public parse = (cartResponse: any) =>
    {
        if (cartResponse?.cart_contents?.BTAX)
        {
            const billIds = cartResponse.cart_contents.BTAX.billIds || [];
            const bills = cartResponse.cart_contents.BTAX.bills || [];

            return billIds
                .filter((id: string) => bills.find((bill: any) => bill.id === id))
                .map((billId: string): BTaxBillCartItemData =>
                {
                    const bill = bills.find((b: any) => b.id === billId.toString());
                    const itemId = `${BTAX_BILL_CART_ITEM_TYPE}-${billId}`;

                    return {
                        type: BTAX_BILL_CART_ITEM_TYPE,
                        id: itemId,
                        amount: bill.due,
                        billId,
                        accountNumber: bill.accountNumber,
                    };
                });
        }
    };

    public parseEntities(response: any)
    {
        if (response.cart_contents.BTAX)
        {
            if (response.cart_contents.BTAX.accounts)
                this.store.dispatch(addBTaxAccountData('BTaxCart entity parsing', {
                    accountsData: response.cart_contents.BTAX.accounts
                }));

            if (response.cart_contents.BTAX.bills)
                this.store.dispatch(addBTaxBillData('BTaxCart entity parsing', {
                    billsData: response.cart_contents.BTAX.bills,
                }));
        }
    }


    // Dependencies

    public readonly dependencyLogic =
    {
        getDependencies: (itemId: string): string[] | null | undefined =>
        {
            const bill = sync(this.store.select(selectBTaxBill(itemId)));
            const accountNumber = bill?.accountNumber ||
                sync(this.store.select(selectBTaxAccountByBillId(itemId)))?.accountNumber;

            if (accountNumber)
            {
                const billsForCartOperations = sync(this.store.select(
                    selectBTaxAccountBillsForCartOperations(accountNumber)
                ));

                if (billsForCartOperations)
                {
                    const otherBills = billsForCartOperations.filter(id => id !== itemId);

                    if (otherBills.length > 0)
                        return otherBills;
                    else
                        return null;
                }
            }

            return undefined;
        },

        getMissingDependencies: (
            itemId: string,
            simultaneousAdditions: string[],
            itemsAlreadyInCart: string[],
        ): string[] | null | undefined =>
        {
            const dependencies = this.dependencyLogic.getDependencies(itemId)!;
            if (!dependencies) return dependencies;

            const missingDependencies = dependencies.filter(id =>
                !itemsAlreadyInCart.includes(id) && !simultaneousAdditions.includes(id)
            );

            return missingDependencies.length ? missingDependencies : null;
        },

        confirmDependenciesInclussion$: (
            itemId: string,
            simultaneousAdditions: string[],
            itemsAlreadyInCart: string[]
        ): Observable<boolean> =>
        {
            const missingDependencies = this.dependencyLogic.getMissingDependencies(itemId, simultaneousAdditions, itemsAlreadyInCart)!;
            const missingDependenciesNames = missingDependencies?.map(id => this.dependencyLogic.getBillName(id));

            return missingDependenciesNames?.length
                ? this.confirmDependenciesInclussion.launchConfirmationDialog(
                    this.dependencyLogic.getBillName(itemId),
                    missingDependenciesNames
                )
                : of(true);
        },


        getDependents: (itemId: string): string[] | null | undefined =>
        {
            const bill = sync(this.store.select(selectBTaxBill(itemId)));

            const accountNumber = bill?.accountNumber ||
                sync(this.store.select(selectBTaxAccountByBillId(itemId)))?.accountNumber;

            if (accountNumber)
            {
                const billsForCartOperations = sync(this.store.select(
                    selectBTaxAccountBillsForCartOperations(accountNumber),
                ));

                if (billsForCartOperations)
                {
                    const otherBills = billsForCartOperations.filter(id => id !== itemId);

                    if (otherBills.length > 0)
                        return otherBills;
                    else
                        return null;
                }
            }

            return undefined;
        },

        getAddedDependents: (
            itemId: string,
            simultaneousRemovals: string[],
            itemsInCart: string[],
        ): string[] | null | undefined =>
        {
            const dependents = this.dependencyLogic.getDependents(itemId)!;
            if (!dependents) return dependents;

            const addedDependents =
                dependents.filter(id => itemsInCart.includes(id) && !simultaneousRemovals.includes(id));

            return addedDependents.length ? addedDependents : null;
        },

        confirmDependentsRemoval$: (
            itemId: string,
            simultaneousAdditions: string[],
            itemsInCart: string[],
        ): Observable<boolean> =>
        {
            const addedDependents = this.dependencyLogic.getAddedDependents(itemId, simultaneousAdditions, itemsInCart)!;
            const addedDependentsNames = addedDependents?.map(id => this.dependencyLogic.getBillName(id));

            return addedDependentsNames?.length
                ? this.confirmDependentsRemoval.launchConfirmationDialog(
                    this.dependencyLogic.getBillName(itemId) + ' for this account',
                    addedDependentsNames
                )
                : of(true);
        },

        getBillName: (billId: string) =>
        {
            const bill = sync(this.store.select(selectBTaxBill(billId)));

            if (bill)
                return `Tax Year ${bill.taxYear}`;

            return `Bill #${billId}`;
        }
    };
}


@Injectable()
export class BTaxCartPagePlugin implements CartPagePlugin<BTaxBillCartItemData>
{
    constructor
    (
        private readonly store: Store<State>,
    )
    {}

    public readonly type = BTAX_BILL_CART_ITEM_TYPE;

    public createPresentationalItems(cartItems: CartItemData[]): CartPresentationalItemDefinition[]
    {
        const accountNumbers = new Set<string>();

        for (const item of cartItems)
        {
            if (item.type === BTAX_BILL_CART_ITEM_TYPE)
                accountNumbers.add((item as BTaxBillCartItem).accountNumber);
        }

        return Array.from(accountNumbers).map(aN => (
        {
            type: 'btax-account',
            id: aN,
            componentImporter:
                () => import('@cart/plugins/btax/btax-account-cart-item.component')
                    .then(({ BTaxAccountCartItemComponent }) => BTaxAccountCartItemComponent),
        }));
    }
}
