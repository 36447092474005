import { createEntityUpdater } from '@/helpers/entity-updater';
import { createReducer } from '@ces/sourced-action';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { on } from '@ngrx/store';
import { subscriptionAdded, subscriptionsForAccountLoaded } from '@subscriptions/state/subscription.actions';
import { PPTAccount, PPTAccountData } from 'egov-api';
import { addPPTAccountData } from './ppt-account.actions';
import { PPTAccountEntities } from './ppt-account.model';


// Runtime state

const addRuntimeState = (accountData: PPTAccountData): PPTAccount => ({
    ...accountData
});


// Adapter

const adapter: EntityAdapter<PPTAccount> = createEntityAdapter<PPTAccount>({ selectId: a => a.accountNumber });
const updater = createEntityUpdater(adapter, addRuntimeState, 'accountNumber');


// Initial state

const initialState = adapter.getInitialState() as PPTAccountEntities;


// Reducer

export const pptAccountsReducer = createReducer<PPTAccountEntities>(

    initialState,

    on(addPPTAccountData, (s, { accountsData }) => updater.update(s,
        accountsData.map(aD => (
        {
            // Served

            ...aD as any,
        }))
    )),

    on(subscriptionsForAccountLoaded, (s, { accountNumber, subscriptionsData }) => adapter.updateOne(
        {
            id: accountNumber,
            changes: { subscriptions: subscriptionsData.map(data => data.id) },
        },
        s
    )),

    on(subscriptionAdded, (s, { accountNumber, subscriptionData }) => adapter.updateOne(
        {
            id: accountNumber,
            changes: { subscriptions: (s.entities[accountNumber]?.subscriptions || []).concat([subscriptionData.id]) }
        },
        s
    )),

);
