import { stateLoaded } from '@/app/state/app-state/app-state.actions';
import { createReducer } from '@ces/sourced-action';
import { combineReducers, on } from '@ngrx/store';
import { BTaxStoreState } from './btax.model';
import { btaxStateReducer } from './btax-state/btax-state.reducer';
import { btaxEntitiesReducer } from './entities';

const supportedByStateReducer = createReducer<boolean | undefined>(
    undefined,
    on(stateLoaded, (s, { newState }) => newState.state === 'fl'),
);

export const btaxReducer = combineReducers<BTaxStoreState>({
    supportedByState: supportedByStateReducer,
    state: btaxStateReducer,
    entities: btaxEntitiesReducer,
});
