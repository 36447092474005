import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BTaxWatchListItemDefinition } from '../../items/btax-account-watch-list-item/btax-account-watch-list-item.definition';
import { PPTWatchListItemDefinition } from '../../items/ppt-account-watch-list-item/ppt-account-watch-list-item.definition';
import { WATCH_LIST_ITEM_COMPONENT_DEFINITION } from '../../items/watch-list-item-definition.model';
import { WatchListItemTypeDefinitions } from '../../services/watch-list-item-definitions.service';
import { watchListsFeatureEffects } from './watch-lists-feature.effects';
import { WATCH_LISTS_STATE_KEY } from './watch-lists-feature.key';
import { watchListsFeatureReducer } from './watch-lists-feature.reducer';


@NgModule(
{
    imports:
    [
        StoreModule.forFeature(WATCH_LISTS_STATE_KEY, watchListsFeatureReducer),
        EffectsModule.forFeature(watchListsFeatureEffects),
    ],
    providers:
    [
        // Lazy watch list item components

        { provide: WATCH_LIST_ITEM_COMPONENT_DEFINITION, multi: true, useClass: PPTWatchListItemDefinition },
        { provide: WATCH_LIST_ITEM_COMPONENT_DEFINITION, multi: true, useClass: BTaxWatchListItemDefinition },

        WatchListItemTypeDefinitions,
    ]
})
export class WatchListsStateModule { }
