import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class NavigationBacktrack
{

    constructor
    (
        // Dependencies

        private readonly router: Router,
    )
    {
        this.init();
    }


    // Initialization

    private init()
    {
        this.initializeNavigationHandling();
    }


    // Navigation handling

    private initializeNavigationHandling()
    {
        (this.router.events.pipe(filter(e => e instanceof NavigationStart)) as Observable<NavigationStart>)
            .subscribe((e: NavigationStart) =>
            {
                // Store the original ids for future reference

                if (e.restoredState)
                    this.navigationIdMappings[e.id] = this.getOriginalNavigationId(e.restoredState.navigationId);
            });
    }


    // Mapping navigation IDs

    private readonly navigationIdMappings: number[] = [];

    public getOriginalNavigationId(id: number)
    {
        return this.navigationIdMappings[id] || id;
    }
}
