import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FrontEndSession } from 'egov-api';
import { SimpleErrorHandling } from '@shared/services/simple-error-handling.service';


@Component({
    selector: 'app-get-password-reset-code',
    template: `
        <egov-dialog-close-button></egov-dialog-close-button>

        <form [formGroup]="form" (ngSubmit)="getCode()">
            <p class="prevent-overlap-with-close-button">
                Enter your email below so we can send you a password reset code.
            </p>

            <mat-form-field appearance="standard" hideRequiredMarker>
                <mat-label>Email</mat-label>
                <input matInput type="email" formControlName="email" required />
            </mat-form-field>

            <button mat-flat-button color="primary" class="bigger" type="submit" [disabled]="!form.valid">
                {{ form.disabled ? "Sending reset code..." : "Send me a reset code" }}
            </button>
        </form>
    `,
    styleUrls: ['./get-password-reset-code.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GetPasswordResetCodeComponent
{
    constructor
    (
        // Dependencies

        private readonly session: FrontEndSession,
        private readonly simpleErrorHandling: SimpleErrorHandling,


        // Dialog reference

        private readonly dialogRef: MatDialogRef<GetPasswordResetCodeComponent, string | undefined>,
    )
    {}


    // Form

    protected readonly form: UntypedFormGroup = new UntypedFormGroup({ email: new UntypedFormControl('', [Validators.required, Validators.email]) });

    protected getCode()
    {
        this.form.disable();

        this.session.getPasswordResetCode$(this.form.value.email).subscribe({

            next: () => this.dialogRef.close(this.form.value.email),
            error: error =>
            {
                this.simpleErrorHandling.displayBestErrorMessage(error, {
                    customMessage: 'Error requesting password reset code.',
                    retryHandler: () => this.getCode()
                });

                this.form.enable();
            },
        });
    }
}
