import { createSelector } from '@ngrx/store';
import { State } from '@state/model';


export const selectPaymentStoreState = (s: State) => s.common.payment;

export const selectPaymentStateLoading = createSelector(selectPaymentStoreState, s => s.loading);
export const selectPaymentStateLoadFailed = createSelector(selectPaymentStoreState, s => s.stateLoadFailed);
export const selectPaymentState = createSelector(selectPaymentStoreState, s => s.state);

export const selectPaymentsAvailable = createSelector(selectPaymentState, s => !!s?.paymentsAvailable);
export const selectPaymentsUnavailableMessage = createSelector(selectPaymentState, s => s?.paymentsUnavailableMessage);
export const selectPaymentTypesRequiringUserSession =
    createSelector(selectPaymentState, s => s?.paymentTypesRequiringUserSession);
export const selectPaymentMethodsInfo = createSelector(selectPaymentState, s => s?.paymentMethodsInfo);

export const selectAvailablePaymentTypes = createSelector(selectPaymentState, s => s?.availablePaymentTypes);

export const selectPaymentMethods = createSelector(selectPaymentStoreState, s => s.paymentMethods);
export const selectPaymentMethod = (id: string) => createSelector(
    selectPaymentMethods,
    pms => pms?.find(pm => pm.id === id),
);
