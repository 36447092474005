import { Injectable } from '@angular/core';
import { ofType } from '@ces/sourced-action';
import { CardProvider } from '@egovsolutions/angular-payment-types';
import { Actions, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { selectSignedIn } from '@session/state/session.selectors';
import { State } from '@state/model';
import { PaymentNetworkService } from 'egov-api';
import { EMPTY, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { signedOut } from '../../session/state/session.actions';
import { clearPaymentMethods, flushPaymentState, loadPaymentState, paymentStateLoaded, paymentStateLoadFailed, setPaymentMethods } from './payment.actions';
import { selectPaymentMethods } from './payment.selectors';


@Injectable()
export class PaymentEffects
{
    constructor
    (
        // Dependencies

        private readonly actions$: Actions,
        private readonly networkService: PaymentNetworkService,
        private readonly store: Store<State>,
    )
    {}


    private readonly loadState$ = createEffect(() => this.actions$.pipe(
        ofType(loadPaymentState),
        mergeMap(_ => this.networkService.loadState$().pipe(
            map(state => paymentStateLoaded(`Effect of ${loadPaymentState.type}`, { state })),
            catchError(error => of(paymentStateLoadFailed(`Effect of ${loadPaymentState.type}`))),
        )),
    ));

    private readonly populatePaymentMethodsOnSignIn$ = createEffect(() => this.store.select(selectSignedIn).pipe(
        filter(singedIn => !!singedIn),
        withLatestFrom(this.store.select(selectPaymentMethods)),
        switchMap(([signedIn, paymentMethods]) =>
        {
            if (signedIn)
            {
                // eslint-disable-next-line no-constant-condition
                if (!paymentMethods && false)
                    // TODO: Stop faking this when possible
                    return of(setPaymentMethods('Effect of Signed in', {
                        paymentMethods:
                        [
                            {
                                id: '123412341234',
                                usCard: true,
                                cardLastFour: '3481',
                                cardProvider: CardProvider.MasterCard,
                            },
                            {
                                id: '223123412',
                                usCard: false,
                                cardLastFour: '6621',
                                cardProvider: CardProvider.Other,
                            },
                            {
                                id: '3412341234',
                                accountLastFour: '1247',
                                routingLastFour: '5911',
                            },
                        ]
                    }));
                else return EMPTY;
            }
            else return of(clearPaymentMethods('Effect of Signed out'));
        }),
    ));

    private readonly flushPaymentStateOnSignedOut$ = createEffect(() => this.actions$.pipe(
        ofType(signedOut),
        map(() => flushPaymentState(`Effect of ${signedOut.type}`)),
    ));
}
