import { HeaderService } from '@/app/components/header/header.service';
import { State } from '@/app/state/model';
import { Injectable } from '@angular/core';
import { ofType } from '@ces/sourced-action';
import { CartOperationItem, createEgovAPIClientError } from 'egov-api';
import { isString } from '@egovsolutions/type-checking';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { SimpleErrorHandling } from '../../shared/services/simple-error-handling.service';
import { addItemsToCartComplete, addItemsToCartFailed, addItemsToCartFullyIntercepted, addItemsToCartIntent, removeItemsFromCartComplete, removeItemsFromCartFailed, removeItemsFromCartIntent } from '../state/common/cart-common.actions';


@Injectable({ providedIn: 'any' })
export class CartActionsFacade
{
    private static actionId = Number.MIN_SAFE_INTEGER;

    constructor
    (
        // Dependencies

        private readonly store: Store<State>,
        private readonly actions: Actions,
        private readonly headerService: HeaderService,
        private readonly simpleErrorHandling: SimpleErrorHandling,
    )
    {}

    public addItemsToCart(
        items: CartOperationItem[],
        raiseAttention = true,
        notifyErrors: boolean | string = true,
        retryHandler?: () => any
    ): Subject<void>
    {
        const subject = new Subject<void>();

        const id = 'CartActions-' + CartActionsFacade.actionId++;


        // Actions

        const actions$ = this.actions.pipe(
            ofType(addItemsToCartFullyIntercepted, addItemsToCartFailed, addItemsToCartComplete),
            first(a => a.id === id),
        );

        const intercepted$ = actions$.pipe(ofType(addItemsToCartFullyIntercepted));
        const failed$ = actions$.pipe(ofType(addItemsToCartFailed));
        const complete$ = actions$.pipe(ofType(addItemsToCartComplete));


        // Events

        intercepted$.subscribe(action =>
        {
            subject.error(createEgovAPIClientError({ message: 'Operation stopped by user.' }));

            if (notifyErrors)
                this.simpleErrorHandling.displaySnackbar(
                    isString(notifyErrors) ? notifyErrors : 'No items could be added to the cart.'
                );
        });

        complete$.subscribe(() =>
        {
            subject.next();
            subject.complete();
            if (raiseAttention) this.headerService.raiseAttentionToTotalIfChanged();
        });

        failed$.subscribe(action =>
        {
            subject.error(action.error);

            if (notifyErrors)
                this.simpleErrorHandling.displaySnackbar(
                    isString(notifyErrors) ? notifyErrors : 'Could not add items to the cart.',
                    action.worthARetry ? retryHandler : undefined
                );
        });


        // Dispatch

        this.store.dispatch(addItemsToCartIntent('CartActions .addItemsToCart()', { id, items }));


        return subject;
    }

    public removeItemsFromCart(
        items: CartOperationItem[],
        raiseAttention = true,
        notifyErrors: boolean | string = true,
        retryHandler?: () => any
    ): Subject<void>
    {
        const subject = new Subject<void>();

        const id = 'CartActions-' + CartActionsFacade.actionId++;


        // Actions

        const actions$ = this.actions.pipe(
            ofType(removeItemsFromCartFailed, removeItemsFromCartComplete),
            first(a => a.id === id),
        );

        const failed$ = actions$.pipe(ofType(removeItemsFromCartFailed));
        const complete$ = actions$.pipe(ofType(removeItemsFromCartComplete));


        // Events

        complete$.subscribe(() =>
        {
            subject.next();
            subject.complete();
            if (raiseAttention) this.headerService.raiseAttentionToTotalIfChanged();
        });


        failed$.subscribe(action =>
        {
            subject.error(action.error);

            if (notifyErrors)
                this.simpleErrorHandling.displaySnackbar(
                    isString(notifyErrors) ? notifyErrors : 'Could not remove items from the cart.',
                    retryHandler
                );
        });


        // Dispatch

        this.store.dispatch(removeItemsFromCartIntent('CartActions .removeItemsFromCart()', { id, items }));


        return subject;
    }
}
