import { createSelector } from '@ngrx/store';
import { State } from '@state/model';


const selectCartState = (state: State) => state.common.cart;

export const selectLatestCartResponse = createSelector(selectCartState, s => s.latestCartResponse);

export const selectCartBeingUpdated = createSelector(selectCartState, s => !!s.cartBeingUpdated);
export const selectCartLoadFailed = createSelector(selectCartState, s => s.cartLoadFailed);


export const selectPaidOrderReceiptId = createSelector(
    selectCartState, s => s.paidOrderReceiptId
);

export const selectGrandTotalWithoutFees = createSelector(selectCartState, s => s.grandTotalWithoutFees);

export const selectPaidOrderItems = createSelector(selectCartState, s => s.paidOrderItems);
export const selectPaidOrderAllItems = createSelector(
    selectCartState,
    s => s?.paidOrderItems?.ids.map(id => s?.paidOrderItems?.entities[id])
);

export const selectPaidOrderItemsById = createSelector(selectCartState, e => e.paidOrderItems?.entities);

export const selectPaidOrderItem = (id: string) => createSelector(
    selectPaidOrderItemsById,
    itemsById => itemsById?.[id]
);

export const selectPaidOrderGrandTotalWithoutFees = createSelector(
    selectCartState, s => s.paidOrderGrandTotalWithoutFees
);

export const selectPaidOrderReceiptUrl = createSelector(
    selectCartState, s => s.paidOrderReceiptUrl
);

export const selectCartSupportsPartialAccountUpdates = createSelector(selectCartState, s => s.supportsPartialAccountUpdates);
export const selectCartProvidesAllPayablesForEachAccount = createSelector(selectCartState, s => s.providesAllPayableItemsForEachAccount);
