import { State } from '@state/model';
import { createSelector } from '@ngrx/store';


export const selectAppState = (state: State) => state.state;
export const selectAppStateLoaded = createSelector(selectAppState, s => !!s);

export const selectPageTitleSuffix = createSelector(selectAppState, s => s?.clientsPageTitleSuffix);

export const selectClientsLogoImage = createSelector(selectAppState, s => s?.clientsLogoImage);
export const selectClientsLogoImageWidth = createSelector(selectAppState, s => s?.clientsLogoImageWidth);
export const selectClientsLogoImageHeight = createSelector(selectAppState, s => s?.clientsLogoImageHeight);
export const selectClientsLogoImageAspectRatio = createSelector(
    selectClientsLogoImageWidth, selectClientsLogoImageHeight,
    (w, h) => w && h && w / h
);

export const selectClientsName = createSelector(selectAppState, s => s?.clientsName);
export const selectClientsTitle = createSelector(selectAppState, s => s?.clientsTitle);
export const selectClientsSlogan = createSelector(selectAppState, s => s?.clientsSlogan);

export const selectBGs = createSelector(selectAppState, s => s?.bgs);
export const selectBgOverlayColor = createSelector(selectAppState, s => s?.bgOverlayColor);
export const selectBgOverlayOpacity = createSelector(selectAppState, s => s?.bgOverlayOpacity);
export const selectBgOverlayBlendMode = createSelector(selectAppState, s => s?.bgOverlayBlendMode);

export const selectState = createSelector(selectAppState, s => s?.state);

export const selectUserAccountsEnabled = createSelector(selectAppState, s => s?.accountsEnabled);
export const selectPhoneRequiredForSignUp = createSelector(selectAppState, s => s?.phoneRequiredForSignUp);

export const selectFeedbackEnabled = createSelector(selectAppState, s => s?.feedbackEnabled);

export const selectTermsAndConditions = createSelector(selectAppState, s => s?.termsAndConditions);
export const selectCopyright = createSelector(selectAppState, s => s?.copyright);
