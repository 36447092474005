import { createReducer } from '@ces/sourced-action';
import { on } from '@ngrx/store';
import { BTaxState } from 'egov-api';
import { btaxStateLoaded } from './btax-state.actions';


// Initial state

const initialState = undefined;


// Reducer

export const btaxStateReducer = createReducer<BTaxState | undefined>(
    initialState,
    on(btaxStateLoaded, (s, { newState }) =>
    ({

        // Defaults

        displayReceiptId: false,


        // Received

        ...newState as any,


        // Testing

        // displayPrintBillButton: true

        // availability:
        // {
        //     ...newState.availability,
        //     // watchLists: false,
        // }

    })),
);
