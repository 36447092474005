import { createReducer } from '@ces/sourced-action';
import { on } from '@ngrx/store';
import { addPaymentMethod, clearPaymentMethods, flushPaymentState, loadPaymentState, paymentStateLoaded, paymentStateLoadFailed, removePaymentMethod, setPaymentMethods } from './payment.actions';
import { PaymentStoreState } from './payment.model';

const initialState: PaymentStoreState = {
    loading: false,
    stateLoadFailed: false,
};

export const paymentReducer = createReducer<PaymentStoreState>(
    initialState,

    on(loadPaymentState, s => ({ ...s, loading: true, stateLoadFailed: false })),
    on(paymentStateLoaded, (s, { state }) => ({ ...s, loading: false, stateLoadFailed: false, state })),
    on(paymentStateLoadFailed, s => ({ ...s, loading: false, stateLoadFailed: true })),

    on(setPaymentMethods, (s, { paymentMethods }) => ({ ...s, paymentMethods })),
    on(clearPaymentMethods, s => s.paymentMethods ? { ...s, paymentMethods: undefined } : s),
    on(removePaymentMethod, (s, { id }) => !s?.paymentMethods ? s : { ...s,
        paymentMethods: s.paymentMethods.filter(pm => pm.id !== id)
    }),

    on(addPaymentMethod, (s, { paymentMethod }) => ({ ...s,
        paymentMethods: (s.paymentMethods || []).concat(paymentMethod)
    })),

    on(flushPaymentState, s => initialState),
);
