import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { DialogsService } from '@egovsolutions/angular-dialogs-service';
import { filter } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class Upgrades
{
    constructor
    (
        private readonly updates: SwUpdate,
        private readonly dialogs: DialogsService,
    )
    {
        this.init();
    }

    private init()
    {
        this.updates.versionUpdates.pipe(filter(e => e.type === 'VERSION_READY')).subscribe(() =>
        {
            this.dialogs.dialog(
            {
                title: 'New version available',
                content: 'There\'s a new version of this application. Do you want to load it?',
                buttons:
                [
                    {
                        label: 'Not now',
                        closeState: false,
                    },
                    {
                        label: 'Load new version',
                        default: true,
                        closeState: true,
                    },
                ],
                navigate: true,
                autoFocus: true,
            }).beforeClosed().subscribe(upgrade => { if (upgrade) window.location.reload(); } );
        });
    }
}
