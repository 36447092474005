import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ConfirmDependenciesInclussionComponent } from '@cart/components/confirm-dependencies-inclussion/confirm-dependencies-inclussion.component';
import { ConfirmDependentsRemovalComponent } from '@cart/components/confirm-dependents-removal/confirm-dependents-removal.component';
import { AppTickSchedulerModule } from '@egovsolutions/angular-app-tick-scheduler';
import { CleaveModule } from '@egovsolutions/angular-cleave';
import { ClickOnKeyModule } from '@egovsolutions/angular-click-on-key';
import { ConfirmModule } from '@egovsolutions/angular-confirm';
import { DialogCloseButtonModule } from '@egovsolutions/angular-dialog-close-button';
import { GenericDialogModule } from '@egovsolutions/angular-dialogs-service';
import { LazyModule } from '@egovsolutions/angular-lazy';
import { MapModule } from '@egovsolutions/angular-map';
import { MessagesModule } from '@egovsolutions/angular-messages';
import { PreventAutofocusModule } from '@egovsolutions/angular-prevent-autofocus';
import { ResponsiveToolbarModule } from '@egovsolutions/angular-responsive-toolbar';
import { SetHeightModule } from '@egovsolutions/angular-set-height';
import { ShowMoreModule } from '@egovsolutions/angular-show-more';
import { TextareaAutoheightModule } from '@egovsolutions/angular-textarea-autoheight';
import { TooltipDirectiveModule } from '@egovsolutions/angular-tooltip-directive';
import { UIPlaceholdersModule } from '@egovsolutions/angular-ui-placeholders';
import { UntilValueModule } from '@egovsolutions/angular-until-value';
import { USPhoneModule } from '@egovsolutions/angular-usphone';
import { WaitABitModule } from '@egovsolutions/angular-wait-a-bit';
import { MaterialModule } from '@material/material.module';
import { NotFoundPage } from '@shared/pages/not-found/not-found.component';
import { TimeAgoPipe } from 'app/../../frozen-libs/time-ago-pipe/time-ago-pipe';
import { MarkdownToHtmlModule } from 'markdown-to-html-pipe';
import { ActionButtonSpinnerComponent } from './components/action-button-spinner/action-button-spinner.component';
import { AddOrRemoveFromCartButtonComponent } from './components/add-or-remove-from-cart-button/add-or-remove-from-cart-button.component';
import { BgComponent } from './components/bg/bg.component';
import { BottomInfoComponent } from './components/bottom-info/bottom-info.component';
import { CardLogoComponent } from './components/card-logo/card-logo.component';
import { ContentPlaceholderComponent } from './components/content-placeholder/content-placeholder.component';
import { DataConnectorComponent } from './components/data-connector/data-connector.component';
import { DataDisclaimerComponent } from './components/data-disclaimer/data-disclaimer.component';
import { DataJumbotronComponent } from './components/data-jumbotron/data-jumbotron.component';
import { NAComponent } from './components/data/n-a/n-a.component';
import { LazyDialogContentComponent } from './components/dialogs/lazy-dialog-content/lazy-dialog-content.component';
import { FeedbackButtonComponent } from './components/feedback-button/feedback-button.component';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { LoadMoreComponent } from './components/load-more/load-more.component';
import { BackButtonComponent } from './components/page-toolbar/back-button/back-button.component';
import { PageToolbarComponent } from './components/page-toolbar/page-toolbar.component';
import { PageComponent } from './components/page/page.component';
import { PoweredByComponent } from './components/powered-by/powered-by.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ShowMoreComponent } from './components/show-more/show-more.component';
import { SpinnerButtonSpinnerComponent } from './components/spinner-button-spinner/spinner-button-spinner.component';
import { TableActionSpinnerComponent } from './components/table-action-spinner/table-action-spinner.component';
import { WarnedComponent } from './components/warned/warned.component';
import { ModalDirective } from './directives/modal.directive';
import { PartialAmountDirective } from './directives/partial-amount.directive';
import { CartStateBarComponent } from './components/cart-state-bar/cart-state-bar.component';
import { SpaceTakerComponent } from './components/cart-state-bar/space-taker/space-taker.component';


@NgModule({
    declarations:
    [
        BgComponent,
        DataDisclaimerComponent,
        ModalDirective,
        PoweredByComponent,
        TableActionSpinnerComponent,
        LoadMoreComponent,
        ContentPlaceholderComponent,
        ActionButtonSpinnerComponent,
        TimeAgoPipe,
        ProgressBarComponent,
        ShowMoreComponent,
        FeedbackButtonComponent,
        BottomInfoComponent,
        CardLogoComponent,
        NotFoundPage,
        PageComponent,
        SpinnerButtonSpinnerComponent,
        LazyDialogContentComponent,
        IconButtonComponent,
        NAComponent,
        ConfirmDependenciesInclussionComponent,
        ConfirmDependentsRemovalComponent,
        DataJumbotronComponent,
        PageToolbarComponent,
        DataConnectorComponent,
        BackButtonComponent,
        WarnedComponent,
        AddOrRemoveFromCartButtonComponent,
        PartialAmountDirective,
        CartStateBarComponent,
        SpaceTakerComponent,
    ],
    imports:
    [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        RouterModule, // Necessary for routerLink directive

        MarkdownToHtmlModule,

        UIPlaceholdersModule,
        MapModule,
        ResponsiveToolbarModule,
        ShowMoreModule,
        ConfirmModule,
        CleaveModule,
        PreventAutofocusModule,
        TextareaAutoheightModule,
        LazyModule,
        UntilValueModule,
        USPhoneModule,
        WaitABitModule,
        GenericDialogModule,
        SetHeightModule,
        ClickOnKeyModule,
        AppTickSchedulerModule,
        DialogCloseButtonModule,
        TooltipDirectiveModule,
        MessagesModule,
    ],
    exports:
    [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        MarkdownToHtmlModule,

        UIPlaceholdersModule,
        MapModule,
        ResponsiveToolbarModule,
        ShowMoreModule,
        ConfirmModule,
        CleaveModule,
        PreventAutofocusModule,
        TextareaAutoheightModule,
        LazyModule,
        UntilValueModule,
        USPhoneModule,
        WaitABitModule,
        GenericDialogModule,
        SetHeightModule,
        ClickOnKeyModule,
        AppTickSchedulerModule,
        TooltipDirectiveModule,

        BgComponent,
        DataDisclaimerComponent,
        ModalDirective,
        PoweredByComponent,
        TableActionSpinnerComponent,
        LoadMoreComponent,
        ContentPlaceholderComponent,
        ActionButtonSpinnerComponent,
        TimeAgoPipe,
        ProgressBarComponent,
        ShowMoreComponent,
        FeedbackButtonComponent,
        BottomInfoComponent,
        CardLogoComponent,
        NotFoundPage,
        PageComponent,
        SpinnerButtonSpinnerComponent,
        LazyDialogContentComponent,
        DialogCloseButtonModule,
        MessagesModule,
        IconButtonComponent,
        NAComponent,
        ConfirmDependenciesInclussionComponent,
        ConfirmDependentsRemovalComponent,
        DataJumbotronComponent,
        PageToolbarComponent,
        DataConnectorComponent,
        BackButtonComponent,
        WarnedComponent,
        AddOrRemoveFromCartButtonComponent,
        PartialAmountDirective,
        CartStateBarComponent,
        SpaceTakerComponent,
    ]
})
export class SharedModule { }
