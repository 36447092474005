import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CanDeactivateGuard } from '@egovsolutions/angular-can-deactivate-guard';

@Component({
    selector: 'app-feedback',
    template: `
        <div class="content">
            <egov-dialog-close-button [closeHandler]="closeAttempt"></egov-dialog-close-button>

            <div class="content-block bottom-line">
                <h2 mat-dialog-title>Feedback</h2>
                <p>Encountering issues? Do you have suggestions or comments for us? Let us know using the form below.</p>
            </div>

            <mat-dialog-content>
                <form [formGroup]="form" (ngSubmit)="submit()">
                    <mat-form-field appearance="standard" hideRequiredMarker class="name">
                        <mat-label>Sender Name</mat-label>
                        <input matInput type="text" formControlName="name" required />
                    </mat-form-field>

                    <mat-form-field appearance="standard" hideRequiredMarker class="email">
                        <mat-label>Sender Email</mat-label>
                        <input matInput type="email" formControlName="email" required email />
                    </mat-form-field>

                    <mat-form-field appearance="outline" hideRequiredMarker class="message">
                        <mat-label>Message</mat-label>
                        <egov-textarea-autoheight>
                            <textarea #textarea matInput formControlName="message" required></textarea>
                        </egov-textarea-autoheight>
                    </mat-form-field>

                    <div class="submit-container">
                        <button mat-flat-button color="primary" class="bigger" type="submit" [disabled]="!form.valid">
                            {{ form.disabled ? "Submitting..." : "Submit" }}
                        </button>
                    </div>
                </form>
            </mat-dialog-content>
        </div>
    `,
    styleUrls: ['./feedback.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackComponent
{
    constructor
    (
        // Dependencies

        private readonly canDeactivateGuard: CanDeactivateGuard,


        // Dialog reference

        private readonly dialogRef: MatDialogRef<FeedbackComponent>,
    )
    {}


    // Close handler

    public readonly closeAttempt = this.canDeactivateGuard.addDialog(
        this.dialogRef,
        () => this.form.value.message?.trim() && 'Exit Feedback?'
    );


    // Form

    protected readonly form: UntypedFormGroup = new UntypedFormGroup({
        name: new UntypedFormControl(''),
        email: new UntypedFormControl(''),
        message: new UntypedFormControl(''),
    });

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    protected submit() {}
}
