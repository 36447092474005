import { selectItemInCart } from '@/app/modules/cart/state/common/entities/cart-item/cart-item.selectors';
import { selectEntireState } from '@/app/state/selectors';
import { createSelector } from '@ngrx/store';
import { State } from '@state/model';
import { PPTCart } from '../../../services/ppt-cart.service';
import { selectPPTAccountBillsForCartOperations } from '../../ppt.selectors';
import { selectPPTBillOrDependenciesDelegated } from '../bill/ppt-bill.selectors';

const selectPPTAccountEntity = (s: State) => s.common.ppt.entities.accounts;

export const selectPPTAccounts = createSelector(selectPPTAccountEntity, s => s.ids.map(id => s.entities[id]!));

export const selectPPTAccountsByNumber = createSelector(selectPPTAccountEntity, s => s.entities);
export const selectPPTAccount = (accountNumber: string | undefined) => createSelector(
    selectPPTAccountsByNumber,
    accountsByNumber => accountNumber !== undefined ? accountsByNumber[accountNumber] : undefined
);

export const selectPPTAccountPath = (accountNumber: string | undefined) => createSelector(
    selectPPTAccountsByNumber,
    accountsByNumber => accountNumber !== undefined ? `/property-tax/bill/${accountNumber}` : undefined
);

export const selectPPTAccountHistoryLoaded = (accountNumber: string) => createSelector(
    selectPPTAccountsByNumber,
    accountsByNumber => !!accountsByNumber[accountNumber]?.bills
);

export const selectPPTAccountHistory = (accountNumber: string) => createSelector(
    selectPPTAccountsByNumber,
    accountsByNumber => accountsByNumber[accountNumber]?.bills
);

export const selectPPTAccountByBillId = (id: string) => createSelector(
    selectPPTAccounts,
    accounts =>
        accounts.find(account => account.bills?.includes(id) || account.payableBillsWithABalance?.includes(id))
);

export const selectPPTAccountHasPayableBills = (accountNumber: string, excludeDelegated = true) => createSelector(
    selectEntireState,
    s =>
    {
        const billsForCartOperations = selectPPTAccountBillsForCartOperations(accountNumber, excludeDelegated)(s);
        if (billsForCartOperations === undefined) return undefined;
        return !!billsForCartOperations.length;
    }
);

export const selectPPTAccountIsInCart = (accountNumber: string, excludeDelegated = true) => createSelector(
    selectEntireState,
    s =>
    {
        const paymentEnabledBills = selectPPTAccountBillsForCartOperations(accountNumber, excludeDelegated)(s);

        return paymentEnabledBills
            ? paymentEnabledBills.length
                ? paymentEnabledBills
                    .map(billId => selectItemInCart(PPTCart.BILL_CART_TYPE, billId)(s))
                    .reduce(
                        (result, billInCart) => !!billInCart && result,
                        true
                    )
                : false
            : undefined;
    }
);

export const selectPPTAccountHasPayableBillsDelegated = (accountNumber: string) => createSelector(
    selectEntireState,
    s =>
    {
        const billIds = selectPPTAccountBillsForCartOperations(accountNumber, false)(s);
        if (billIds === undefined) return undefined;

        const billsDelegated = billIds.map(billId => selectPPTBillOrDependenciesDelegated(billId)(s));
        if (billsDelegated.includes(true)) return true;

        return false;
    }
);

export const selectPPTAccountHasALLPayableBillsDelegated = (accountNumber: string) => createSelector(
    selectEntireState,
    s =>
    {
        const billIds = selectPPTAccountBillsForCartOperations(accountNumber, false)(s);
        if (billIds === undefined) return undefined;

        const billsDelegated = billIds.map(billId => selectPPTBillOrDependenciesDelegated(billId)(s));
        if (billsDelegated.includes(false)) return false;

        return true;
    }
);

export const selectPPTAccountAddAllButtonTitle = (accountNumber: string) => createSelector(
    selectEntireState,
    s =>
    {
        const hasPayableBills = selectPPTAccountHasPayableBills(accountNumber)(s);

        return hasPayableBills === undefined
            ? ''
            : hasPayableBills
                ? !selectPPTAccountIsInCart(accountNumber)(s)
                    ? 'Add bills for this parcel to the cart.'
                    : 'Remove bills for this parcel from the cart.'
                : 'This account has no bills available for payment.';
    }
);

export const selectPPTAccountAddAllButtonEnabled = (accountNumber: string) => createSelector(
    selectEntireState,
    s => selectPPTAccountHasPayableBills(accountNumber, false)(s)
);

export const selectPPTAccountAddAllShowWarningIcon = (accountNumber: string) => createSelector(
    selectEntireState,
    s => selectPPTAccountHasPayableBillsDelegated(accountNumber)(s)
);
