import { createReducer } from '@ces/sourced-action';
import { on } from '@ngrx/store';
import { WatchListsState } from 'egov-api';
import { watchListsStateLoaded } from './watch-lists-state.actions';


// Initial state

const initialState = undefined;


// Reducer

export const watchListsStateReducer = createReducer<WatchListsState | undefined>(
    initialState,
    on(watchListsStateLoaded, (state, { newState }) => ({

        ...newState,


        // Testing

        // available: false,
        // unavailabilityMessage: 'Ooopsie, Woopsie! We made a fuckie wackie!',

    })),
);
