import { btaxReducer } from '@btax/state/btax.reducer';
import { composedCartCommonReducer } from '@cart/state/common/cart-common.reducer';
import { ActionReducerMap, combineReducers } from '@ngrx/store';
import { paymentReducer } from '@payment/state/payment.reducer';
import { pptReducer } from '@ppt/state/ppt.reducer';
import { sessionReducer } from '@session/state/session.reducer';
import { watchListsCommonReducer } from '../modules/watch-lists/state/common/watch-lists-common.reducer';
import { stateReducer } from './app-state/app-state.reducer';
import { State } from './model';

export const reducers: ActionReducerMap<State> = {
    state: stateReducer,
    session: sessionReducer,
    common: combineReducers({
        watchLists: watchListsCommonReducer,
        cart: composedCartCommonReducer,
        payment: paymentReducer,
        ppt: pptReducer,
        btax: btaxReducer,
    }),
};
