/* eslint-disable */
export const KEYS: { [hostBase: string]: string } = {
    'https://highlands-fl.mygovonline.com/': 'GdbnSgYc2EM55vdY0Vm2X8V1D',
    'https://lafayette-fl.mygovonline.com/': 'VUaMOokTJWrWsqbaXoJgzpynj',
    'https://hendry-fl.mygovonline.com/': 'o4jumHPIUwtzjWgRgREPRgY1F',
    'https://polk-fl.mygovonline.com/': 'ypp45nfNNBfyXOf1ksZtSI0VJ',
    'https://polk-eps.mygovonline.com/': 'ypp45nfNNBfyXOf1ksZtSI0VJ',
    'https://knox-tn.mygovonline.com/': 'TjqWyDn32CYBxqu4TjfsZzBGVTjHcDeh',
    'https://nashville-tn.mygovonline.com/': '1903kPZkbBulNflWw',
    'https://hamblen-tn.mygovonline.com/': 'Hz67SVdzxywub2mCl',
    'https://madison-tn.mygovonline.com/': 'h3CGMroASJHJMmOdt',
    'https://blount-tn.mygovonline.com/': 'KUbdZQ5zWbPAE8P8euGiY1IsbG9S0BSw',
    'https://memphis-payments.mygovonline.com/': 'w8P8ur36gBGYq7yqr',
    'https://beckham-ok.mygovonline.com/': 'fKnymhTLchSr3OeL6',
    'https://bedford-tn.mygovonline.com/': 'sTbIlwMvqhTqdcVxM',
    'https://washington-tn.mygovonline.com/': 'QFXGrvcwAGwKb6NrK',
    'https://warren-tn.mygovonline.com/': 'bJSCoWZfRzaAa8B1J',
    'https://lincoln-tn.mygovonline.com/': 'ux5DCXT2u6dsayZZ6',
    'https://wilson-tn.mygovonline.com/': 'ZTwjog5cVMj/LlX',
    'https://anderson-tn.mygovonline.com/': 'GDrZ2pTfJZ6X3sc6d',
    'https://jefferson-tn.mygovonline.com/': 'pC6wGBY9TtMVJrFD5'
};
