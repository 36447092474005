import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Platform } from '@egovsolutions/angular-platform';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
    selector: 'app-confirm-dependents-removal',
    template: `
        <egov-dialog-close-button></egov-dialog-close-button>
        <div class="dialog-content" [class.firefox]="platform.isFirefox">
            <p class="prevent-overlap-with-close-button">
                <ng-container *ngIf="!alternativeText; else altText">
                    If you remove {{ triggerItemName }} from the cart, the following items will also be removed:
                </ng-container>
                <ng-template #altText>{{ altText }}</ng-template>
            </p>
            <ul>
                <li *ngFor="let name of additionalItemsNames">{{ name }}</li>
            </ul>
        </div>
        <mat-dialog-actions>
            <button class="text-only-button" [mat-dialog-close]="false">Cancel</button>
            <button class="default text-only-button" [mat-dialog-close]="true">OK</button>
        </mat-dialog-actions>
    `,
    styleUrls: ['./confirm-dependents-removal.component.scss']
})
export class ConfirmDependentsRemovalComponent implements ConfirmDependentsDialogOptions
{
    constructor
    (
        // Template dependencies

        protected platform: Platform,


        // Passed values

        @Inject(MAT_DIALOG_DATA) options: ConfirmDependentsDialogOptions,
    )
    {
        Object.assign(this, options);
    }

    public triggerItemName!: string;
    public additionalItemsNames!: string[];
    public alternativeText?: string;
}

export interface ConfirmDependentsDialogOptions
{
    triggerItemName: string
    additionalItemsNames: string[]
    alternativeText?: string
}
