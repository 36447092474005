import { clearCart, flushCartState } from '@cart/state/common/cart-common.actions';
import { createReducer } from '@ces/sourced-action';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { on } from '@ngrx/store';
import { setItemsInCart } from './cart-item.actions';
import { CartItem, CartItemData, CartItemEntities } from './cart-item.model';


// Adapter

const adapter: EntityAdapter<CartItem> = createEntityAdapter<CartItem>();


// Initial state

const initialState = adapter.getInitialState() as CartItemEntities;


// Runtime state

const addRuntimeState = (itemData: CartItemData): CartItem =>
    ({ ...itemData, runtimeState: { removing: false } });


// Reducer

export const cartItemsReducer = createReducer<CartItemEntities>(
    initialState,

    on(setItemsInCart, (s, { items }) => adapter.setAll(items.map(addRuntimeState), s)),

    // on(updateCartItemPartialAmount, (s, { item, partialAmount }) =>
    // {
    //     const cartItem = s.entities[id];

    //     if (cartItem)
    //     {
    //         let newAmount = partialAmount;
    //         if (newAmount < 0) newAmount = 0;
    //         if (newAmount > cartItem.amount) newAmount = cartItem.amount;

    //         const currentAmount = cartItem.partialAmount;

    //         if (newAmount !== currentAmount)
    //         {
    //             return adapter.updateOne({
    //                 id,
    //                 changes: { partialAmount: newAmount }
    //             }, s);
    //         }
    //     }

    //     return s;
    // }),

    // on(removeItemFromCart, (s, { id }) =>
    // {
    //     return adapter.removeOne(id, s);
    // }),

    on(clearCart, s => adapter.removeAll(s)),
    on(flushCartState, s => adapter.removeAll(s)),
);
