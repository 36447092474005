import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import { environment } from './environments/environment';

import { initializeDraggedClickPrevention } from './helpers/prevent-dragged-clicks';


if (!(window as any).unsupportedBrowser)
{
    // Initialize dragged click prevention

    initializeDraggedClickPrevention();


    // Bootstrap module

    if (environment.production)
        enableProdMode();

    platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.error(err)); // eslint-disable-line

}
