import { createSourcedAction } from '@ces/sourced-action';
import { props } from '@ngrx/store';
import { SubscriptionData } from 'egov-api';


export const loadSubscriptionsForAccount = createSourcedAction(
    'Load account subscriptions for account',
    props<{ accountNumber: string }>()
);

export const subscriptionsForAccountLoaded = createSourcedAction(
    'Subscriptions for account loaded',
    props<{ accountNumber: string, subscriptionsData: SubscriptionData[] }>(),
);

export const subscriptionsForAccountLoadFailed = createSourcedAction(
    'Subscriptions for account load failed',
    props<{ accountNumber: string }>(),
);


export const addSubscription = createSourcedAction(
    'Add account subscription',
    props<{ accountNumber: string, subscriptionData: SubscriptionData }>(),
);

export const subscriptionAdded = createSourcedAction(
    'Account subscription added',
    props<{ accountNumber: string, subscriptionData: SubscriptionData }>(),
);

export const subscriptionAddFailed = createSourcedAction(
    'Account subscription add failed',
    props<{ accountNumber: string }>(),
);


export const removeSubscription = createSourcedAction(
    'Remove account subscription',
    props<{ accountNumber: string, subscriptionId: string }>(),
);

export const subscriptionRemoved = createSourcedAction(
    'Account subscription removed',
    props<{ accountNumber: string, subscriptionId: string }>(),
);

export const subscriptionRemovalFailed = createSourcedAction(
    'Account subscription removal failed',
    props<{ accountNumber: string, subscriptionId: string }>(),
);
