<header id="header" [class.collapsed]="collapsed$ | async" [style.right]="(scrollableModalCommon.amountToAdjustForScrollbar$ | async) + 'px'">
    <div class="bg" [class.show]="showBG$ | async"></div>

    <a class="logo" routerLink="/">
        <div class="logo-image" [style.width]="(logoImageAspectRatio$ | async) + 'em'">
            <img
                *ngIf="doneLoadingLogoImage$ | async; else logoPlaceholder"
                [src]="logoImageURL$ | async"
                [style.width]="(logoImageAspectRatio$ | async) + 'em'"
                alt="Logo"
            />
            <ng-template #logoPlaceholder>
                <egov-shape-ui-placeholder
                    [shape]="(logoImageAspectRatio$ | async)! > 0.98 && (logoImageAspectRatio$ | async)! < 1.02 ? 'circle' : 'square'"
                ></egov-shape-ui-placeholder>
            </ng-template>
        </div>

        <!--
            We need to render the texts twice. One for actual display and one for untouched
            metrics reference. So we use a template and render it twice.

            This is necessary to calculate the scale value for the texts in a way that won't
            cause constant relayouts.
        -->
        <div class="texts-container" #textsContainer>
            <ng-template #texts let-reference>
                <div class="texts" [class.reference]="reference" ngPreserveWhitespaces>
                    <div
                        *ngIf="clientsName$ | async; else textsPlaceholders"
                        class="all-texts"
                        [style.font-size]="reference ? '' : (textScale$ | async) + 'em'"
                    >
                        <div class="name-and-title">
                            <span class="name">{{ clientsName$ | async }}</span>
                            <span class="title">{{ clientsTitle$ | async }}</span>
                        </div>
                        <span class="slogan" *ngIf="clientsSlogan$ | async">
                            {{ clientsSlogan$ | async }}
                        </span>
                    </div>
                    <ng-template #textsPlaceholders>
                        <div class="all-texts placeholders">
                            <div class="name-and-title">
                                <span class="name">
                                    <egov-text-ui-placeholder [length]="28"></egov-text-ui-placeholder>
                                </span>
                                <span class="slogan">
                                    <egov-text-ui-placeholder [length]="38"></egov-text-ui-placeholder>
                                </span>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </ng-template>

            <ng-container *ngTemplateOutlet="texts; context: { $implicit: true }"></ng-container>
            <ng-container *ngTemplateOutlet="texts; context: { $implicit: false }"></ng-container>
        </div>
    </a>

    <!-- Regular Navigation -->

    <ng-container *ngIf="(viewport.mobileNavigation$ | async) === false; else mobileNavigation">
        <mat-toolbar *ngIf="appStateLoaded$ | async; else placeholders">
            <div class="user-widget">
                <ng-container *ngIf="(accountsEnabled$ | async) !== false">
                    <button *ngIf="(signedIn$ | async) !== true; else userButton" mat-button class="user" (click)="signIn()">
                        <span class="logged-out">Sign In</span>
                        <mat-icon>account_circle</mat-icon>
                    </button>

                    <ng-template #userButton>
                        <button mat-button class="user" [matMenuTriggerFor]="userMenu">
                            <span class="logged-in">{{ userName$ | async }}</span>
                            <mat-icon>account_circle</mat-icon>
                            <mat-icon class="expand">expand_more</mat-icon>
                        </button>
                    </ng-template>
                </ng-container>

                <mat-menu #userMenu="matMenu" class="header-user-menu" yPosition="below" xPosition="before" [overlapTrigger]="false">
                    <button *ngIf="watchListsEnabled$ | async" mat-menu-item routerLink="/watch-lists" routerLinkActive="active">
                        <span>Watch Lists</span>
                    </button>

                    <button mat-menu-item (click)="openSettings()">
                        <span>Settings</span>
                    </button>

                    <mat-divider></mat-divider>

                    <button mat-menu-item (click)="signOut()">
                        <span>Sign Out</span>
                    </button>
                </mat-menu>
            </div>

            <a mat-button class="cart" [class.attention]="drawAttentionToCart$ | async" routerLink="/cart" [class.active]="inCart$ | async">
                <span>{{ (cartTotal$ | async) === 0 || (cartTotal$ | async) === undefined ? "Cart" : (cartTotal$ | async | currency) }}</span>
                <mat-icon>shopping_cart</mat-icon>
            </a>

            <a mat-button class="search" [class.active]="inParcelLanding$ | async" routerLink="/lookup/property-tax">
                <span>Lookup</span>
                <mat-icon>search</mat-icon>
            </a>
        </mat-toolbar>

        <ng-template #placeholders>
            <mat-toolbar class="nav-placeholders">
                <button mat-button>
                    <span class="logged-out"><egov-text-ui-placeholder>Sign In</egov-text-ui-placeholder></span>
                    <mat-icon><egov-shape-ui-placeholder shape="circle"></egov-shape-ui-placeholder></mat-icon>
                </button>

                <button mat-button>
                    <span><egov-text-ui-placeholder>Cart</egov-text-ui-placeholder></span>
                    <mat-icon><egov-shape-ui-placeholder shape="circle"></egov-shape-ui-placeholder></mat-icon>
                </button>

                <button mat-button>
                    <span><egov-text-ui-placeholder>Lookup</egov-text-ui-placeholder></span>
                    <mat-icon><egov-shape-ui-placeholder shape="circle"></egov-shape-ui-placeholder></mat-icon>
                </button>
            </mat-toolbar>
        </ng-template>
    </ng-container>

    <!-- Mobile Navigation -->

    <ng-template #mobileNavigation>
        <div class="mobile-navigation">
            <a
                mat-button
                class="button cart"
                *ngIf="!(viewport.phone$ | async)"
                routerLink="/cart"
                [class.active]="inCart$ | async"
                [class.attention]="drawAttentionToCart$ | async"
            >
                <ng-container *ngIf="appStateLoaded$ | async">
                    <mat-icon [style.font-size]="'30px'">shopping_cart</mat-icon>
                    <span *ngIf="itemCount$ | async" class="count">{{ itemCount$ | async }}</span>
                </ng-container>
                <egov-shape-ui-placeholder
                    *ngIf="(appStateLoaded$ | async) !== true"
                    shape="circle"
                    [style.width]="'30px'"
                    [style.height]="'30px'"
                ></egov-shape-ui-placeholder>
            </a>
            <button class="button menu" mat-button title="Show menu" (click)="showMenu()" [disabled]="(appStateLoaded$ | async) !== true">
                <div *ngIf="appStateLoaded$ | async" class="icon"></div>
                <egov-shape-ui-placeholder *ngIf="(appStateLoaded$ | async) !== true" shape="square"></egov-shape-ui-placeholder>
            </button>
        </div>
    </ng-template>
</header>
