import { Injectable } from '@angular/core';
import { ScrollableModal } from '@egovsolutions/angular-scrollable-modal';
import { map } from 'rxjs/operators';
import { ConfirmDependenciesDialogOptions, ConfirmDependenciesInclussionComponent } from '../components/confirm-dependencies-inclussion/confirm-dependencies-inclussion.component';

@Injectable({ providedIn: 'any' })
export class ConfirmDependenciesInclussion
{
    constructor
    (
        // Dependencies

        private readonly scrollableModal: ScrollableModal,
    )
    {}

    public launchConfirmationDialog(
        triggerItemName: string,
        additionalItemsNames: string[],
        alternativeText?: string
    )
    {
        const options: ConfirmDependenciesDialogOptions = {
            triggerItemName,
            additionalItemsNames,
            alternativeText,
        };

        return this.scrollableModal.open(ConfirmDependenciesInclussionComponent, { data: options }).afterClosed().pipe(
            map(v => !!v)
        );
    }
}
