import { animate, query, style, transition, trigger } from '@angular/animations';
import { ElementRef, Injectable } from '@angular/core';
import { NavigationCancel, NavigationError, NavigationStart, Resolve, Router, RouterOutlet, RoutesRecognized, Scroll as ScrollEvent } from '@angular/router';
import { HeaderService } from '@/app/components/header/header.service';
import { Animation } from '@shared/services/animation.service';
import { FramesDelay } from '@egovsolutions/angular-frames-delay';
import { NavigationBacktrack } from '@shared/services/navigation-backtrack.service';
import { Platform } from '@egovsolutions/angular-platform';
import { Scroll } from '@shared/services/scroll.service';
import { Viewport } from '@shared/services/viewport.service';
import { isFunction } from '@egovsolutions/type-checking';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';


// Regular Angular Router animations are extremely limited. This class sets up a custom
// router transitions implementation. This is custom code so it's very likely that there
// are issues with it, given the complexity of the routing process in Angular's router.
//
// If you need to fix or change something about it, please be extremely careful, make
// sure you know the implications of what you're doing, and make sure to keep things
// as maintainable as possible for other developers.

@Injectable({ providedIn: 'root' })
export class Transitions
{

    // A reference to the instance is required by FORCE_RELAYOUT_RESOLVER,
    // to discriminate platforms and check whether a transition is occurring or not.

    public static instance: Transitions;


    // This animation descriptor is used for the sole purpose of forcing Angular to keep
    // the element around while the CSS animation is happening.

    public static readonly RETAIN_LEAVING_PAGE = trigger(
        'retainPageAnimation',
        [
            transition(':increment', [ query(':leave', [ animate('10s', style({})) ], { optional: true }) ])
        ]
    );


    // This is pretty much the same but it's used in inner elements to prevent them
    // from disappearing while the transition occurs.

    public static readonly RETAIN_LEAVING_ELEMENT = trigger(
        'retainAnimation',
        [
            transition('* => *', [ query(':leave', [ animate('11s', style({})) ], { optional: true }) ])
        ]
    );


    constructor
    (
        // Dependencies

        private readonly router: Router,
        private readonly viewport: Viewport,
        private readonly animation: Animation,
        private readonly headerService: HeaderService,
        private readonly scroll: Scroll,
        public platform: Platform,
        private readonly framesDelay: FramesDelay,
        private readonly navigationBacktrack: NavigationBacktrack,
    )
    {
        Transitions.instance = this;
    }


    // This variable is used to make angular enable the RETAIN_LEAVING_PAGE animation every
    // time the content of the router-outlet changes.

    private readonly routerAnimChangerBS$ = new BehaviorSubject(0);
    public readonly routerAnimChanger$ = this.routerAnimChangerBS$.asObservable();


    // Collection of reasons to ignore new navigations.
    // Starts with a value because we don't want a transition during the initial navigation.

    private readonly reasonsToIgnoreNavigation: string[] = ['startup'];


    // Other properties

    private lastNavigationId = 0;
    private initialized = false;

    private readonly transitioningBS$ = new BehaviorSubject(0);
    public readonly transitioning$ = this.transitioningBS$.asObservable();

    private readonly loadingNextPageBS$ = new BehaviorSubject(false);
    public readonly loadingNextPage$ = this.loadingNextPageBS$.asObservable();


    // Passed values
    //
    // The initialization method is run once these three are set. That's why they are get-sets.

    private _outletContainer!: ElementRef;
    public set outletContainer(newOutletContainer: ElementRef) { this._outletContainer = newOutletContainer; this.init(); }
    public get outletContainer(): ElementRef { return this._outletContainer; }

    private _outlet!: RouterOutlet;
    public set outlet(newOutlet: RouterOutlet) { this._outlet = newOutlet; this.init(); }
    public get outlet(): RouterOutlet { return this._outlet; }

    private _header!: ElementRef;
    public set header(newHeader: ElementRef) { this._header = newHeader; this.init(); }
    public get header(): ElementRef { return this._header; }


    // Initialization

    public init()
    {
        if (!this.initialized && this.outletContainer && this.outlet && this.header)
        {

            // Set the browser's scroll restoration method to manual.
            // This prevents the browser from trying to restore the page's scroll position itself
            // when the user navigates back to a previous page. We're going to handle this
            // ourselves.

            this.scroll.setHistoryScrollRestoration('manual');


            // A bunch of values that we want to share accross events

            let leavingElement: HTMLElement | null;
            let currentScroll: [number, number];
            let isBackwards = false;

            let ignoreScrollId: string;

            let lastComponent: any | undefined;
            let newComponent: any | undefined;

            let restoringState: boolean | undefined;

            let initialAnimationStateSet: boolean | undefined;

            let transitionAborted: boolean | undefined;


            // IMPORTANT NOTE: The following subscriptions are layed out in this file in the order in which
            // the router triggers these events for easier visualization of how all this works.

            (this.router.events.pipe(filter(e => e instanceof NavigationStart)) as Observable<NavigationStart>)
                .subscribe((e: NavigationStart) =>
                {

                    initialAnimationStateSet = false;
                    transitionAborted = false;

                    try { lastComponent = this.outlet.component; }
                    catch {}


                    // Store whether this transition actually needs to happen or not

                    restoringState = !!e.restoredState;

                    const restoringStateOnIOSSafari = restoringState && this.platform.isIOS && this.platform.isSafari;
                    const skipTransitionForThisNavigation =
                    !!this.reasonsToIgnoreNavigation.length || restoringStateOnIOSSafari;


                    if (!skipTransitionForThisNavigation)
                    {

                        // Set loadingNextPage state

                        this.loadingNextPageBS$.next(true);


                        // Store the position of the scroll when the navigation starts

                        currentScroll = this.scroll.get();


                        // This detects whether the event is a back navigation.

                        isBackwards = this.forceBackwards || this.navigationBacktrack.getOriginalNavigationId(e.id) <
                        this.navigationBacktrack.getOriginalNavigationId(this.lastNavigationId);

                        this.forceBackwards = false;
                    }
                    else
                    abortTransition();
                });


            // Apply the animation's initial state.
            //
            // We need to do this as early as possible to block the scroll right away. Current-day browsers allow
            // scrolling even if the page is frozen. Allowing the users to keep scrolling around while the new
            // content is being created and rendered may lead them to think that their navigation request wasn't
            // received. And so we need to make the current content fixed immediately, to disable the scroll
            // right away.
            //
            // In order to do this, we need an event that happens as early as possible but also after
            // NavigationStart because if we start applying transition styles in NavigationStart, the Router
            // Scroller can't properly store the scroll position the page was at for later use.

            (this.router.events.pipe(filter(e => e instanceof RoutesRecognized)) as Observable<RoutesRecognized>)
                .subscribe((event: RoutesRecognized) =>
                {
                    if (!transitionAborted)
                    {

                        initialAnimationStateSet = true;


                        // Set backwards value or otherwise on both the outlet container and the header

                        if (isBackwards)
                        {
                            (this.outletContainer.nativeElement as HTMLElement).classList.add('backwards');
                            (this.header.nativeElement as HTMLElement).classList.add('backwards');
                        }
                        else
                        {
                            (this.outletContainer.nativeElement as HTMLElement).classList.remove('backwards');
                            (this.header.nativeElement as HTMLElement).classList.remove('backwards');
                        }


                        // Ask the header to ignore any scroll events until told otherwise.

                        ignoreScrollId = `router-transition-${this.routerAnimChangerBS$.value + 1}`;
                        this.scroll.reasonsToIgnoreScrollEvents.add(ignoreScrollId);


                        // Grabbing the leaving element by checking that it's not:
                        // the router-outlet directive, .leaving, .about-to-leave.

                        leavingElement = null;

                        const routerChildren = (this.outletContainer.nativeElement as HTMLElement).children;
                        for (let i = 0; i < routerChildren.length; i++)
                        {
                            const element = routerChildren.item(i) as HTMLElement;

                            if
                            (
                                element.tagName === 'ROUTER-OUTLET'
                                ||
                                element.classList.contains('leaving')
                                ||
                                element.classList.contains('about-to-leave')
                            )
                                continue;

                            leavingElement = element; break;
                        }

                        if (leavingElement)
                        {

                            // Remove and add some classes. This makes the current content fixed.

                            leavingElement.classList.remove('entering');
                            leavingElement.classList.remove('entered');
                            leavingElement.classList.add('about-to-leave');


                            // Since all the content is now fixed the scroll is reset to zero.
                            // To compensate for it, we need to move the content to its scrolled
                            // position.

                            leavingElement.style.left = -currentScroll[0] + 'px';
                            leavingElement.style.top = -currentScroll[1] + 'px';


                            // If the component is interested in its departure, inform it

                            if (lastComponent && isOnLeaving(lastComponent))
                                lastComponent.onLeaving(currentScroll);
                        }
                    }
                });


            // The outlet gives us an instance of the component being added. When a new component
            // is created, we store it. When the current component is reused, this event doesn't
            // fire, and therefore newComponent and lastComponent end up with the same value.
            //
            // That fact is used later on to determine whether this transition needs to actually
            // be fulfilled or should be aborted.

            this.outlet.activateEvents.subscribe((component: any) => newComponent = component);


            // ScrollEvent
            //
            // We need to use one of the late-est events triggered by the router on every navigation to
            // start the transition. The reason for using Scroll over any of the other events is that
            // Scroll contains useful information about what the scroll of the new page should be
            // (only useful when going back to pages using the browser's navigation features).

            (this.router.events.pipe(filter(e => e instanceof ScrollEvent)) as Observable<ScrollEvent>)
                .subscribe((e: ScrollEvent) =>
                {
                    this.framesDelay.delay$().subscribe(() => this.loadingNextPageBS$.next(false));

                    if (newComponent === lastComponent) abortTransition();


                    if (!transitionAborted)
                    {

                        // Store a local copy of ignoreScrollId in case a new navigation is stared

                        const lastIgnoreScrollId = ignoreScrollId;


                        // Store the starting scrollLeft and scrollTop for the new content

                        const targetPageScrollLeft = e.position?.[0] || 0;
                        const targetPageScrollTop = e.position?.[1] || 0;


                        // Set transitioning state

                        this.transitioningBS$.next(this.transitioningBS$.value + 1);


                        // Increment router animation changer so Angular will start the
                        // RETAIN_LEAVING_PAGE (non-)animation and thus keep the leaving element around
                        // for a bit, while we animate it using plain CSS animations.

                        this.routerAnimChangerBS$.next(this.routerAnimChangerBS$.value + 1);


                        // Get a reference to the element that was added

                        let enteringElement!: HTMLElement;

                        const routerChildren = (this.outletContainer.nativeElement as HTMLElement).children;
                        for (let i = 0; i < routerChildren.length; i++)
                        {
                            const element = routerChildren.item(i) as HTMLElement;
                            if
                            (
                                element.tagName === 'ROUTER-OUTLET'
                                ||
                                element.classList.contains('leaving')
                                ||
                                element.classList.contains('about-to-leave')
                            )
                                continue;

                            enteringElement = element; break;
                        }


                        // Add .about-to-enter to the entering content so that it'll start outside the
                        // viewport and since the new content starts as fixed, offset it to its proper
                        // scroll position

                        enteringElement.classList.add('about-to-enter');
                        enteringElement.style.top = -targetPageScrollTop + 'px';


                        // If the header is currently hidden, make it appear as if a new header was
                        // entering with the new content.

                        const enterHeaderToo: boolean = !this.headerService.reasonsToShow$.value.size;


                        if (enterHeaderToo)
                        {

                            // Add about-to-enter to the header so that it'll move outside the viewport

                            (this.header.nativeElement as HTMLElement).classList.add('about-to-enter');


                            // Show entering header if currently collapsed

                            this.headerService.forceShow(targetPageScrollTop);

                        }


                        // Keep a copy of the leaving element in case something changes the
                        // wider-scope variable.

                        const leavingEl = leavingElement;


                        // lastComponent is updated immediately and then the transition starts.
                        // To keep a reference to the transitioning instance, we store in a
                        // separate variable.

                        const transitioningComponent: any | undefined = lastComponent;


                        // Define a function that triggers the transition

                        const doTheTransition = () =>
                        {


                            // If not entering header, update header transparency for target page

                            if (!enterHeaderToo)
                                this.headerService.updateCloseToTop(targetPageScrollTop);


                            // Launch the leaving animation

                            if (leavingEl)
                            {

                                // Change the leaving page from .about-to-leave to .leaving

                                leavingEl.classList.remove('about-to-leave');
                                leavingEl.classList.add('leaving');


                                // When the animation finishes, move the state from leaving to left

                                fromEvent(
                                    leavingEl,
                                    this.animation.animationEndEvent
                                )
                                .pipe(
                                    filter(event => event.target === event.currentTarget),
                                    first()
                                )
                                .subscribe(_ =>
                                {

                                    // Wait one frame more, so the animation will fully complete before
                                    // officially ending the transition which can launch expensive tasks.

                                    this.framesDelay.delay$(1).subscribe(() =>
                                    {

                                        leavingEl.classList.remove('leaving');
                                        leavingEl.classList.add('left');


                                        // Let's also remove it before Angular does to release some memory.
                                        // (Experimental. Get rid of it if it is causing issues.)

                                        leavingEl.remove();


                                        // If the component is interested in its departure, inform it

                                        if (transitioningComponent && isOnLeft(transitioningComponent))
                                            transitioningComponent.onLeft();
                                    });
                                });
                            }



                            // Start the entering animation for both the content and the header, ny switching
                            // them from .about-to-enter to .entering

                            if (enteringElement)
                            {
                                enteringElement.classList.remove('about-to-enter');
                                enteringElement.classList.add('entering');
                            }

                            if (enterHeaderToo)
                            {
                                (this.header.nativeElement as HTMLElement).classList.remove('about-to-enter');
                                (this.header.nativeElement as HTMLElement).classList.add('entering');
                            }


                            // If the component is interested in its entrance, inform it

                            if (isOnEntering(newComponent))
                                newComponent.onEntering([targetPageScrollLeft, targetPageScrollTop]);


                            // When the animation is complete...

                            const animationDoneHandler = () =>
                            {

                                // Wait a bit more, so the animation will fully complete before
                                // officially ending the transition which can launch expensive tasks.

                                this.framesDelay.delay$(1).subscribe(() =>
                                {

                                    // Set transitioning state

                                    this.transitioningBS$.next(this.transitioningBS$.value - 1);


                                    // Change the element and the header from entering to entered

                                    if (enteringElement)
                                    {
                                        enteringElement.classList.remove('entering');
                                        enteringElement.classList.add('entered');
                                        enteringElement.style.top = '';
                                    }

                                    if (enterHeaderToo)
                                        (this.header.nativeElement as HTMLElement).classList.remove('entering');


                                    // Set initial scroll position

                                    this.scroll.set([targetPageScrollLeft, targetPageScrollTop]);


                                    // Remove reason for the scroll service to ignore scroll events

                                    this.framesDelay.delay$(1).subscribe(() =>
                                        this.scroll.reasonsToIgnoreScrollEvents.delete(lastIgnoreScrollId));


                                    // If the component is interested in its entrance, inform it

                                    if (isOnEntered(newComponent))
                                        newComponent.onEntered();
                                });
                            };

                            if (enteringElement)
                                fromEvent(enteringElement, this.animation.animationEndEvent)
                                    .pipe(filter(event => event.target === event.currentTarget), first())
                                    .subscribe(animationDoneHandler);
                            else
                                this.framesDelay.delay$().subscribe(animationDoneHandler);
                        };


                        // Trigger the transition with a delay.
                        //
                        // The delay is used to give the browser time to render things for a few frames
                        // and generally be settled about the new content, so that when the animation
                        // happens it'll be smoother.

                        this.framesDelay.delay$(2).subscribe(doTheTransition);
                    }


                    // Keep track of the last component used

                    lastComponent = newComponent;


                    // Once the first navigation is done, we should enable transitions

                    this.unignoreNavigation('startup');


                    // Store last navigation id

                    this.lastNavigationId = e.routerEvent.id;
                });


            // Abort transition

            const abortTransition = () =>
            {
                if (!transitionAborted)
                {

                    transitionAborted = true;


                    // Update loadingNextPage

                    this.loadingNextPageBS$.next(false);


                    // Undo initial changes

                    if (initialAnimationStateSet)
                    {

                        // Report transition cancelled if component is interested

                        if (isOnTransitionCancelled(lastComponent))
                            lastComponent.onTransitionCancelled();

                        // Undo changes to leaving element

                        if (leavingElement)
                        {
                            leavingElement.style.left = '';
                            leavingElement.style.top = '';
                            leavingElement.classList.remove('about-to-leave');
                            leavingElement.classList.add('entered');
                        }


                        // Scroll had been moved to zero by the fact that the leavingElement was changed to a fixed
                        // position. Let's reset it back to its original value.

                        this.scroll.set(currentScroll);


                        // Tell the scroll service to stop ignoring scroll events

                        this.scroll.reasonsToIgnoreScrollEvents.delete(ignoreScrollId);


                        // Set initialAnimationStateSet state

                        initialAnimationStateSet = false;
                    }
                }
            };

            (this.router.events
                .pipe(
                    filter(e => e instanceof NavigationCancel || e instanceof NavigationError)
                ) as Observable<NavigationCancel | NavigationError>)
                .subscribe(abortTransition);


            // Initialized

            this.initialized = true;
        }
    }


    // If you want to ignore navigation events for a while use the following methods

    public ignoreNavigation(reason: string)
    {
        if (!this.reasonsToIgnoreNavigation.includes(reason))
            this.reasonsToIgnoreNavigation.push(reason);
    }

    public unignoreNavigation(reason: string)
    {
        const index: number = this.reasonsToIgnoreNavigation.indexOf(reason);
        if (index !== -1) this.reasonsToIgnoreNavigation.splice(index, 1);
    }


    // Force back animation

    private forceBackwards = false;

    public forceBackwardsAnimation()
    {
        this.forceBackwards = true;
    }


    // This resolver guard doesn't really resolve anything. It is used instead to force a few relayouts between
    // the navigation start (when the scroll is disabled) and the creation of the new component.
    //
    // Without it the scroll isn't really disabled until the new element is completely rendered and a relayout
    // finally happens. Rendering the new component might take several seconds (especially on mobile).
    // During which the user gets to scroll through a frozen page and doesn't see any effects of their input
    // (tap or click, typically) anywhere. This leads the user to believe that the input was ineffective.
    //
    // By allowing one or a few relayouts to happen right away, the "disableing" of the scroll happens,
    // and the user gets to see the effects of their last interaction on screen, while the new component
    // is being created and rendered.

    public static readonly FORCE_RELAYOUT_RESOLVER: Resolve<void> = { resolve: () =>
    {
        if (!Transitions.instance || Transitions.instance.loadingNextPageBS$.value)
            return FramesDelay.delay$(3);
    }};
}

export interface OnLeaving
{
    onLeaving(scroll: [number, number]): void;
}

export const isOnLeaving = (value: any): value is OnLeaving => isFunction(value.onLeaving);

export interface OnLeft
{
    onLeft(): void;
}

export const isOnLeft = (value: any): value is OnLeft => isFunction(value.onLeft);

export interface OnEntering
{
    onEntering(scroll: [number, number]): void;
}

export const isOnEntering = (value: any): value is OnEntering => isFunction(value.onEntering);

export interface OnEntered
{
    onEntered(): void;
}

export const isOnEntered = (value: any): value is OnEntered => isFunction(value.onEntered);

export interface OnTransitionCancelled
{
    onTransitionCancelled(): void;
}

export const isOnTransitionCancelled = (value: any): value is OnTransitionCancelled =>
    isFunction(value.onTransitionCancelled);
