import { createReducer } from '@ces/sourced-action';
import { on } from '@ngrx/store';
import { PPTState } from 'egov-api';
import { pptStateLoaded } from './ppt-state.actions';


// Initial state

const initialState = undefined;


// Reducer

export const pptStateReducer = createReducer<PPTState | undefined>(
    initialState,
    on(pptStateLoaded, (state, { newState }) =>
    ({

        // Defaults

        displayReceiptId: false,
        numberDisplayType: null,

        handlesTurnedOver: false,
        handlesNotTurnedOver: true,

        otherBillsDelegatedMessage: null,
        delegatedBillMessage: null,
        delegatedBillLinkText: null,

        accountPartiallyDelegatedDialogContent: null,
        accountDelegatedDialogContent: null,
        accountDelegatedDialogButtonLabel: null,


        // Response

        ...newState as any,


        // Testing

        // availability:
        // {
        //     ...newState.availability,
        //     watchLists: false,
        // }

        // disallowPaymentTypes: { delinquent: [PaymentType.ECheck] }

    })),
);
