const MAX_DISTANCE = 10;

export function initializeDraggedClickPrevention()
{
    let eventStartX: number | undefined;
    let eventStartY: number | undefined;

    let panned = false;

    window.addEventListener('mousedown', (event: MouseEvent) =>
    {
        eventStartX = event.clientX;
        eventStartY = event.clientY;
    });

    window.addEventListener('mouseup', (event: MouseEvent) =>
    {
        panned = Math.sqrt(
            Math.pow(event.clientX - eventStartX!, 2)
            +
            Math.pow(event.clientY - eventStartY!, 2)
        ) > MAX_DISTANCE;
    });

    window.addEventListener(
        'click',
        (event: MouseEvent) =>
        {
            if (panned)
            {
                event.preventDefault();
                event.stopPropagation();
            }
        },
        true
    );
}
