import { createSourcedAction } from '@ces/sourced-action';
import { BTaxLookupResponse, EgovAPIError } from 'egov-api';
import { props } from '@ngrx/store';


export const loadBillsForBTaxAccount = createSourcedAction(
    'Load bills for BTax account',
    props<{ operationId?: string, accountNumber: string }>()
);

export const billsForBTaxAccountLoaded = createSourcedAction(
    'Bills for BTax account loaded',
    props<{ operationId?: string, response: BTaxLookupResponse }>()
);

export const billsForBTaxAccountLoadFailed = createSourcedAction(
    'Bills for BTax account load failed',
    props<{ operationId?: string, error: EgovAPIError }>()
);
