import { State } from '@state/model';
import { createSelector } from '@ngrx/store';
import { selectUserAccountsEnabled } from '@/app/state/app-state/app-state.selectors';


export const selectPPTState = (state: State) => state.common.ppt.state;
export const selectPPTStateLoaded = createSelector(selectPPTState, s => !!s);

export const selectPPTAvailability = createSelector(selectPPTState, s => s?.availability);
export const selectPPTBillsAvailability = createSelector(selectPPTAvailability, a => a?.bills);

export const selectPPTAutoDraftEnabled = createSelector(
    selectUserAccountsEnabled, selectPPTAvailability,
    (accountsEnabled, availability) => accountsEnabled && availability?.autoDraft
);
export const selectPPTSubscriptionsEnabled = createSelector(selectPPTAvailability, a => a?.subscriptions);
const selectPPTWatchListsAvailability = createSelector(selectPPTAvailability, a => a?.watchlists);

export const selectPPTWatchListsEnabled = createSelector(
    selectUserAccountsEnabled, selectPPTWatchListsAvailability,
    (accountsEnabled, watchListsAvailable) => accountsEnabled && watchListsAvailable
);

export const selectPPTDisplayReceiptId = createSelector(selectPPTState, s => s?.displayReceiptId);
export const selectPPTDisplayPrintBillButton = createSelector(selectPPTState, s => s?.displayPrintBillButton);
export const selectPPTDisplayPrintReceiptButton = createSelector(selectPPTState, s => s?.displayPrintReceiptButton);
export const selectPPTDisplayPdfParcelHistory = createSelector(selectPPTState, s => s?.displayPdfParcelHistory);
export const selectPPTDisplayPaymentHistory = createSelector(selectPPTState, s => s?.displayPaymentHistory);

export const selectPPTUpcomingYear = createSelector(selectPPTState, s => s?.upcomingYear);
export const selectPPTDisclaimer = createSelector(selectPPTState, s => s?.disclaimer);
export const selectPPTInstallmentMonths = createSelector(selectPPTState, s => s?.installmentMonths);
export const selectPPTDisallowPaymentTypes = createSelector(selectPPTState, s => s?.disallowPaymentTypes);

export const selectPPTBlockMessages = createSelector(selectPPTState, s => s?.blockMessages);

export const selectPPTAllowPartialPayments = createSelector(selectPPTState, s => s?.allowPartialPayments);
export const selectPPTPaymentDependencies = createSelector(selectPPTState, s => s?.paymentDependencies);

export const selectPPTNumberDisplayType = createSelector(selectPPTState, s => s?.numberDisplayType);

export const selectPPTHandlesTurnedOver = createSelector(
    selectPPTState,
    s => s?.handlesTurnedOver
);

export const selectPPTHandlesNotTurnedOver = createSelector(
    selectPPTState,
    s => s?.handlesNotTurnedOver
);

export const selectDelegatedPriorsMessage = createSelector(
    selectPPTState,
    s => s?.otherBillsDelegatedMessage || 'This bill cannot be paid here because prior unpaid bills have been turned over for collection.'
);

export const selectDelegatedBillMessage = createSelector(
    selectPPTState,
    s => s?.delegatedBillMessage || 'This bill has been turned over to the Clerk & Master for collection and it cannot be paid here.'
);

export const selectDelegatedBillLinkText = createSelector(
    selectPPTState,
    s => s?.delegatedBillLinkText || 'View in the Clerk & Master\'s website'
);

export const selectAccountPartiallyDelegatedDialogContent = createSelector(
    selectPPTState,
    s => s?.accountPartiallyDelegatedDialogContent || 'Some of these bills have been turned over for collection. If you proceed, not all bills will be added to the cart.'
);

export const selectAccountDelegatedDialogContent = createSelector(
    selectPPTState,
    s => s?.accountDelegatedDialogContent || 'This account cannot be added to the cart here because its bills have been turned over for collection.'
);
