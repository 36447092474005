import { selectPageTitleSuffix } from '@/app/state/app-state/app-state.selectors';
import { State } from '@state/model';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, filter, first, last, map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TitleService
{

    constructor
    (
        // Dependencies

        private readonly title: Title,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly store: Store<State>,
    )
    {
        this.getDefaultSuffixFromTitleTag();
        this.setUpNavigationHandling();
        this.setUpTitleSuffixHandling();
    }


    // Suffix

    private defaultTitleSuffix = 'Taxes';
    private titleSuffix?: string;

    private getDefaultSuffixFromTitleTag()
    {
        const titleElement = document.querySelector<HTMLTitleElement>('head > title');
        if (titleElement?.innerText) this.defaultTitleSuffix = titleElement.innerText;
    }


    // Page title

    private pageTitle?: string;


    // Handle page changes

    private setUpNavigationHandling()
    {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).pipe(
            map(() => this.route),
            map(route =>
            {
                const routes: ActivatedRoute[] = [];

                while (route.firstChild)
                {
                    routes.push(route.firstChild);
                    route = route.firstChild;
                }

                return from(routes);
            })
        )
        .subscribe(routes$ =>
        {
            routes$.pipe(
                filter(route => route.outlet === 'primary'),
                switchMap(route => route.data.pipe(first())),
                map(data => data.title),
                filter(title => title),
                last(),
                catchError(err => of(undefined))
            )
            .subscribe(pageTitle =>
            {
                this.pageTitle = pageTitle;
                this.updateTitle();
            });
        });
    }


    // Handle title suffix changes

    private setUpTitleSuffixHandling()
    {
        this.store.select(selectPageTitleSuffix).pipe(filter(s => !!s)).subscribe(suffix =>
        {
            this.titleSuffix = suffix;
            this.updateTitle();
        });
    }


    // Actually update the title

    private updateTitle()
    {
        const titleSuffix: string = this.titleSuffix || this.defaultTitleSuffix;
        this.title.setTitle(this.pageTitle ? `${this.pageTitle} | ${titleSuffix}` : titleSuffix);
    }
}
