import { Injectable } from '@angular/core';
import { ofType } from '@ces/sourced-action';
import { Actions, createEffect } from '@ngrx/effects';
import { BTaxNetworkService } from 'egov-api';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { BTaxStateEffects } from './btax-state/btax-state.effects';
import { billsForBTaxAccountLoaded, billsForBTaxAccountLoadFailed, loadBillsForBTaxAccount } from './btax.actions';
import { btaxEntityEffects } from './entities';


@Injectable()
class BTaxEffects
{
    constructor
    (
        // Dependencies

        private readonly actions$: Actions,
        private readonly networkService: BTaxNetworkService,
    )
    {}

    private readonly loadBillsForBTaxAccount$ = createEffect(() => this.actions$.pipe(
        ofType(loadBillsForBTaxAccount),
        mergeMap(({ operationId, accountNumber }) => this.networkService.bills$(accountNumber).pipe(
            map(response => billsForBTaxAccountLoaded(
                `Effect of ${loadBillsForBTaxAccount.type}`,
                { operationId, response }
            )),
            catchError(error => of(billsForBTaxAccountLoadFailed(
                `Effect of ${loadBillsForBTaxAccount.type}`,
                { operationId, error: JSON.parse(JSON.stringify(error)) }
            )))
        )),
    ));
}

export const btaxEffects = [BTaxEffects, BTaxStateEffects, ...btaxEntityEffects];
