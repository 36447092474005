import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ScrollableModalCommonService } from '@egovsolutions/angular-scrollable-modal';
import { ImageLoader } from '@shared/services/image-loader.service';
import { BehaviorSubject } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { BgImageService } from '@shared/services/bg-image.service';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { State } from '@state/model';
import { selectBgOverlayBlendMode, selectBgOverlayColor, selectBgOverlayOpacity } from '@/app/state/app-state/app-state.selectors';

@Component({
    selector: 'app-bg',
    template: `
        <div
            class="site-bg"
            [class.full-page]="fullPage"
            [style.margin-right]="fullPage ? (scrollableModalCommon.amountToAdjustForScrollbar$ | async) + 'px' : null"
            [ngStyle]="styles"
        >
            <div class="image" [style.background-image]="backgroundImage" [class.loaded]="doneLoadingBG$ | async" [class.enter-with-transition]="fadeIn$ | async"></div>
            <div class="color-overlay" [style.mix-blend-mode]="bgOverlayBlendMode$ | async">
                <div class="color-overlay-color" [style.background-color]="bgOverlayColor$ | async" [style.opacity]="bgOverlayOpacity$ | async"></div>
            </div>
        </div>
    `,
    styleUrls: ['./bg.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BgComponent implements OnInit
{
    constructor
    (
        // Dependencies

        private readonly imageLoader: ImageLoader,
        private readonly bgImageService: BgImageService,
        private readonly sanitizer: DomSanitizer,
        private readonly store: Store<State>,


        // Shared dependency

        protected scrollableModalCommon: ScrollableModalCommonService,
    )
    {}


    // Input settings

    private _fullPage = false;
    @Input() protected set fullPage(newValue: boolean | '') { this._fullPage = newValue === '' || newValue; }
    protected get fullPage() { return this._fullPage; }

    @Input() protected styles?: { [klass: string]: any; };


    // Initialization

    public ngOnInit()
    {
        this.triggerFadeThreshold();
        this.startLoading();
    }


    // Customizations

    protected bgOverlayColor$ = this.store.select(selectBgOverlayColor);
    protected bgOverlayOpacity$ = this.store.select(selectBgOverlayOpacity);
    protected bgOverlayBlendMode$ = this.store.select(selectBgOverlayBlendMode);


    // URL

    protected bgURL?: string;
    protected get backgroundImage(): SafeStyle | undefined
    {
        return this.bgURL && this.sanitizer.bypassSecurityTrustStyle(`url('${this.bgURL}')`);
    }


    // Loading the picture

    protected readonly doneLoadingBG$ = new BehaviorSubject(false);
    protected readonly fadeIn$ = new BehaviorSubject(false);

    private startLoading()
    {
        this.bgImageService.nextBackgroundURL$.pipe(filter(url => !!url), first()).subscribe(url =>
        {
            this.bgURL = url;
            this.imageLoader.load(this.bgURL!, true)
                .subscribe((loaded: boolean) => loaded && this.doneLoadingBG$.next(true));
        });
    }

    private triggerFadeThreshold()
    {
        setTimeout(() => this.fadeIn$.next(true), 400);
    }
}
