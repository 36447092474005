import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FrontEndSession, PASSWORD_VALIDATOR } from 'egov-api';
import { SimpleErrorHandling } from '@shared/services/simple-error-handling.service';
import { BehaviorSubject } from 'rxjs';


@Component({
    selector: 'app-sign-in',
    template: `
        <egov-dialog-close-button></egov-dialog-close-button>
        <mat-dialog-content>
            <form [formGroup]="signInForm" (ngSubmit)="signIn()">
                <mat-form-field appearance="standard" hideRequiredMarker>
                    <mat-label>Email</mat-label>
                    <input matInput type="email" formControlName="email" required email />
                </mat-form-field>
                <mat-error *ngIf="(submissionErrors$ | async)?.email?.length">
                    {{ ((submissionErrors$ | async)?.email)[0] }}
                </mat-error>

                <mat-form-field appearance="standard" hideRequiredMarker>
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="password" required minlength="8" />
                </mat-form-field>
                <mat-error *ngIf="(submissionErrors$ | async)?.password?.length">
                    {{ ((submissionErrors$ | async)?.password)[0] }}
                </mat-error>

                <button mat-flat-button color="primary" class="bigger" type="submit" [disabled]="!signInForm.valid">
                    {{ signInForm.disabled ? "Signing In..." : "Sign In" }}
                </button>

                <div class="bottom">
                    <a href class="sign-up-link dialog-link-button" (click)="$event.preventDefault(); showSignUp()"> Need an account? Sign up here. </a>
                    <div class="password-reset">
                        <a href (click)="showPasswordReset($event)" class="dialog-link-button"> Reset password </a>
                    </div>
                </div>
            </form>
        </mat-dialog-content>
    `,
    styleUrls: ['./sign-in.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInComponent
{
    constructor
    (
        // Dependencies

        private readonly session: FrontEndSession,
        private readonly snackBar: MatSnackBar,
        private readonly simpleErrorHandling: SimpleErrorHandling,


        // Dialog reference

        private readonly dialogRef: MatDialogRef<SignInComponent>,
    )
    {}


    // Form

    protected readonly submissionErrors$ = new BehaviorSubject<any | undefined>(undefined);

    protected readonly signInForm: UntypedFormGroup = new UntypedFormGroup({
        email: new UntypedFormControl('', [Validators.required, Validators.email]),
        password: new UntypedFormControl('', [Validators.required, PASSWORD_VALIDATOR]),
    });

    protected signIn()
    {
        this.submissionErrors$.next(undefined);

        this.signInForm.disable();

        this.session.signIn$(
            this.signInForm.get('email')!.value,
            this.signInForm.get('password')!.value,
        )
        .subscribe({

            next: () =>
            {
                this.snackBar.open('You are now signed in.');
                this.dialogRef.close('signedIn');
            },
            error: error =>
            {
                this.submissionErrors$.next(this.simpleErrorHandling.getValidationErrorsOrDisplayBestMessage(error, {
                    ignoreValidationProperties: ['login'],
                    customMessage: 'Error trying to sign in.',
                    retryHandler: () => this.signIn()
                }));

                this.signInForm.enable();
                this.signInForm.markAsPristine();
            },
        });
    }


    // Go somewhere else

    protected showSignUp()
    {
        this.dialogRef.close('signUpRequested');
    }

    protected showPasswordReset(e: Event)
    {
        e.preventDefault();
        this.dialogRef.close('passwordResetRequested');
    }

}
